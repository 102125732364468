import config from 'config';
import { getHeaders } from 'utils/getHeaders';

export function fetchRequest(token, method, endpoint, body) {
  const fetchRequest = async () => {
    const options = getHeaders(token);
    options.method = method;
    options.body = JSON.stringify(body);

    const res = await fetch(`${config.api.baseURL}/${endpoint}`, options);
    return await res.json();
  }

  return fetchRequest();
}