import React, { memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import "./IncrementalStepper.scss";

export function IncrementalStepper({
  initialValue,
  resetStepper,
  onValueChange,
}) {
  const [timeoutValue, setTimeoutValue] = useState(initialValue);

  function checkAndDecreaseTimeout() {
    if (timeoutValue - 5 > 0) {
      setTimeoutValue(timeoutValue - 5);
    }
  }

  function increaseTimeout() {
    setTimeoutValue(timeoutValue + 5);
  }

  useEffect(() => {
    onValueChange(timeoutValue);
  }, [timeoutValue, onValueChange]);

  useEffect(() => {
    setTimeoutValue(initialValue);
  }, [initialValue, resetStepper]);

  return (
    <div className="iwk-stepper">
      <Button
        variant="primary"
        className="iwk-stepper__button -decrease"
        onClick={() => checkAndDecreaseTimeout()}
      >
        -
      </Button>
      <div type="text" disabled className="iwk-stepper__label">
        {timeoutValue} minutes
      </div>
      <Button
        variant="primary"
        className="iwk-stepper__button -increase"
        onClick={() => increaseTimeout()}
      >
        +
      </Button>
    </div>
  );
}

export default memo(IncrementalStepper);
