import {
  CONNECTION_AVAILABLE,
  CONNECTION_ERROR
} from "actions/serverConnection";

export const serverConnectionInitialState = {
  error: null
};

export function serverConnectionReducer(state, action) {
  switch (action.type) {
    case CONNECTION_AVAILABLE:
      return {
        ...state,
        error: null
      };

    case CONNECTION_ERROR:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
}
