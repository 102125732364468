import {
  SETTINGS_RECEIVED,
  SETTING_UPDATED
} from "actions/settings";

export const settingsInitialState = {
  settings: undefined
};

export function settingsReducer(state, action) {
  switch (action.type) {
    case SETTINGS_RECEIVED:
      return {
        ...state,
        settings: action.settings
      }

    case SETTING_UPDATED:
      const settings = state.settings;
      const setting = settings.find((setting) => setting.id === action.settingId);
      setting.value = action.settingValue;

      return { ...state, settings }

    default:
      return { ...state }
  }
}