import React, { memo, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./ResetPassword.scss";

import { fetchRequest } from "utils/fetch";
import validate from "./ResetPasswordFormValidationRules";
import useForm from "hooks/useForm";
import Logo from "components/Logo/Logo";

const ResetPassword = ({ history }) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    validate,
    onResetPassword
  );
  const [emailSent, setEmailSent] = useState(false);

  function onResetPassword() {
    fetchRequest(undefined, "POST", "forgot-password", {
      email: values.email,
      type: "admin"
    })
      .then(result => {
        setEmailSent(true);
      })
      .catch(error => {
        console.log(error);
      });
  }

  function goToLogin() {
    history.push("/login");
  }

  if (emailSent) {
    return (
      <div className="iwk-reset-password">
        <Logo />
        <div className="iwk-reset-password__info">
          <h1 className="iwk-reset-password__info-header">Email Sent!</h1>
          <p className="iwk-reset-password__info-message">
            We have just sent an email with instructions on
            <br />
            how to reset your password
          </p>
        </div>

        <Button
          className="iwk-reset-password__form-actions-button"
          variant="primary"
          onClick={goToLogin}
        >
          Return to Log In
        </Button>
      </div>
    );
  }

  return (
    <div className="iwk-reset-password">
      <Logo />
      <div className="iwk-reset-password__info">
        <h1 className="iwk-reset-password__info-header">Reset Password</h1>
        <p className="iwk-reset-password__info-message">
          Enter your email below and we'll email you <br />
          instructions to reset your password
        </p>
      </div>

      <Form
        className="iwk-reset-password__form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Form.Group
          className="iwk-reset-password__form-group"
          controlId="loginForm.email"
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="email@example.com"
            isInvalid={errors.email}
            value={values.email || ""}
            onChange={handleChange}
          />
          {errors.email && (
            <Form.Control.Feedback
              className="iwk-reset-password__form-error"
              type="invalid"
            >
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <div className="iwk-reset-password__form-actions">
          <Button
            className="iwk-reset-password__form-actions-button"
            variant="secondary"
            onClick={goToLogin}
          >
            Return to Log In
          </Button>
          <Button
            className="iwk-reset-password__form-actions-button"
            variant="primary"
            type="submit"
          >
            Reset Password
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default memo(ResetPassword);
