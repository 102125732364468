import React, { useState } from "react";
import { Button } from "react-bootstrap";

// scss
import "../../Families/FamiliesRow/FamiliesRow.scss";

// components
import Checkbox from "components/Checkbox/Checkbox";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InfoModal from "components/InfoModal/InfoModal";
import { format } from "date-fns";

const RequestsRow = ({
  id,
  email,
  firstName,
  familyName,
  roomNumber,
  date,
  checked,
  onCheckboxUpdate,
  updateRequestStatus,
  showInviteModal,
  setShowInviteModal,
  inviteMessage,
  bulkDeleteRequests,
  inviteStatus,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="iwk-families-row">
      <div className="iwk-families-row__cell -small">
        <Checkbox
          checked={checked}
          onCheckboxCheckedCallback={(checked) => onCheckboxUpdate(id, checked)}
        />
      </div>
      <div className="iwk-families-row__cell -large">{email}</div>

      <div className="iwk-families-row__cell">{firstName}</div>

      <div className="iwk-families-row__cell">{familyName}</div>

      <div className="iwk-families-row__cell">{roomNumber}</div>

      <div className="iwk-families-row__cell">
        {format(new Date(date), "MM/dd/yyyy")}
      </div>

      <div className="iwk-families-row__cell iwk-requests-row__container">
        <Button
          variant="secondary"
          className="iwk-families-row__cell-invite"
          onClick={() => setShowDeleteModal(!showDeleteModal)}
        >
          Reject
        </Button>

        <Button
          variant="primary"
          className="iwk-families-row__cell-invite"
          onClick={() => {
            updateRequestStatus(id, 1);
          }}
        >
          Approve
        </Button>
      </div>
      <ConfirmationModal
        message={
          <div>
            <p>Did you want to reject:</p>
            <p>{email}?</p>
            <p>You will permanently delete this request.</p>
          </div>
        }
        modalCloseAction={() => setShowDeleteModal(!showDeleteModal)}
        confirmActionLabel={"Reject"}
        confirmAction={() => {
          updateRequestStatus(id, 0);
        }}
        cancelActionLabel={"Cancel"}
        cancelAction={() => setShowDeleteModal(!showDeleteModal)}
        show={showDeleteModal}
        title={"Are you sure?"}
      />
      <InfoModal
        title="Invite Sent"
        message={inviteMessage}
        show={showInviteModal}
        modalCloseAction={() => setShowInviteModal(!showInviteModal)}
      />
    </div>
  );
};

export default RequestsRow;

