import React, { memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useStateValue } from "state";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import "./TableHeaderButtons.scss";

const TableHeaderButtons = ({
  checkedIds,
  users,
  onBulkDeleteClick,
  onUpdateStatusClick,
  showDischargeButtons,
  showApproveButtons,
  requestsEmail,
  inviteStatus,
}) => {
  const [
    {
      accounts: { access },
    },
  ] = useStateValue();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [
    showConfirmUpdateStatusModal,
    setShowConfirmUpdateStatusModal,
  ] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [updateStatusAction, setUpdateStatusAction] = useState(undefined);
  const [currentActionButtons, setCurrentActionButtons] = useState(null);

  const activeButton = {
    message: "Activate",
    statusAction: "active",
  };

  const deactiveButton = {
    message: "Deactivate",
    statusAction: "inactive",
  };

  const dischargeButton = {
    message: "Discharge",
    statusAction: "discharged",
  };

  const approveButton = {
    message: "Approve",
    statusAction: 1,
  };

  const rejectButton = {
    message: "Reject",
    statusAction: 0,
  };

  function getFormattedIds() {
    //don't format emails for requests
    if (showApproveButtons) {
      return requestsEmail.filter((email) => email).join(", ");
    }

    return checkedIds.join(", ");
  }

  useEffect(() => {
    const updateButtons = ({
      users,
      access,
      activeButton,
      approveButton,
      deactiveButton,
      dischargeButton,
      rejectButton,
      showApproveButtons,
      showDischargeButtons,
    }) => {
      setCurrentActionButtons(null);
      let checkedUsers;

      if (users) {
        checkedUsers = users.filter((user) => user.checked);
      }
      const dischargedUsers = checkedUsers.filter(
        (user) => user.status === "discharged"
      );
      const activeUsers = checkedUsers.filter(
        (user) => user.status === "active"
      );
      const inactiveUsers = checkedUsers.filter(
        (user) => user.status === "inactive"
      );
      const requestedUsers = checkedUsers.filter(
        (user) => user.checked && showApproveButtons
      );

      // activate and deactivate buttons (active, inactive, discharged families)
      if (
        activeUsers.length &&
        dischargedUsers.length &&
        inactiveUsers.length
      ) {
        setCurrentActionButtons([activeButton, deactiveButton]);

        // only deactivate button (current active staff accounts)
      } else if (
        activeUsers.length &&
        !dischargedUsers.length &&
        !inactiveUsers.length &&
        !showDischargeButtons &&
        (access === "super-admin" || access === "admin")
      ) {
        setCurrentActionButtons([deactiveButton]);

        // deactivate / discharge (current active family accounts)
      } else if (
        activeUsers.length &&
        !dischargedUsers.length &&
        !inactiveUsers.length &&
        showDischargeButtons &&
        (access === "super-admin" || access === "admin")
      ) {
        setCurrentActionButtons([deactiveButton, dischargeButton]);

        // discharge button (staff able to discharge families but not deactivate)
      } else if (
        activeUsers.length &&
        !dischargedUsers.length &&
        !inactiveUsers.length &&
        showDischargeButtons &&
        access === "staff"
      ) {
        setCurrentActionButtons([dischargeButton]);

        // activate button (inactive staff accounts or inactive family accounts)
      } else if (
        inactiveUsers.length &&
        !dischargedUsers.length &&
        !activeUsers.length
      ) {
        setCurrentActionButtons([activeButton]);

        // deactivate and activate buttons (discharged family accounts or active and inactive accounts but no discharged or inactive and discharge users)
      } else if (
        (dischargedUsers.length &&
          !activeUsers.length &&
          !inactiveUsers.length &&
          (access === "super-admin" || access === "admin")) ||
        (activeUsers.length &&
          inactiveUsers.length &&
          !dischargedUsers.length &&
          (access === "super-admin" || access === "admin")) ||
        (inactiveUsers.length &&
          dischargedUsers.length &&
          !activeUsers.length &&
          (access === "super-admin" || access === "admin"))
      ) {
        setCurrentActionButtons([activeButton, deactiveButton]);
      } else if (
        activeUsers.length &&
        dischargedUsers.length &&
        !inactiveUsers.length &&
        showDischargeButtons &&
        (access === "super-admin" || access === "admin")
      ) {
        setCurrentActionButtons([
          activeButton,
          deactiveButton,
          dischargeButton,
        ]);

        // approve and reject buttons for requests
        // add in check for status requested
      } else if (requestedUsers.length) {
        setCurrentActionButtons([approveButton, rejectButton]);
      }

      // This is for the staff tab only
      if (activeUsers.length && inactiveUsers.length && !showDischargeButtons) {
        setCurrentActionButtons([activeButton, deactiveButton]);
      }
    };
    updateButtons({
      users,
      access,
      activeButton,
      approveButton,
      deactiveButton,
      dischargeButton,
      rejectButton,
      showApproveButtons,
      showDischargeButtons,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, showApproveButtons, showDischargeButtons]);

  const renderConfirmationMessage = (button) => {
    let message = "";

    if (updateStatusAction === "inactive") {
      message = "deactivate";
    } else if (updateStatusAction === "active") {
      message = "activate";
    } else if (updateStatusAction === "discharged") {
      message = "discharge";
    } else if (updateStatusAction === 0) {
      message = "reject";
    } else if (updateStatusAction === 1) {
      message = "approve";
    }

    if (button) {
      return message.charAt(0).toUpperCase() + message.slice(1);
    }
    return message;
  };

  let actionButtons;

  // use the info modal for approving requests
  if (showApproveButtons && updateStatusAction === 1) {
    actionButtons = (title, statusAction) => (
      <Button
        variant="secondary"
        className="iwk-table-header-buttons__activate"
        onClick={() => {
          setShowInviteModal(!showInviteModal);
          setUpdateStatusAction(statusAction);
        }}
      >
        {title}
      </Button>
    );
  } else {
    actionButtons = (title, statusAction) => (
      <Button
        variant="secondary"
        className="iwk-table-header-buttons__activate"
        onClick={() => {
          setShowConfirmUpdateStatusModal(true);
          setUpdateStatusAction(statusAction);
        }}
        key={title}
      >
        {title}
      </Button>
    );
  }

  return (
    <div className="iwk-table-header-buttons">
      {checkedIds.length > 0 &&
        (access === "super-admin" || access === "admin") && (
          <Button
            variant="secondary"
            className="iwk-table-header-buttons__delete"
          >
            <DeleteIcon
              className="iwk-staff__actions-delete-icon"
              onClick={() => setShowConfirmDeleteModal(true)}
            />
          </Button>
        )}

      {currentActionButtons &&
        currentActionButtons.map((button) =>
          actionButtons(button.message, button.statusAction)
        )}

      <ConfirmationModal
        message={`Did you want to delete ${getFormattedIds()}? You will not be able to access their data again.`}
        modalCloseAction={() =>
          setShowConfirmDeleteModal(!showConfirmDeleteModal)
        }
        confirmAction={() => onBulkDeleteClick(checkedIds)}
        confirmActionLabel={"Delete"}
        cancelActionLabel={"Cancel"}
        cancelAction={() => setShowConfirmDeleteModal(!showConfirmDeleteModal)}
        show={showConfirmDeleteModal}
        title={"Are you sure?"}
      />

      <ConfirmationModal
        message={
          <div>
            <p>Did you want to {renderConfirmationMessage()}</p>
            <div>{getFormattedIds()}</div>
          </div>
        }
        modalCloseAction={() =>
          setShowConfirmUpdateStatusModal(!showConfirmUpdateStatusModal)
        }
        confirmAction={() =>
          onUpdateStatusClick(checkedIds, updateStatusAction)
        }
        confirmActionLabel={renderConfirmationMessage(true)}
        cancelActionLabel={"Cancel"}
        cancelAction={() => {
          setShowConfirmUpdateStatusModal(!showConfirmUpdateStatusModal);
        }}
        show={showConfirmUpdateStatusModal}
        title={"Are you sure?"}
      />
    </div>
  );
};

export default memo(TableHeaderButtons);
