import { accountsReducer, accountsInitialState } from "./accounts";
import { babiesReducer, babiesInitialState } from "./babies";
import { familiesReducer, familiesInitialState } from "./families";
import { profilesReducer, profilesInitialState } from "./profiles";
import { rolesReducer, rolesInitialState } from "./roles";
import { settingsReducer, settingsInitialState } from "./settings";
import { staffReducer, staffInitialState } from "./staff";
import { userReducer, userInitialState } from "./user";
import { dischargeReducer, dischargeInitialState } from "./dischargeSteps";
import { requestsReducer, requestsInitialState } from "./requests";
import {
  serverConnectionReducer,
  serverConnectionInitialState
} from "./serverConnection";

export const rootReducer = (
  {
    accounts,
    babies,
    families,
    profiles,
    roles,
    settings,
    staff,
    user,
    dischargeSteps,
    requests,
    serverConnection
  },
  action
) => ({
  accounts: accountsReducer(accounts, action),
  babies: babiesReducer(babies, action),
  families: familiesReducer(families, action),
  profiles: profilesReducer(profiles, action),
  roles: rolesReducer(roles, action),
  settings: settingsReducer(settings, action),
  staff: staffReducer(staff, action),
  user: userReducer(user, action),
  dischargeSteps: dischargeReducer(dischargeSteps, action),
  requests: requestsReducer(requests, action),
  serverConnection: serverConnectionReducer(serverConnection, action)
});

export const initialState = {
  accounts: accountsInitialState,
  babies: babiesInitialState,
  families: familiesInitialState,
  profiles: profilesInitialState,
  roles: rolesInitialState,
  settings: settingsInitialState,
  staff: staffInitialState,
  user: userInitialState,
  dischargeSteps: dischargeInitialState,
  requests: requestsInitialState,
  serverConnection: serverConnectionInitialState
};
