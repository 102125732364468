import {
  ACCOUNTS_RECEIVED,
  ACCOUNT_CREATED,
  ACCOUNT_UPDATED,
  ACCOUNT_DELETED,
  ACCOUNT_ACCESS_RECEIVED
} from "actions/accounts";

export const accountsInitialState = {
  accounts: undefined,
  access: undefined
};

export function accountsReducer(state, action) {
  switch (action.type) {
    case ACCOUNTS_RECEIVED:
      return { ...state, accounts: action.accounts };

    case ACCOUNT_CREATED:
      return { ...state, accounts: [...state.accounts, action.account] };

    case ACCOUNT_UPDATED: {
      const { account: updatedAccount } = action;
      const accounts = state.accounts.map(account => {
        if (account.id !== updatedAccount.id) {
          return account;
        }
        return updatedAccount;
      });

      return { ...state, accounts };
    }

    case ACCOUNT_DELETED: {
      const accounts = state.accounts;
      const accountIndex = state.accounts.findIndex(
        account => account.id === action.accountId
      );
      accounts.splice(accountIndex, 1);

      return { ...state, accounts };
    }

    case ACCOUNT_ACCESS_RECEIVED: {
      return {
        ...state,
        access: action.access
      };
    }

    default:
      return { ...state };
  }
}
