import {
  REQUESTS_DELETED,
  REQUESTS_RECEIVED,
  REQUESTS_UPDATED,
} from "actions/requests";

export const requestsInitialState = {
  requests: undefined,
};
  
  export function requestsReducer(state, action) {
    switch (action.type) {
      case REQUESTS_RECEIVED:
        return { ...state, requests: action.requests };
      
      case REQUESTS_UPDATED: {
        const requests = state.requests;

        const requestIndex = requests.findIndex(
          request => request.id === action.request.id
        );

        if (requestIndex) {
          requests[requestIndex] = action.request;
        }

        return {...state, requests: requests };
      }

      case REQUESTS_DELETED: {
        let requests = state.requests;

        const requestIndex = state.requests.findIndex((request) => request.id === action.signUpRequestId);

        requests.splice(requestIndex, 1);

        return { ...state, requests: requests };
      }
    
      default:
        return { ...state };
    }
  }
  