import React, { memo } from "react";
import { NavLink } from "react-router-dom";

// scss
import "./Sidebar.scss";

// components
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/admin-settings.svg";

// hooks and utils
import { useStateValue } from "state";

const Sidebar = (props) => {
  const [{ accounts }] = useStateValue();

  return (
    <div className="iwk-sidebar">
      <NavLink
        activeClassName="-active"
        className="iwk-sidebar__link"
        to="/"
        isActive={checkDashboardActive}
      >
        <UsersIcon className="iwk-sidebar__link-icon" />
      </NavLink>
      {accounts.access === "super-admin" && (
        <NavLink
          activeClassName="-active"
          className="iwk-sidebar__link"
          exact
          to="/settings/general"
        >
          <SettingsIcon className="iwk-sidebar__link-icon" />
        </NavLink>
      )}
    </div>
  );
};

// checks whether the dashboard sidebar link should be active
// returns true if the url contains /staff, /families or /requests
const checkDashboardActive = (match, location) => {
  const activeRoutes = ["/staff", "/families" , "/requests"];

  return activeRoutes.some((route) => location.pathname.includes(route));
}

export default memo(Sidebar);