import React from 'react';

export function isEmpty(value) {
  return !value ? true : false;
}

export function invalidEmail(email) {
  if (!/\S+@\S+\.\S+/.test(email)) {
    return true;
  }
}

export function getFormattedName(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

/**
 * Password requirements
 * @returns
 * - {bool} if password is valid
 * - {arr} array of errors if invalid
 */
export function passwordValid(password) {
  let errors = [];

  if (!password) {
    errors.push(<li>Please enter a value</li>);
  } else {

    // minimum 8 characters
    if (password.length < 8) {
      errors.push(<li>Password must be 8 or more characters</li>);
    }

    // at least 1 uppercase character
    if (!/([A-Z]+)/g.test(password)) {
      errors.push(<li>Password must have at least 1 uppercase character</li>);
    }

    // at least 1 special character
    if (!/([!@#$%^&*(),.?":{}|<>]+)/g.test(password)) {
      errors.push(<li>Password must have at least 1 special character</li>);
    }
  }

  if (errors.length >= 1) {
    return errors;
  }
  return true;
}

/**
 * Passwords match?
 * @returns
 * - {bool} if password is valid
 * - {str} error string if invalid
 */
export function passwordsMatch(password, passwordConfirmation) {
  if (!isEmpty(password) && isEmpty(passwordConfirmation)) {
    return 'Please enter a value';
  } else if (password !== passwordConfirmation) {
    return 'Passwords do not match';
  }
  return true;
}

export const errorMessages = {
  emptyValue: "Please enter a value",
  invalidEmail: "Emails must have both an @ and domain (e.g. .com)"
}