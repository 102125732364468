export const sexOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" }
];

export const positionOptions = [
  {
    label: "Advanced practice nurse",
    options: [
      { label: "Nurse practitioner", value: "Nurse practitioner" },
      { label: "Nurse educator", value: "Nurse educator" },
      { label: "Nurse administrator ", value: "Nurse administrator" },
      { label: "Clinical nurse specialist", value: "Clinical nurse specialist" },
    ]
  },
  {
    label: "Allied health",
    options: [
      { label: "Dietician", value: "Dietician" },
      { label: "Pharmacist", value: "Pharmacist" },
      { label: "Occupational therapist", value: "Occupational therapist" },
      { label: "Physical therapist", value: "Physical therapist" },
      { label: "Respiratory therapist", value: "Respiratory therapist" },
      { label: "Allied health - Other", value: "Allied health - Other" },
    ]
  },
  {
    label: "Medical doctor",
    options: [
      { label: "Family physician", value: "Family physician" },
      { label: "General practitioner", value: "General practitioner" },
      { label: "Medical house staff", value: "Medical house staff" },
      { label: "Neonatologist", value: "Neonatologist" },
      { label: "Medical fellow", value: "Medical fellow" },
      { label: "Medical residents", value: "Medical residents" },
      { label: "Medical student", value: "Medical student" },
      { label: "Psychiatrist", value: "Psychiatrist" },
      { label: "Medical doctor - Other", value: "Medical doctor - Other" },
    ]
  },
  {
    label: "Parent partner",
    options: [
      { value: "Parent partner", label: "Parent partner" }
    ]
  },
  {
    label: "Psychologist",
    options: [
      { value: "Psychologist", label: "Psychologist" }
    ]
  },
  {
    label: "Registered nurse",
    options: [
      { value: "Registered nurse", label: "Registered nurse" }
    ]
  },
  {
    label: "Research staff",
    options: [
      { value: "Research assistant", label: "Research assistant" },
      { value: "Research coordinator", label: "Research coordinator" },
      { value: "Research manager", label: "Research manager" },
      { value: "Research trainee", label: "Research trainee" },
      { value: "Research staff - Other", label: "Research staff - Other" },
    ]
  },
  {
    label: "Social worker",
    options: [
      { value: "Social worker", label: "Social worker" }
    ]
  },
  {
    label: "Other",
    options: [
      { value: "Other", label: "Other" }
    ]
  }
];

export const accessOptions = [
  { label: "Staff", value: "staff" },
  { label: "Admin", value: "admin" },
  { label: "Super Admin", value: "super-admin" },
  { label: "None", value: "none" }
];
