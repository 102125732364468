import { passwordsMatch, passwordValid } from "utils/validation";

export default function validate({
  password,
  passwordConfirmation,
  confirmTerms,
}) {
  let errors = {};

  // ensure password meets requirements
  const isPasswordValid = passwordValid(password);
  if (isPasswordValid !== true) {
    errors.password = isPasswordValid;
  }

  // ensure confirmation password matches
  const doPasswordsMatch = passwordsMatch(password, passwordConfirmation);
  if (doPasswordsMatch !== true) {
    errors.passwordConfirmation = doPasswordsMatch;
  }

  // ensure user accepts terms
  const hasConfirmed = confirmTerms;
  if (!hasConfirmed) {
    errors.confirmTerms =
      "You must accept the Terms of Service before creating an account";
  }

  return errors;
}
