import React, { memo, useRef, useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import {
  DISCHARGE_STEPS_RECEIVED,
  DISCHARGE_PROGRESS_RECEIVED,
  DISCHARGE_STEPS_FAILURE,
  DISCHARGE_PROGRESS_FAILURE
} from "actions/discharge";
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import DischargePath from "components/DischargePath/Container";
import Loading from "components/Loading/Loading";
import Error from "components/Error/Error";
import Spinner from "react-bootstrap/Spinner";
import "./DischargeReport.scss";
import FooterSectionRow from "./FooterSectionRow";

const DischargeReport = ({ match }) => {
  const familyId = match.params.familyId;
  const pdfDoc = useRef(null);
  const [steps, setSteps] = useState([]);
  const [fullCompletion, setFullCompletion] = useState(false);
  const [{ user, dischargeSteps }, dispatch] = useStateValue();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [error, setError] = useState(null);

  // update error state if dischargeSteps error
  useEffect(() => {
    setError(dischargeSteps.error);
  }, [dischargeSteps.error]);

  const appendHomeNode = useCallback(
    steps => {
      let returnSteps = steps.map(step => {
        const { completion } = step;
        const isStarted = completion > 0;
        const isComplete = completion === 100;

        if (isComplete) {
          return {
            ...step,
            status: "complete"
          };
        } else if (isStarted) {
          return {
            ...step,
            status: "active"
          };
        }
        return {
          ...step,
          status: "inProgress"
        };
      });

      if (returnSteps.length > 0) {
        // get the next highest step number to produce the "Home" node
        const nextStep =
          returnSteps.reduce((acc, o) => {
            const currentStep =
              (o.acf && parseInt(o.acf.step_number, 10)) || -1;
            return currentStep > acc ? currentStep : acc;
          }, 0) + 1;

        // adding an additional node to render appropriately. Requires acf.step_number and topcis array to work
        returnSteps = returnSteps.concat([
          {
            ID: -99,
            reason: "home",
            acf: {
              step_number: `${nextStep}`
            },
            topics: [],
            status: fullCompletion ? "homeComplete" : "homeIncomplete"
          }
        ]);
      }
      return returnSteps;
    },
    [fullCompletion]
  );

  useEffect(() => {
    // get all steps
    fetchRequest(user.token, "GET", "steps", undefined)
      .then(steps => {
        dispatch({
          type: DISCHARGE_STEPS_RECEIVED,
          data: steps
        });

        fetchRequest(
          user.token,
          "GET",
          `accounts/${familyId}/profiles`,
          undefined
        )
          .then(profiles => {
            const profileId =
              profiles &&
              profiles.length > 0 &&
              profiles.find(profile => profile.accountHolder).id;

            fetchRequest(
              user.token,
              "GET",
              `accounts/${familyId}/profiles/${profileId}/progress`,
              undefined
            )
              .then(dischargeProgress => {
                dispatch({
                  type: DISCHARGE_PROGRESS_RECEIVED,
                  data: dischargeProgress
                });
              })
              .catch(error => {
                dispatch({
                  type: DISCHARGE_PROGRESS_FAILURE,
                  data: error
                });
              });
          })
          .catch(error => {
            dispatch({
              type: DISCHARGE_PROGRESS_FAILURE,
              data: error
            });
          });
      })
      .catch(error => {
        dispatch({
          type: DISCHARGE_STEPS_FAILURE,
          data: error
        });
      });
  }, [dispatch, familyId, user.token]);

  useEffect(() => {
    const { dischargeSteps: dischargeStepsArray } = dischargeSteps;
    if (
      dischargeStepsArray &&
      dischargeStepsArray[0] &&
      !Number.isNaN(dischargeStepsArray[0].completion)
    ) {
      setFullCompletion(
        dischargeStepsArray.reduce((acc, o) => acc && o.completion === 100)
      );
      setSteps(appendHomeNode(dischargeStepsArray));
    }
  }, [appendHomeNode, dischargeSteps]);

  const handleClick = () => {
    setPdfLoading(true);
    const pdfDoc = document.getElementById("myMm");
    const canvas = document.getElementById("iwk-canvas");

    // Use clone with htm2canvas and delete clone
    const options = {
      foreignObjectRendering: true,
      x: pdfDoc.offsetLeft,
      scrollX: pdfDoc.offsetLeft,
      scrollY: pdfDoc.offsetTop,
      y: pdfDoc.offsetTop,
      imageTimeout: 15000,
      allowTaint: true,
      useCORS: true,
      letterRendering: 1,
      canvas
    };

    html2canvas(pdfDoc, options).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        putOnlyUsedFonts: true
      });

      const docWidth = doc.internal.pageSize.getWidth();
      const imgWidth = pdfDoc.offsetWidth;
      const imgHeight = pdfDoc.offsetHeight;
      // TODO Determine page margins and resolve issue
      // const defaultMargin = 2.54 / 8;

      const ratio = docWidth / pdfDoc.offsetWidth;

      doc.addImage(imgData, "PNG", 0, 0, imgWidth * ratio, imgHeight * ratio);

      doc.save("discharge.pdf");
      setPdfLoading(false);
    });
  };

  if (error) {
    return <Error />;
  }

  return (
    <div className="iwk-discharge-report">
      {steps.length > 0 && typeof steps[0].completion === "number" ? (
        <>
          <div className="iwk-discharge-report-download__row">
            <Button
              disabled={pdfLoading ? true : false}
              className="iwk-discharge-report-download__button"
              variant="primary"
              onClick={() => handleClick()}
            >
              {pdfLoading ? (
                <Spinner
                  className="iwk-login__form-button-spinner"
                  animation="border"
                />
              ) : (
                "Download"
              )}
            </Button>
          </div>
          <div className="iwk-discharge-report-pdf" id="myMm" ref={pdfDoc}>
            <div className="iwk-discharge-report-pdf__header">
              <h2 className="iwk-discharge-report-pdf__header-title">
                Discharge Pathway
              </h2>
              <h3 className="iwk-discharge-report-pdf__header-family-id">
                ID: {familyId}
              </h3>
            </div>
            <DischargePath
              nodes={steps}
              rowHeight={150}
              columnCount={4}
              fullCompletion={fullCompletion}
            />
            <div className="iwk-discharge-report-pdf__footer">
              <FooterSectionRow title="Family" numRows={3} />
              <FooterSectionRow
                title="Health Care Provider"
                numRows={1}
                topMargin={true}
              />
            </div>
          </div>
          <canvas id="iwk-canvas" className="iwk-discharge-report__canvas" />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default memo(DischargeReport);
