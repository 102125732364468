import React, { memo, useEffect } from "react";
import { NavLink, Route, Switch } from "react-router-dom";

import "./Settings.scss";

import { fetchRequest } from "utils/fetch";
import { useStateValue } from "state";
import { SETTINGS_RECEIVED } from "actions/settings";

import GeneralSettings from "./GeneralSettings/GeneralSettings";

const Settings = (props) => {
  const [{ user, settings }, dispatch] = useStateValue();

  useEffect(() => {
    if (settings.settings) {
      return;
    }

    fetchRequest(user.token, "GET", "settings", undefined)
      .then((settings) => {
        dispatch({
          type: SETTINGS_RECEIVED,
          settings,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }, [settings, user, dispatch]);

  return (
    <div className="iwk-settings">
      <div className="iwk-settings__header">
        <NavLink
          activeClassName="-active"
          className="iwk-settings__header-tab"
          exact
          to="/settings/general"
        >
          Settings
        </NavLink>
      </div>

      <Switch>
        <Route path="/settings/general" exact component={GeneralSettings} />
      </Switch>
    </div>
  );
};

export default memo(Settings);
