import React, { memo, useState } from "react";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./BabiesRow.scss";

// components
import Dropdown from "components/Dropdown/Dropdown";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

// hooks and utils
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import { sexOptions } from "utils/options";
import validate from "../BabyValidationRules";
import useForm from "hooks/useForm";

// actions
import { BABY_DELETED, BABY_UPDATED } from "actions/babies";

export const BabiesRow = ({
  id,
  familyId,
  status,
  name,
  sex,
  room,
  birthDate,
  dueDate,
  admissionDate,
  deliveryType,
  diagnosis,
  checked,
  onCheckboxUpdate,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { resetForm } = useForm();
  const [{ user, accounts }, dispatch] = useStateValue();

  const selectedSex = sexOptions.find((sexOption) => sexOption.value === sex);

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `editBabyForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  function editBaby(values) {
    if (!values.name) {
      return;
    }

    const babyBody = {
      accountId: familyId,
      name: values.name,
      sex: values.sex.value,
      dueDate: values.dueDate,
      birthDate: values.birthDate,
      admissionDate: values.admissionDate || null,
      deliveryType: values.deliveryType || null,
      diagnosis: values.diagnosis || null,
      room: values.room || null,
      // TODO: Find out what this color scheme is for
      colorScheme: "",
    };

    fetchRequest(user.token, "PATCH", `/babies/${id}`, babyBody)
      .then((baby) => {
        dispatch({
          type: BABY_UPDATED,
          baby,
        });

        setShowEditModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function deleteBaby(babyId) {
    fetchRequest(user.token, "DELETE", `babies/${babyId}`, undefined)
      .then((result) => {
        dispatch({
          type: BABY_DELETED,
          babyId,
        });

        setShowDeleteModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  // This effect is used to set the state values with the information passed down from the parent so that
  // when the edit modal is opened the user doesn't have to re-type everything to save their changes
  // useEffect(() => {
  //   values.name = name;
  //   values.sex = sex;
  //   values.birthDate = birthDate;
  //   values.dueDate = dueDate;
  //   values.admissionDate = admissionDate;
  //   values.deliveryType = deliveryType;
  //   values.diagnosis = diagnosis;
  //   values.room = room;
  // }, [showEditModal, name, sex, birthDate, dueDate, admissionDate, deliveryType, diagnosis, room]);

  return (
    <div className="iwk-babies-row">
      {/* <div className="iwk-babies-row__cell -small">
        <Checkbox
          checked={checked}
          onCheckboxCheckedCallback={checked => onCheckboxUpdate(id, checked)}
        />
      </div> */}
      {/* <div className="iwk-babies-row__cell">
        <span>{capitalize(status)}</span>
      </div> */}
      <div className="iwk-babies-row__cell">
        <span>{name}</span>
      </div>
      <div className="iwk-babies-row__cell">{sex}</div>

      <div className="iwk-babies-row__cell">{room}</div>

      <div className="iwk-babies-row__cell -icons -small">
        {accounts.access === "admin" ||
          (accounts.access === "super-admin" && (
            <button
              onClick={() => setShowDeleteModal(!showDeleteModal)}
              className="iwk-babies-row__cell-button"
            >
              <DeleteIcon className="iwk-babies-row__cell-icon" />
            </button>
          ))}
        <button
          onClick={() => setShowEditModal(!showEditModal)}
          className="iwk-babies-row__cell-button -edit"
        >
          <EditIcon className="iwk-babies-row__cell-icon -edit" />
        </button>
      </div>

      <ConfirmationModal
        message={`Did you want to delete ID ${id}? You will not be able to access their data again.`}
        modalCloseAction={() => setShowDeleteModal(!showDeleteModal)}
        confirmAction={() => deleteBaby(id)}
        confirmActionLabel={"Delete"}
        cancelActionLabel={"Cancel"}
        cancelAction={() => setShowDeleteModal(!showDeleteModal)}
        show={showDeleteModal}
        title={"Are you sure?"}
      />

      <FormModal
        modalCloseAction={() => setShowEditModal(!showEditModal)}
        show={showEditModal}
        title={"Edit Baby"}
        validate={(values) => validate(values)}
        submitAction={async (newValues) => {
          editBaby(newValues);
        }}
        resetAction={resetForm}
        className={"--overflow-scroll"}
      >
        <Field
          name="name"
          type="text"
          initialValue={name}
          component={FormInput}
          label="Name"
        />
        <Field
          name="sex"
          component={FormDropdown}
          type="select"
          value={selectedSex}
          initialValue={selectedSex}
          selectedValue={selectedSex}
          options={sexOptions}
          label="Sex"
        ></Field>
        <Field
          name="birthDate"
          type="date"
          initialValue={birthDate}
          component={FormInput}
          label="Birth Date"
        />
        <Field
          name="dueDate"
          type="date"
          initialValue={dueDate}
          component={FormInput}
          label="Due Date"
        />
        <Field
          name="admissionDate"
          type="date"
          initialValue={admissionDate}
          component={FormInput}
          label="Admission Date"
        />
        <Field
          name="deliveryType"
          type="text"
          initialValue={deliveryType}
          component={FormInput}
          label="Delivery Type"
        />
        <Field
          name="diagnosis"
          type="text"
          initialValue={diagnosis}
          component={FormInput}
          label="Primary Diagnosis"
        />
        <Field
          name="room"
          type="text"
          initialValue={room}
          component={FormInput}
          label="Room"
        />
      </FormModal>
    </div>
  );
};

export default memo(BabiesRow);
