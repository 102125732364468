import React from "react";
import InfoModal from "components/InfoModal/InfoModal";
import "./TermsOfService.scss";

const message = (
  <>
    <h3>Use of Web Site</h3>
    <p>
      By using this Web Site (the “Web Site”) of the IWK Health Centre, you
      agree to be bound by the following terms and conditions. The IWK Health
      Centre may change these terms and conditions by posting such changes on
      the Web Site. Your continued use of the Web Site constitutes your
      acceptance of such changed terms and conditions. No Healthcare or Medical
      Advice.
    </p>
    <p>
      Information, material and content on or available via this site
      (“Content”) is for informational purposes only and is not intended to
      provide medical or other advice or recommendations, and should not be
      relied upon in that regard. You should not rely on the Content to replace
      consultation with qualified health care professionals to meet your, or
      your child’s, health care needs. This website contains links to other
      Internet sites. These links are provided as references to help you
      identify and locate other Internet resources that may be of interest. Such
      links are provided for reference only and were independently developed by
      parties other than the IWK. Therefore, the IWK is not responsible for the
      content of any such linked sites and does not assume any responsibility
      for the accuracy or appropriateness of the information contained at such
      sites or of the content of any file you might choose to download from such
      sites. The inclusion of any link does not imply endorsement by the IWK of
      the site.
    </p>
    <h3>Limited Licence</h3>
    <p>
      The IWK Health Centre grants you a limited license to display on your
      computer, print, download and use the information contained in this Web
      Site for your own personal, non-commercial or educational purposes only,
      provided that:You do not modify the information; andAll copyright and
      other proprietary notices are retained.No other use of the information is
      permitted. Without limiting the generality of the foregoing, you may
      not:Use any of the information for any commercial purpose, or for any
      public display (commercial or non-commercial);Include any of the
      information or reference thereto in or with any information, product or
      service that you create, distribute or provide;Copy or transfer any of the
      information onto your own web site or any other web site or server. No
      Warranties Any information on this Web Site represents the opinions of the
      authors and not necessarily of the IWK Health Centre. The IWK Health
      Centre does not guarantee or warrant the quality, accuracy, completeness,
      timeliness, appropriateness or suitability of the information on the
      Website or of any product or services referenced on the Web Site.
      Reference to or mention of specific products, processes or services do not
      constitute or imply a recommendation or endorsement. The IWK Health Centre
      assumes no obligation to update the information or advise on further
      developments concerning topics mentioned. The information may be changed
      from time to time without notice.The IWK Health Centre disclaims all
      warranties, representations and conditions regarding use of the Web Site
      or the information, including all implied warranties or conditions of
      merchantability, fitness for a particular purpose, non-infringement,
      whether expressed or implied.The IWK Health Centre does not warrant that
      the functions contained in the Web Site or the Content will be
      uninterrupted or error free, or that the Web Site is free of harmful
      elements such as viruses. Disclaimer of Liability In no event shall the
      IWK Health Centre, or any of its directors, officers, employees,
      professional or other staff or consultants, be liable in any manner for
      direct, indirect, special, consequential or punitive or other damages,
      however caused, arising out of your use of this Web Site and/or any web
      browser, including any damages you may suffer in contract, tort,
      negligence equity, statute law, if you transmit confidential or sensitive
      information to the IWK Health Centre, if the IWK Health Centre
      communicates such information to you at your request over the Internet, or
      out of the Content or any failure of performance, error, omission defect
      or other problem with this Web Site.
    </p>
  </>
);

const TermsOfService = ({ open, onClose }) => {
  return (
    <InfoModal
      className={"--terms-of-service"}
      title={"Terms of Service"}
      message={message}
      show={open}
      modalCloseAction={onClose}
      size={"lg"}
    />
  );
};

export default TermsOfService;
