import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import "./Login.scss";
import config from "config";
import validate from "./LoginFormValidationRules";
import useForm from "hooks/useForm";
import Logo from "components/Logo/Logo";

import useLogin from "hooks/useLogin";

const Login = ({ history }) => {
  const { values, errors, handleChange, handleSubmit, setErrors } = useForm(
    validate,
    onSubmit
  );
  const [token, setToken] = useState(null);
  useLogin(token, onLogin);

  const [loading, setLoading] = useState(false);

  const options = {
    method: "POST",
    body: JSON.stringify({
      email: values.email,
      password: values.password
    }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  function onLogin(isLoggedIn) {
    if (isLoggedIn) {
      history.push("/staff");
    }
  }

  function onSubmit() {
    setLoading(true);
    fetch(`${config.api.baseURL}/login`, options)
      .then(res => {
        // handle incorrect password
        if (!res.ok) {
          // only want to show the unauthorized error under the password
          // but have both inputs light up with error border
          const errors = {
            email: " ",
            password:
              "Sorry, the email or password isn't right. Forgot your password?"
          };
          setLoading(false);
          setErrors(errors);
        }

        return res;
      })
      .catch(error => {
        // handle server errors
        const errors = {
          email: " ",
          password: "Something went wrong on our end. Try again later."
        };
        setLoading(false);
        setErrors(errors);
        throw new Error();
      })
      .then(res => res.json())
      .then(res => setToken(res.token))
      .catch(error => {
        // do nothing
      });
  }

  return (
    <div className="iwk-login">
      <Logo />

      <Form className="iwk-login__form" onSubmit={handleSubmit} noValidate>
        <Form.Group
          className="iwk-login__form-group"
          controlId="loginForm.email"
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            isInvalid={errors.email}
            value={values.email || ""}
            onChange={handleChange}
          />
          {errors.email && (
            <Form.Control.Feedback
              className="iwk-login__form-error"
              type="invalid"
            >
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group
          className="iwk-login__form-group"
          controlId="loginForm.password"
        >
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            isInvalid={errors.password}
            value={values.password || ""}
            onChange={handleChange}
          />
          {errors.password && (
            <Form.Control.Feedback
              className="iwk-login__form-error"
              type="invalid"
            >
              {errors.password}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Button
          className="iwk-login__form-button"
          variant="primary"
          type="submit"
        >
          Log In
          {loading && (
            <Spinner
              className="iwk-login__form-button-spinner"
              animation="border"
            />
          )}
        </Button>
      </Form>

      <Link className="iwk-login__forgot-password" to="/reset-password">
        Forgot your password?
      </Link>
    </div>
  );
};

export default memo(Login);
