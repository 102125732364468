import React from 'react';
import { Route, Switch } from "react-router-dom";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";

import Dashboard from "containers/Dashboard/Dashboard";
import Settings from "containers/Settings/Settings";

import './MainDashboard.scss';

import { useStateValue } from "state";

function MainDashboard() {
  const [{ accounts }] = useStateValue();

  return (
    <div>
      <Header></Header>
      <div className="iwk-main-dashboard">
        <Sidebar></Sidebar>

        <Switch>
          {
            accounts.access === "super-admin" &&
            <Route path="/settings/general" exact component={Settings} />
          }
          <Route path="/" component={Dashboard}/>
        </Switch>
      </div>
    </div>
  )
}

export default MainDashboard;