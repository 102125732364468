import {
  FAMILIES_RECEIVED,
  FAMILY_CREATED,
  FAMILY_DELETED,
  FAMILY_INVITE_SENT
} from "actions/families"

export const familiesInitialState = {
  families: undefined
};

export function familiesReducer(state, action) {
  switch (action.type) {
    case FAMILIES_RECEIVED:
      return { ...state, families: action.families }

    case FAMILY_INVITE_SENT: {
      const families = state.families;
      const family = families.find((family) => family.id === action.id);
      family.inviteSent = true;

      return { ...state, families }
    }

    case FAMILY_CREATED:
      return { ...state, families: [...state.families, action.family]}

      case FAMILY_DELETED: {
        const families = state.families;
        const familyIndex = state.families.findIndex((family) => family.id === action.accountId);
        families.splice(familyIndex, 1);

        return { ...state, families }
      }

    default:
      return { ...state }
  }
}