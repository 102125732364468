import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { StateProvider } from './state';
import { rootReducer, initialState } from "./reducers";

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={rootReducer}>
    <Router>
      <Route path="/" component={App}/>
    </Router>
  </StateProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
