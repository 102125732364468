import React, { memo, useEffect, useRef, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Field } from "react-final-form";
import FormStyled from "react-bootstrap/Form";
import { generate } from "generate-password";

// stylesheets
import "./Staff.scss";

// components
import TableHeaderButtons from "components/TableHeaderButtons/TableHeaderButtons";
import Checkbox from "components/Checkbox/Checkbox";
import Dropdown from "components/Dropdown/Dropdown";
import Search from "components/Search/Search";
import StaffRow from "./StaffRow/StaffRow";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import InfoModal from "components/InfoModal/InfoModal";

// hooks and utils
import useForm from "hooks/useForm";
import validate from "./StaffFormValidationRules";
import { accessOptions as importedAccessOptions } from "utils/options";
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import { sortAccountByDateCreated } from "utils/utils";

import { STAFF_CREATED, STAFF_DELETED, STAFF_UPDATED } from "actions/staff";
import {
  ACCOUNT_CREATED,
  ACCOUNT_DELETED,
  ACCOUNT_UPDATED
} from "actions/accounts";
import { PROFILE_CREATED, PROFILES_DELETED } from "actions/profiles";

const Staff = props => {
  const { resetForm } = useForm();
  const [{ accounts, staff, profiles, roles, user }, dispatch] = useStateValue();
  const [staffMembers, setStaffMembers] = useState(undefined);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState([]);
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [showDeleteInfoModal, setShowDeleteInfoModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [tableHeaderUsers, setTableHeaderUsers] = useState([]);
  const [accessOptions, setAccessOptions] = useState(importedAccessOptions);
  const latestCheckedIds = useRef(checkedIds);
  const [duplicatedEmailError, setDuplicatedEmailError] = useState({
    exists: false
  });

  // Limit admins from creating super admins
  useEffect(() => {
    if (accounts.access === "admin") {
      setAccessOptions(accessOptions.filter((level) => {
        return level.value !== "super-admin";
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts.access]);

  // Types of inputs found in this section
  const FormInput = options =>
    createFormInput(
      FormStyled.Control,
      options,
      `accountSettingsForm.${options.input.name}`
    );
  const FormDropdown = options => createFormInput(Dropdown, options);

  const onFormSubmission = async newValues => {
    addStaff(newValues);
  };

  // keep checkedIds, latestCheckedIds and table header users in sync with filtered staff members
  useEffect(() => {
    if (filteredStaffMembers.length > 0) {
      latestCheckedIds.current = Array.from(
        filteredStaffMembers.filter(({ account }) => account.checked),
        ({ account }) => account.id
      );
      const newTableHeaderUsers = filteredStaffMembers.map(({ account }) => ({
        status: account.status,
        checked: account.checked
      }));
      setCheckedIds(latestCheckedIds.current);
      setTableHeaderUsers(newTableHeaderUsers);
    }
  }, [filteredStaffMembers]);

  const filterStaff = useMemo(() => (
    <React.Fragment>
      <span className="iwk-staff__actions-value">{filteredStaffMembers.filter(member => member.account.onboarded).length} Onboarded</span>

      <span className="iwk-staff__actions-separator">|</span>

      <span className="iwk-staff__actions-value">{filteredStaffMembers.filter(member => !member.account.onboarded && member.account.inviteSent).length} Invited</span>

      <span className="iwk-staff__actions-separator">|</span>

      <span className="iwk-staff__actions-value">{filteredStaffMembers.length} Total</span>
    </React.Fragment>
  ), [filteredStaffMembers])

  function onBulkCheckboxClick(checked) {
    let ids = [];
    const tableHeaderUsers = filteredStaffMembers.map(
      ({ account, profile }) => {
        account.checked = checked;

        if (checked) {
          ids.push(account.id);
        }

        return {
          status: account.status,
          checked
        };
      }
    );

    const staffMembers = filteredStaffMembers.map(({ account, profile }) => {
      account.checked = checked;
      return {
        account,
        profile
      };
    });

    latestCheckedIds.current = ids;
    setCheckedIds(latestCheckedIds.current);
    setFilteredStaffMembers(staffMembers);
    setTableHeaderUsers(tableHeaderUsers);
  }

  function updateCheckedIds(id, checked) {
    if (checked) {
      latestCheckedIds.current.push(id);
    } else {
      const checkedIndex = checkedIds.findIndex(checkedId => checkedId === id);
      latestCheckedIds.current.splice(checkedIndex, 1);
    }

    const staffMembers = filteredStaffMembers.map(({ account, profile }) => {
      if (account.id === id) {
        account.checked = checked;
      }

      return {
        account,
        profile
      };
    });

    const tableHeaderUsers = filteredStaffMembers.map(
      ({ account, profile }) => {
        return {
          status: account.status,
          checked: account.id === id ? checked : account.checked
        };
      }
    );

    setFilteredStaffMembers(staffMembers);
    setTableHeaderUsers(tableHeaderUsers);
    setCheckedIds(latestCheckedIds.current);
  }

  function addStaff(values) {
    if (!values.email) {
      return;
    }

    const password = generate({
      length: 10,
      numbers: true
    });

    const accountBody = {
      email: values.email,
      role: "admin",
      access: values.accessLevel.value,
      password,
      passwordConfirmation: password,
      status: "active"
    };

    const profileBody = {
      accountHolder: true,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      status: "active",
      roleId: roles.roles.find(role => role.name === "other").id
    };

    let account;

    fetchRequest(user.token, "POST", "accounts", accountBody)
      .then(response => {
        if (response.message) {
          setDuplicatedEmailError({
            exists: true,
            message: "An account with that email address is already in use"
          });

          throw new Error();
        }
        account = response;

        return fetchRequest(
          user.token,
          "POST",
          `accounts/${account.id}/profiles`,
          profileBody
        );
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
        throw new Error();
      })
      .then(profile => {
        dispatch({
          type: PROFILE_CREATED,
          profile
        });

        dispatch({
          type: STAFF_CREATED,
          staff: account
        });

        dispatch({
          type: ACCOUNT_CREATED,
          account
        });

        setShowAddStaffModal(false);
        resetForm();
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });
  }

  // removes hypens and capitilizes each word
  function formatStaffFields(access) {
    const string = access.replace(/-/g, " ");

    return string.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function handleSearchInput(input) {
    const staff = staffMembers.filter(({ account, profile }) => {
      if (input === "") {
        return true;
      }

      const id = account.id;
      const email = account.email;
      const name = `${profile.firstName} ${profile.lastName}`;

      return (
        id.toLowerCase().includes(input) || name.toLowerCase().includes(input) || email.toLowerCase().includes(input)
      );
    });

    setFilteredStaffMembers(staff);
  }

  function bulkDeleteStaff(ids) {
    for (let id of ids) {
      if (id !== user.user.accountId) {
        fetchRequest(user.token, "DELETE", `accounts/${id}`, undefined)
          .then(result => {
            dispatch({
              type: ACCOUNT_DELETED,
              accountId: id
            });

            dispatch({
              type: STAFF_DELETED,
              accountId: id
            });

            dispatch({
              type: PROFILES_DELETED,
              accountId: id
            });
          })
          .catch(error => {
            // TODO app error handling
            console.log(error);
          });
      } else {
        setShowDeleteInfoModal(true);
      }
    }
  }

  function updateStaffStatus(ids, action) {
    for (let accountId of ids) {
      let { account } = staffMembers.find(
        ({ account }) => account.id === accountId
      );
      const tableUsers = tableHeaderUsers.map(({ id, status, checked }) => {
        if (id === accountId) {
          checked = true;
          status = action;
        }

        return {
          status,
          checked,
          id
        };
      });

      if (account.status !== action) {
        // set the account status to the action
        account.status = action;
        fetchRequest(user.token, "PATCH", `accounts/${account.id}`, account)
          .then(account => {
            dispatch({
              type: ACCOUNT_UPDATED,
              account
            });

            dispatch({
              type: STAFF_UPDATED,
              staff: account
            });
          })
          .catch(error => {
            // TODO app error handling
            console.log(error);
          });

        setTableHeaderUsers(tableUsers);
      }
    }
  }

  useEffect(() => {
    if (!staff.staff || !profiles.profiles) {
      return;
    }

    const staffMembers = staff.staff
      .sort((a, b) => sortAccountByDateCreated(a, b))
      .map(account => {
        const profile = profiles.profiles.find(
          profile => profile.accountId === account.id
        );

        return {
          account,
          profile
        };
      });

    setStaffMembers(staffMembers);
    setFilteredStaffMembers(staffMembers);

    const tableHeaderUsers = staffMembers.map(({ account, profile }) => {
      return {
        status: account.status,
        checked: account.checked || false
      };
    });

    setTableHeaderUsers(tableHeaderUsers);
  }, [profiles, staff.staff]);

  // TODO loading table while staffMembers loads?
  return (
    <div className="iwk-staff">
      <div className="iwk-staff__search">
        <Search
          placeholder="Search table"
          handleSearch={handleSearchInput}
        />
      </div>

      <div className="iwk-staff__actions">
        <TableHeaderButtons
          checkedIds={latestCheckedIds.current}
          users={tableHeaderUsers}
          onBulkDeleteClick={bulkDeleteStaff}
          onUpdateStatusClick={updateStaffStatus}
          showDischargeButtons={false}
        />

        <div>
          {filteredStaffMembers && (
            filterStaff
          )}
          <Button
            variant="primary"
            className="iwk-staff__actions-add"
            onClick={() => setShowAddStaffModal(!showAddStaffModal)}
          >
            Add Staff
          </Button>
        </div>
      </div>

      <div className="iwk-staff__table">
        <div className="iwk-staff__table-header">
          <div className="iwk-staff__table-header-cell -small">
            <Checkbox
              checked={
                checkedIds.length === filteredStaffMembers.length &&
                checkedIds.length
              }
              full={
                checkedIds.length > 0 &&
                checkedIds.length !== filteredStaffMembers.length
              }
              onCheckboxCheckedCallback={checked =>
                onBulkCheckboxClick(checked)
              }
            />
          </div>
          <div className="iwk-staff__table-header-cell">Status</div>
          <div className="iwk-staff__table-header-cell">Name</div>
          <div className="iwk-staff__table-header-cell">Access</div>
          <div className="iwk-staff__table-header-cell">Position</div>
          <div className="iwk-staff__table-header-cell">Invites</div>
          <div className="iwk-staff__table-header-cell -small">Actions</div>
        </div>
        {filteredStaffMembers &&
          filteredStaffMembers.map((staff, index) => {
            if (!staff.profile) {
              return <></>
            }

            return (
              <StaffRow
                status={formatStaffFields(staff.account.status)}
                id={staff.account.id}
                name={`${staff.profile.firstName} ${staff.profile.lastName}`}
                firstName={staff.profile.firstName}
                lastName={staff.profile.lastName}
                access={formatStaffFields(staff.account.access)}
                position={staff.account.position}
                inviteSent={staff.account.inviteSent}
                onboarded={staff.account.onboarded}
                email={staff.account.email}
                checked={staff.account.checked}
                researchId={staff.account.researchId}
                onCheckboxUpdate={(id, checked) =>
                  updateCheckedIds(id, checked)
                }
                profileId={staff.profile.id}
                key={`staff-${index}`}
              />
            );
          })}
      </div>

      <FormModal
        modalCloseAction={() => setShowAddStaffModal(!showAddStaffModal)}
        show={showAddStaffModal}
        title={"Add Staff"}
        validate={values => validate(values)}
        submitAction={onFormSubmission}
        resetAction={resetForm}
        className={'--overflow-visible'}
      >
        <Field
          name="firstName"
          type="text"
          component={FormInput}
          label="First Name"
          initialValue=""
        />
        <Field
          name="lastName"
          type="text"
          component={FormInput}
          label="Last Name"
          initialValue=""
        />
        <Field name="email" type="text" component={FormInput} label="Email" duplicatedemail={duplicatedEmailError}  initialValue="" />

        <Field
          options={accessOptions}
          name="accessLevel"
          component={FormDropdown}
          label="Access Level"
          initialValue={{label: accessOptions[0].label, value: accessOptions[0].value}}
        />
      </FormModal>

      <InfoModal
        title="Delete Error"
        message={"Deleting your own account is not allowed."}
        show={showDeleteInfoModal}
        modalCloseAction={() => setShowDeleteInfoModal(false)}
      />
    </div>
  );
};

export default memo(Staff);
