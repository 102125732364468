import {
  DISCHARGE_STEPS_RECEIVED,
  DISCHARGE_PROGRESS_RECEIVED,
  DISCHARGE_STEPS_FAILURE,
  DISCHARGE_PROGRESS_FAILURE
} from "actions/discharge";

export const dischargeInitialState = {
  dischargeSteps: [],
  error: null
};

const mapStepToProgress = (step, progress) => {
  const found = progress.find(({ chapterId }) => chapterId === step.ID);
  if (!found) {
    return {
      ...step,
      completion: 0,
      topics: step.topics.map(topic => ({ ...topic, completed: false }))
    };
  }

  const completedTopics = [];
  // steps.topics.include
  const stepCompletedTopics = step.topics.map(topic => {
    const completed = found.topics
      .map(({ topicId }) => topicId)
      .includes(topic.ID);
    if (completed) {
      completedTopics.push(topic.ID);
    }
    return {
      ...topic,
      completed
    };
  });

  const newStep = { ...step };
  newStep.topics = stepCompletedTopics;

  return {
    ...newStep,
    completion: (completedTopics.length / step.topics.length) * 100 || 0
  };
};

export function dischargeReducer(state, action) {
  switch (action.type) {
    case DISCHARGE_STEPS_RECEIVED: {
      return {
        ...state,
        dischargeSteps: [...action.data],
        error: null
      };
    }

    case DISCHARGE_PROGRESS_RECEIVED: {
      const updateDischarge =
        (state.dischargeSteps &&
          action.data &&
          state.dischargeSteps.map(step =>
            mapStepToProgress(step, action.data)
          )) ||
        state;

      return {
        ...state,
        dischargeSteps: [...updateDischarge],
        error: null
      };
    }

    case DISCHARGE_STEPS_FAILURE: {
      return {
        ...state,
        dischargeSteps: [],
        error: action.data
      };
    }

    case DISCHARGE_PROGRESS_FAILURE: {
      return {
        ...state,
        error: action.data
      };
    }

    default:
      return { ...state };
  }
}
