import {
  USER_AUTHENTICATED,
  USER_AUTHORIZED,
  USER_UNAUTHENTICATED,
  USER_UNAUTHORIZED
} from "actions/user";

export const userInitialState = {
  authenticated: undefined,
  authorized: undefined,
  token: "",
  user: undefined
};

export function userReducer(state, action) {
  switch (action.type) {
    case USER_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        token: action.token
      }

    case USER_UNAUTHENTICATED:
      localStorage.removeItem("iwk-admin-token");

      // when the user is unauthenticated set the user store back to initial values
      return userInitialState;

    case USER_AUTHORIZED:
      return {
        ...state,
        authorized: true,
        user: action.user
      }

    case USER_UNAUTHORIZED:
    return {
      ...state,
      authorized: false
    }

    default:
      return { ...state }
  }
}