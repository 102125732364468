import React, { memo, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./StaffRow.scss";

// components
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InfoModal from "components/InfoModal/InfoModal";
import Checkbox from "components/Checkbox/Checkbox";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import Dropdown from "components/Dropdown/Dropdown";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

// hooks and utils
import useForm from "hooks/useForm";
import validate from "../StaffFormValidationRules";
import { accessOptions as importedAccessOptions } from "utils/options";
import { getFormattedName } from "utils/validation";
import { fetchRequest } from "utils/fetch";
import { useStateValue } from "state";

// actions
import { ACCOUNT_DELETED } from "actions/accounts";
import { STAFF_INVITE_SENT, STAFF_DELETED, STAFF_UPDATED } from "actions/staff";
import { PROFILES_DELETED, PROFILE_UPDATED } from "actions/profiles";

const StaffRow = ({
  researchId,
  profileId,
  status,
  id,
  firstName,
  lastName,
  access,
  position,
  inviteSent,
  onboarded,
  email,
  checked,
  onCheckboxUpdate,
}) => {
  const { resetForm } = useForm(validate, editStaff);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteMessage, setInviteMessage] = useState("");
  const [accessOptions, setAccessOptions] = useState(importedAccessOptions);
  const [staff, setStaff] = useState({
    firstName,
    lastName,
    email,
    access,
  });
  const [{ accounts, user }, dispatch] = useStateValue();

  useEffect(() => {
    setStaff({
      firstName,
      lastName,
      email,
      access,
    });
  }, [email, firstName, lastName, access]);

  // Limit admins from creating super admins
  useEffect(() => {
    if (accounts.access === "admin") {
      setAccessOptions(accessOptions.filter((level) => {
        return level.value !== "super-admin";
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts.access]);

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `accountSettingsForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  function deleteStaff(accountId) {
    fetchRequest(user.token, "DELETE", `accounts/${accountId}`, undefined)
      .then((result) => {
        dispatch({
          type: ACCOUNT_DELETED,
          accountId,
        });

        dispatch({
          type: STAFF_DELETED,
          accountId,
        });

        dispatch({
          type: PROFILES_DELETED,
          accountId,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function sendInvite() {
    const inviteBody = {
      email,
      type: "admin",
    };

    fetchRequest(user.token, "POST", "invite", inviteBody)
      .then((result) => {
        // if there is no message invite was sent successfully
        if (!result.message) {
          setInviteMessage(`An Invite has been sent to ID ${id}.`);
          dispatch({
            type: STAFF_INVITE_SENT,
            id,
          });
        } else {
          setInviteMessage(
            "There was an error sending out the invite, try resending."
          );
        }

        setShowInviteModal(true);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function editStaff(values) {
    if (!values.email) {
      return;
    }

    const accountBody = {
      email: values.email,
      role: "admin",
      access: values.accessLevel.value,
      status: status.toLowerCase(),
    };

    const profileBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    let account;

    fetchRequest(user.token, "PATCH", `accounts/${id}`, accountBody)
      .then((response) => {
        account = response;
        return fetchRequest(
          user.token,
          "PATCH",
          `profiles/${profileId}`,
          profileBody
        );
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      })
      .then((profile) => {
        dispatch({
          type: PROFILE_UPDATED,
          profile,
        });

        dispatch({
          type: STAFF_UPDATED,
          staff: account,
        });

        setShowEditModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  const renderInviteButton = () => {
    if (onboarded) {
      return (
        <Button
          disabled
          variant="secondary"
          className="iwk-staff-row__cell-invite"
        >
          Onboarded
        </Button>
      );
    } else if (inviteSent && !onboarded) {
      return (
        <Button
          variant="secondary"
          className="iwk-staff-row__cell-invite"
          onClick={() => sendInvite(id)}
        >
          Resend Invite
        </Button>
      );
    } else {
      return (
        <Button
          variant="primary"
          className="iwk-staff-row__cell-invite"
          onClick={() => sendInvite(id)}
        >
          Send Invite
        </Button>
      );
    }
  };

  return (
    <div className="iwk-staff-row">
      <div className="iwk-staff-row__cell -small">
        {((accounts.access === "admin" && access === "Staff") ||
          accounts.access === "super-admin") && (
          <Checkbox
            checked={checked}
            onCheckboxCheckedCallback={(checked) =>
              onCheckboxUpdate(id, checked)
            }
          />
        )}
      </div>
      <div className="iwk-staff-row__cell">
        <span>{status}</span>
      </div>
      <div className="iwk-staff-row__cell">
        <span>{getFormattedName(firstName, lastName)}</span>
      </div>
      <div className="iwk-staff-row__cell">
        <span>{access}</span>
      </div>
      <div className="iwk-staff-row__cell">
        <span>{position}</span>
      </div>

      <div className="iwk-staff-row__cell">{renderInviteButton()}</div>

      <div className="iwk-staff-row__cell -icons -small">
        {((user.user &&
          user.user.accountId !== id &&
          accounts.access === "super-admin") ||
          (access === "Staff" && accounts.access === "admin")) && (
          <button
            className="iwk-staff-row__cell-button"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
          >
            <DeleteIcon className="iwk-staff-row__cell-icon" />
          </button>
        )}
        {user.user && user.user.accountId === id && (
          <div className="iwk-staff-row__cell-empty"></div>
        )}
        {((access === "Staff" && accounts.access === "admin") ||
          (user.user && user.user.accountId === id) ||
          accounts.access === "super-admin") && (
          <button
            className="iwk-staff-row__cell-button -edit"
            onClick={() => setShowEditModal(!showEditModal)}
          >
            <EditIcon className="iwk-staff-row__cell-icon" />
          </button>
        )}
      </div>

      <ConfirmationModal
        message={`Did you want to delete ID ${id}? You will not be able to access their data again.`}
        modalCloseAction={() => setShowDeleteModal(!showDeleteModal)}
        confirmAction={() => deleteStaff(id)}
        confirmActionLabel={"Delete"}
        cancelActionLabel={"Cancel"}
        cancelAction={() => setShowDeleteModal(!showDeleteModal)}
        show={showDeleteModal}
        title={"Are you sure?"}
      />

      <FormModal
        modalCloseAction={() => setShowEditModal(!showEditModal)}
        show={showEditModal}
        title={"Edit Staff"}
        validate={(values) => validate(values)}
        submitAction={async (newValues) => {
          editStaff(newValues);
        }}
        resetAction={resetForm}
      >
        <Field
          name="firstName"
          type="text"
          initialValue={staff.firstName}
          component={FormInput}
          label="First Name"
        />
        <Field
          name="lastName"
          type="text"
          initialValue={staff.lastName}
          component={FormInput}
          label="Last Name"
        />
        <Field
          name="email"
          type="text"
          initialValue={staff.email}
          component={FormInput}
          label="Email"
        />
        <Field
          name="accessLevel"
          component={FormDropdown}
          type="select"
          customOption={true}
          value={{
            label: staff.access,
            value: staff.access,
          }}
          initialValue={{
            label: staff.access,
            value: staff.access,
          }}
          selectedValue={{
            label: staff.access,
            value: staff.access,
          }}
          options={accessOptions}
          label="Access Level"
          controlId="editStaffForm.accessLevel"
        ></Field>
      </FormModal>
      <InfoModal
        title="Invite Sent"
        message={inviteMessage}
        show={showInviteModal}
        modalCloseAction={() => setShowInviteModal(!showInviteModal)}
      />
    </div>
  );
};

export default memo(StaffRow);
