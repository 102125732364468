import { isEmpty, invalidEmail, errorMessages, passwordValid, passwordsMatch } from "utils/validation";

export default function validate({ firstName, lastName, email, password, passwordConfirmation, position }, readOnly) {
  let errors = {};

  if (isEmpty(firstName)) {
    errors.firstName = errorMessages.emptyValue
  }

  if (isEmpty(lastName)) {
    errors.lastName = errorMessages.emptyValue;
  }

  if (isEmpty(position)) {
    errors.position = errorMessages.emptyValue;
  }

  if((isEmpty(password) && (isEmpty(passwordConfirmation))) && (!readOnly)) {
    errors.password = errorMessages.emptyValue;
    errors.passwordConfirmation = errorMessages.emptyValue;
  } 
  
  if(password && passwordConfirmation && !readOnly) {
    // ensure password meets requirements
    const isPasswordValid = passwordValid(password);
    if (isPasswordValid !== true) {
      errors.password = isPasswordValid;
    }

    // ensure confirmation password matches
    const doPasswordsMatch = passwordsMatch(password, passwordConfirmation);
    if (doPasswordsMatch !== true) {
      errors.passwordConfirmation = doPasswordsMatch;
    }
  }

  if (isEmpty(email)) {
    errors.email = errorMessages.emptyValue;
  } else if (invalidEmail(email)) {
    errors.email = errorMessages.invalidEmail;
  }
  return errors;
};