import React, { memo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./Header.scss";

// components
import Dropdown from "components/Dropdown/Dropdown";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { ReactComponent as LinkExternalIcon } from "assets/icons/link-external.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

// redux store
import { useStateValue } from "state";

// hooks and utils
import useForm from "hooks/useForm";
import { positionOptions } from "utils/options";
import { fetchRequest } from "utils/fetch";
import validate from "./AccountSettingsValidationRules";
import config from "../../config";

// actions
import { USER_UNAUTHENTICATED } from "actions/user";
import { ACCOUNT_UPDATED } from "actions/accounts";
import { STAFF_UPDATED } from "actions/staff";
import { PROFILE_UPDATED } from "actions/profiles";

const Header = ({ modalVisible = false, passwordReadOnly = true }) => {
  const [{ user, accounts, profiles }, dispatch] = useStateValue();
  const [accountUser, setAccountUser] = useState(undefined);
  const [showSettingsModal, setShowSettingsModal] = useState(modalVisible);
  const { resetForm } = useForm(validate, updateAccountSettings);
  const [readOnly, setReadOnly] = useState(passwordReadOnly);

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `accountSettingsForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  const showForm = (event) => {
    setShowSettingsModal(!showSettingsModal);
    setReadOnly(true);
  };

  const passwordChange = (event) => {
    event.preventDefault();
    setReadOnly(!readOnly);
  };

  function updateAccountSettings(values) {
    if (!values.email) {
      return;
    }

    const accountId = user.user.accountId;
    const profile = profiles.profiles.find(
      (profile) => profile.accountId === accountId
    );

    let accountBody;

    // readOnly siginifies the user wanted to change their password, if this is true, pass the password && confirmation, else do not pass them through
    if (readOnly) {
      accountBody = {
        email: values.email,
        position: values.position.value,
      };
    } else {
      accountBody = {
        email: values.email,
        position: values.position.value,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      };
    }

    const profileBody = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    fetchRequest(user.token, "PATCH", `accounts/${accountId}`, accountBody)
      .then((account) => {
        dispatch({
          type: ACCOUNT_UPDATED,
          account,
        });
        dispatch({
          type: STAFF_UPDATED,
          staff: account,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });

    fetchRequest(user.token, "PATCH", `profiles/${profile.id}`, profileBody)
      .then((profile) => {
        dispatch({
          type: PROFILE_UPDATED,
          profile,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });

    setShowSettingsModal(false);
    resetForm();
  }

  function logout() {
    dispatch({ type: USER_UNAUTHENTICATED });
  }

  useEffect(() => {
    if (!accounts.accounts || !profiles.profiles || !user.user) {
      return;
    }

    const accountId = user.user.accountId;
    const account = accounts.accounts.find(
      (account) => account.id === accountId
    );
    const profile = profiles.profiles.find(
      (profile) => profile.accountId === accountId
    );

    const accountUser = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: account.email,
      position: account.position,
    };

    setAccountUser(accountUser);
  }, [accounts.accounts, profiles, user.user]);

  return (
    <div className="iwk-header">
      <div className="iwk-header__logo">
        <NavLink
          activeClassName="-active"
          className="iwk-header__logo-link"
          exact
          to="/"
        >
          <LogoIcon className="iwk-header__logo-icon" />
        </NavLink>
      </div>

      <div className="iwk-header__actions">
        <div className="iwk-header__actions-action">
          <LinkExternalIcon className="iwk-header__actions-icon" />
          <button
            className="iwk-header__actions-button -frontend"
            onClick={() => window.open(config.clientURL, "_blank")}
          >
            Chez NICU Home Site
          </button>
        </div>

        <div className="iwk-header__actions-action">
          <SettingsIcon className="iwk-header__actions-icon" />
          <button
            className="iwk-header__actions-button -account"
            onClick={(event) => showForm(event)}
          >
            Account Settings
          </button>
        </div>

        <div className="iwk-header__actions-action">
          <LogoutIcon className="iwk-header__actions-icon" />
          <button
            className="iwk-header__actions-button -logout"
            onClick={logout}
          >
            Log Out
          </button>
        </div>
      </div>

      {/* Uses React Final Form */}
      {/* https://github.com/final-form/react-final-form */}
      {showSettingsModal && (
        <FormModal
          modalCloseAction={() => setShowSettingsModal(!showSettingsModal)}
          show={showSettingsModal}
          title={"Account Settings"}
          // pass in readOnly to only validate password fields if necessary
          validate={(values) => validate(values, readOnly)}
          submitAction={async (newValues) => {
            updateAccountSettings(newValues);
          }}
          resetAction={resetForm}
          className={readOnly ? "--overflow-visible" : "--overflow-scroll"}
        >
          <Field
            name="firstName"
            type="text"
            initialValue={accountUser.firstName}
            component={FormInput}
            label="First Name"
          />
          <Field
            name="lastName"
            type="text"
            initialValue={accountUser.lastName}
            component={FormInput}
            label="Last Name"
          />
          <Field
            name="email"
            type="text"
            initialValue={accountUser.email}
            component={FormInput}
            label="Email"
          />
          <button
            className="iwk-header__actions-change"
            onClick={passwordChange}
          >
            {readOnly ? "Change" : "Cancel"}
          </button>
          {readOnly && (
            <Field
              component={FormInput}
              name="dummy"
              type="password"
              label="Password"
              disabled={readOnly}
              initialValue={"Password!"}
            />
          )}
          {!readOnly && (
            <div>
              <Field
                name={readOnly ? "dummyPassword" : "password"}
                component={FormInput}
                label="Password"
                type="password"
              />
              <Field
                name={
                  readOnly
                    ? "dummyPasswordConfirmation"
                    : "passwordConfirmation"
                }
                type="password"
                component={FormInput}
                label="Password Confirmation"
              />
            </div>
          )}
          <Field
            name="position"
            component={FormDropdown}
            type="select"
            customOption={true}
            value={{ label: accountUser.position, value: accountUser.position }}
            initialValue={{
              label: accountUser.position,
              value: accountUser.position,
            }}
            selectedValue={{
              label: accountUser.position,
              value: accountUser.position,
            }}
            options={positionOptions}
            label="Position"
          ></Field>
        </FormModal>
      )}
    </div>
  );
};

export default memo(Header);