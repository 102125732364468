import {
  BABIES_RECEIVED,
  BABY_CREATED,
  BABY_UPDATED,
  BABY_DELETED,
} from "actions/babies"

export const babiesInitialState = {
  babies: undefined
};

export function babiesReducer(state, action) {
  switch (action.type) {
    case BABIES_RECEIVED:
      return { ...state, babies: action.babies }

    case BABY_CREATED:
      return { ...state, babies: [...state.babies, action.baby] }

    case BABY_UPDATED:
      const updatedBabies = state.babies.map((baby)=> {
        return baby.id === action.baby.id ? action.baby : baby;
      });

      return { ...state, babies: updatedBabies };

    case BABY_DELETED:
      const babies = state.babies;
      const babyIndex = state.babies.findIndex((baby) => baby.id === action.babyId);
      babies.splice(babyIndex, 1);

      return { ...state, babies };

    default:
      return { ...state }
  }
}