import {
  STAFF_RECEIVED,
  STAFF_INVITE_SENT,
  STAFF_CREATED,
  STAFF_UPDATED,
  STAFF_DELETED
} from "actions/staff";

export const staffInitialState = {
  staff: undefined
};

export function staffReducer(state, action) {
  switch (action.type) {
    case STAFF_RECEIVED: {
      return { ...state, staff: action.staff }
    }

    case STAFF_INVITE_SENT: {
      const staff = state.staff;
      const staffMember = staff.find((staff) => staff.id === action.id);
      staffMember.inviteSent = true;

      return { ...state, staff }
    }

    case STAFF_CREATED: {
      return { ...state, staff: [...state.staff, action.staff] }
    }

    case STAFF_DELETED: {
      const staff = state.staff;
      const staffIndex = state.staff.findIndex((staff) => staff.id === action.accountId);
      staff.splice(staffIndex, 1);

      return { ...state, staff }
    }

    case STAFF_UPDATED:
      const staff = state.staff.map((staffAccount) => {
        return staffAccount.id === action.staff.id ? action.staff : staffAccount;
      });

      return { ...state, staff }

    default:
      return { ...state }
  }
}