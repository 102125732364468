import React, { memo } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

import Staff from "./Staff/Staff";
import Families from "./Families/Families";
import Requests from "./Requests/Requests";

import "./StaffFamiliesDashboard.scss";

import { useStateValue } from "state";

const StaffFamiliesDashboard = () => {
  const [{ accounts }] = useStateValue();

  return (
    <div className="iwk-staff-families-dashboard">
      <div className="iwk-staff-families-dashboard__header">
        {accounts.access !== "staff" && (
          <NavLink
            activeClassName="-active"
            className="iwk-staff-families-dashboard__header-tab"
            exact
            to="/staff"
          >
            Staff
          </NavLink>
        )}

        <NavLink
          activeClassName="-active"
          className="iwk-staff-families-dashboard__header-tab"
          exact
          to="/families"
        >
          Families
        </NavLink>

        <NavLink
          activeClassName="-active"
          className="iwk-staff-families-dashboard__header-tab"
          exact
          to="/requests"
        >
          Requests
        </NavLink>
      </div>

      <Switch>
        {accounts.access !== "staff" && (
          <Route path="/staff" exact component={Staff} />
        )}

        <Route path="/families" exact component={Families} />
        <Route path="/requests" exact component={Requests} />

        <Redirect
          to={{
            pathname: `${accounts.access === "staff" ? "/families" : "/staff"}`
          }}
        />
      </Switch>
    </div>
  );
};

export default memo(StaffFamiliesDashboard);
