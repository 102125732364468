import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormStyled from "react-bootstrap/Form";
import { generate } from "generate-password";
import { Field } from "react-final-form";

// scss
import "./Families.scss";

// components
import Checkbox from "components/Checkbox/Checkbox";
import Dropdown from "components/Dropdown/Dropdown";
import TableHeaderButtons from "components/TableHeaderButtons/TableHeaderButtons";
import FamiliesRow from "./FamiliesRow/FamiliesRow";
import Search from "components/Search/Search";
import FormModal, { createFormInput } from "components/FormModal/FormModal";

// hooks and utils
import { useStateValue } from "state";
import useForm from "hooks/useForm";
import validate from "./FamilyValidationRules";
import { fetchRequest } from "utils/fetch";
import { sortAccountByDateCreated } from "utils/utils";

// actions
import {
  ACCOUNT_CREATED,
  ACCOUNT_DELETED,
  ACCOUNT_UPDATED,
} from "actions/accounts";
import {
  FAMILY_CREATED,
  FAMILY_DELETED,
  FAMILY_UPDATED,
} from "actions/families";
import { PROFILE_CREATED, PROFILES_DELETED } from "actions/profiles";

const Families = (props) => {
  const { resetForm } = useForm();
  const [errors] = useState({});
  const [
    { families, profiles, roles, user, babies },
    dispatch,
  ] = useStateValue();
  const [familiesArray, setFamiliesArray] = useState(undefined);
  const [filteredFamiliesArray, setFilteredFamiliesArray] = useState([]);
  const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);

  const [checkedIds, setCheckedIds] = useState([]);
  const [tableHeaderUsers, setTableHeaderUsers] = useState([]);
  const latestCheckedIds = useRef(checkedIds);
  const [duplicatedEmailError, setDuplicatedEmailError] = useState({
    exists: false,
  });

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `addFamilyForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  // keep checkedIds, latestCheckedIds and table header users in sync with filtered family array
  useEffect(() => {
    if (filteredFamiliesArray.length > 0) {
      latestCheckedIds.current = Array.from(
        filteredFamiliesArray.filter(({ account }) => account.checked),
        ({ account }) => account.id
      );
      const newTableHeaderUsers = filteredFamiliesArray.map(({ account }) => ({
        status: account.status,
        checked: account.checked,
      }));
      setCheckedIds(latestCheckedIds.current);
      setTableHeaderUsers(newTableHeaderUsers);
    }
  }, [filteredFamiliesArray]);

  const filterFamilies = useMemo(
    () => (
      <React.Fragment>
        <span className="iwk-staff__actions-value">
          {
            filteredFamiliesArray.filter(
              (member) =>
                member.account.onboarded &&
                member.account.status !== "discharged"
            ).length
          }{" "}
          Onboarded
        </span>

        <span className="iwk-staff__actions-separator">|</span>

        <span className="iwk-staff__actions-value">
          {
            filteredFamiliesArray.filter(
              (member) =>
                !member.account.onboarded &&
                member.account.inviteSent &&
                member.account.status !== "discharged"
            ).length
          }{" "}
          Invited
        </span>

        <span className="iwk-staff__actions-separator">|</span>

        <span className="iwk-staff__actions-value">
          {
            filteredFamiliesArray.filter(
              (member) => member.account.status === "discharged"
            ).length
          }{" "}
          Discharged
        </span>

        <span className="iwk-staff__actions-separator">|</span>

        <span className="iwk-staff__actions-value">
          {filteredFamiliesArray.length} Total
        </span>
      </React.Fragment>
    ),
    [filteredFamiliesArray]
  );

  function updateCheckedIds(id, checked) {
    if (checked) {
      latestCheckedIds.current.push(id);
    } else {
      const checkedIndex = checkedIds.findIndex(
        (checkedId) => checkedId === id
      );
      latestCheckedIds.current.splice(checkedIndex, 1);
    }

    const tableHeaderUsers = filteredFamiliesArray.map(
      ({ account, primaryProfile }) => {
        return {
          id: account.id,
          status: account.status,
          checked: account.id === id ? checked : account.checked,
        };
      }
    );

    const familyArray = filteredFamiliesArray.map(
      ({ account, primaryProfile }) => {
        if (account.id === id) {
          account.checked = checked;
        }

        return {
          account,
          primaryProfile,
        };
      }
    );

    setFilteredFamiliesArray(familyArray);
    setTableHeaderUsers(tableHeaderUsers);
    setCheckedIds(latestCheckedIds.current);
  }

  function onBulkCheckboxClick(checked) {
    let ids = [];
    const tableHeaderUsers = filteredFamiliesArray.map(
      ({ account, profile }) => {
        account.checked = checked;

        if (checked) {
          ids.push(account.id);
        }

        return {
          id: account.id,
          status: account.status,
          checked,
        };
      }
    );

    const familyAccounts = filteredFamiliesArray.map(
      ({ account, primaryProfile }) => {
        account.checked = checked;

        return {
          account,
          primaryProfile,
        };
      }
    );

    setFilteredFamiliesArray(familyAccounts);
    updateCheckedIds(ids, checked);
    setTableHeaderUsers(tableHeaderUsers);
  }

  const validation = (values) => {
    const form = validate(values);

    return {
      ...form,
      ...errors,
    };
  };

  function addFamily(values) {
    if (!values.email) {
      return;
    }

    const password = generate({
      length: 10,
      numbers: true,
    });

    const accountBody = {
      email: values.email,
      role: "regular",
      access: "none",
      password,
      passwordConfirmation: password,
      status: "active",
    };

    const profileBody = {
      accountHolder: true,
      status: "active",
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: roles.roles.find((role) => role.id === values.role.value).id,
    };

    let account;

    fetchRequest(user.token, "POST", "accounts", accountBody)
      .then((response) => {
        if (response.message) {
          setDuplicatedEmailError({
            exists: true,
            message: "An account with that email address is already in use",
          });

          throw new Error();
        }

        account = response;
        return fetchRequest(
          user.token,
          "POST",
          `accounts/${account.id}/profiles`,
          profileBody
        );
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
        throw new Error();
      })
      .then((profile) => {
        dispatch({
          type: PROFILE_CREATED,
          profile,
        });

        dispatch({
          type: FAMILY_CREATED,
          family: account,
        });

        dispatch({
          type: ACCOUNT_CREATED,
          account,
        });

        resetForm();
        setShowAddFamilyModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function handleSearchInput(input) {
    const families = familiesArray.filter(({ account, primaryProfile }) => {
      if (input === "") {
        return true;
      }

      let babyRooms = false;

      if (babies.babies) {
        babies.babies.forEach((baby) => {
          let babyRoom = baby.room;
          if (
            babyRoom &&
            babyRoom.toLowerCase().includes(input) &&
            baby.accountId === account.id
          ) {
            babyRooms = true;
          }
        });
      }

      const id = account.id;
      const email = account.email;
      const lastName = primaryProfile.lastName;

      return (
        id.toLowerCase().includes(input) ||
        email.toLowerCase().includes(input) ||
        lastName.toLowerCase().includes(input) ||
        babyRooms
      );
    });

    setFilteredFamiliesArray(families);
  }

  function bulkDeleteFamilies(ids) {
    for (let id of ids) {
      fetchRequest(user.token, "DELETE", `accounts/${id}`, undefined)
        .then((result) => {
          dispatch({
            type: ACCOUNT_DELETED,
            accountId: id,
          });

          dispatch({
            type: FAMILY_DELETED,
            accountId: id,
          });

          dispatch({
            type: PROFILES_DELETED,
            accountId: id,
          });
        })
        .catch((error) => {
          // TODO app error handling
          console.log(error);
        });
    }
  }

  function updateFamiliesStatus(ids, action) {
    for (let accountId of ids) {
      let { account } = familiesArray.find(
        ({ account }) => account.id === accountId
      );

      const tableUsers = tableHeaderUsers.map(({ id, status, checked }) => {
        if (id === accountId) {
          status = action;
        }

        return {
          status,
          checked,
          id,
        };
      });

      if (account.status !== action) {
        account.status = action;
        account.checked = false;

        fetchRequest(
          user.token,
          "PATCH",
          account.status === "discharged"
            ? `accounts/${account.id}/discharge`
            : `accounts/${account.id}`,
          account
        )
          .then((account) => {
            dispatch({
              type: ACCOUNT_UPDATED,
              account,
            });

            dispatch({
              type: FAMILY_UPDATED,
              staff: account,
            });
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });

        setTableHeaderUsers(tableUsers);
      } else {
        account.checked = false;
      }
    }

    updateCheckedIds(ids, false);
  }

  const sortByStatus = useMemo(() => {
    if (families.families) {
      const activeFamilies = families.families.filter(
        (family) => family.status === "active"
      );
      activeFamilies.sort((a, b) => sortAccountByDateCreated(a, b));

      const dischargedFamilies = families.families.filter(
        (family) => family.status === "discharged"
      );
      dischargedFamilies.sort((a, b) => sortAccountByDateCreated(a, b));

      const inactiveFamilies = families.families.filter(
        (family) => family.status === "inactive"
      );
      inactiveFamilies.sort((a, b) => sortAccountByDateCreated(a, b));

      return activeFamilies.concat(dischargedFamilies, inactiveFamilies);
    }
  }, [families]);

  useEffect(() => {
    if (!families.families || !profiles.profiles || !roles.roles) {
      return;
    }

    const familiesArray = sortByStatus.map((account) => {
      let familyProfiles = profiles.profiles.filter(
        (profile) => profile.accountId === account.id
      );
      familyProfiles = familyProfiles.sort(
        (a, b) => Date.parse(a.createdAt) > Date.parse(b.createdAt)
      );

      /**
       * if the familyProfiles array exists, then find either the profile with
       * account holder, or the first created
       * (they've already been sorted at this point)
       */
      const primaryProfile =
        familyProfiles.find((profile) => profile.accountHolder) ||
        familyProfiles[0];

      if (primaryProfile) {
        primaryProfile.role = roles.roles.find(
          (role) => role.id === primaryProfile.roleId
        );
      }

      return {
        account,
        primaryProfile,
      };
    });

    setFamiliesArray(familiesArray);
    setFilteredFamiliesArray(familiesArray);

    const tableHeaderUsers = familiesArray.map(({ account, profile }) => {
      return {
        id: account.id,
        status: account.status,
        checked: account.checked || false,
      };
    });

    setTableHeaderUsers(tableHeaderUsers);
  }, [families.families, profiles.profiles, roles.roles, sortByStatus]);

  // TODO loading table component
  return (
    <div className="iwk-families">
      <div className="iwk-families__search">
        <Search placeholder="Search table" handleSearch={handleSearchInput} />
      </div>

      <div className="iwk-families__actions">
        <TableHeaderButtons
          checkedIds={latestCheckedIds.current}
          users={tableHeaderUsers}
          onBulkDeleteClick={bulkDeleteFamilies}
          onUpdateStatusClick={updateFamiliesStatus}
          showDischargeButtons={true}
        />
        <div>
          {filteredFamiliesArray && filterFamilies}

          <Button
            variant="primary"
            className="iwk-families__actions-add"
            onClick={() => setShowAddFamilyModal(!showAddFamilyModal)}
          >
            Add Family
          </Button>
        </div>
      </div>

      <div className="iwk-families__table">
        <div className="iwk-families__table-header">
          <div className="iwk-families__table-header-cell -small">
            <Checkbox
              checked={
                checkedIds.length === filteredFamiliesArray.length &&
                checkedIds.length
              }
              full={
                checkedIds.length > 0 &&
                checkedIds.length !== filteredFamiliesArray.length
              }
              onCheckboxCheckedCallback={(checked) =>
                onBulkCheckboxClick(checked)
              }
            />
          </div>
          <div className="iwk-families__table-header-cell -extend">Status</div>
          <div className="iwk-families__table-header-cell">Primary Role</div>
          <div className="iwk-families__table-header-cell">Family Name</div>
          <div className="iwk-families__table-header-cell">Room #</div>
          <div className="iwk-families__table-header-cell -large">Invites</div>
          <div className="iwk-families__table-header-cell">Joined On</div>
          <div className="iwk-families__table-header-cell">Actions</div>
        </div>
        {filteredFamiliesArray &&
          filteredFamiliesArray.map((family, index) => {
            let currentBabies = null;
            if (babies.babies) {
              currentBabies = babies.babies.filter(
                (baby) => baby.accountId === family.account.id
              );
            }

            return (
              <FamiliesRow
                status={family.account.status}
                id={family.account.id}
                primaryRole={
                  family.primaryProfile.role ? family.primaryProfile.role : null
                }
                familyName={
                  family.primaryProfile.lastName
                    ? family.primaryProfile.lastName
                    : null
                }
                incomingBabyRooms={
                  currentBabies && family.account.status !== "discharged"
                    ? currentBabies.map((baby) => baby.room)
                    : null
                }
                defaultRoom={
                  family.primaryProfile.defaultRoom
                    ? family.primaryProfile.defaultRoom
                    : null
                }
                inviteSent={family.account.inviteSent}
                createdAt={family.account.createdAt}
                onboarded={family.account.onboarded}
                checked={family.account.checked}
                email={family.account.email}
                onCheckboxUpdate={(id, checked) =>
                  updateCheckedIds(id, checked)
                }
                key={`families-${index}`}
              />
            );
          })}
      </div>

      <FormModal
        modalCloseAction={() => setShowAddFamilyModal(!showAddFamilyModal)}
        show={showAddFamilyModal}
        title={"Add Family"}
        validate={(values) => validation(values)}
        submitAction={async (newValues) => {
          addFamily(newValues);
        }}
        resetAction={resetForm}
      >
        <Field
          name="firstName"
          type="text"
          component={FormInput}
          label="First Name"
          initialValue=""
        />
        <Field
          name="lastName"
          type="text"
          component={FormInput}
          label="Last Name"
          initialValue=""
        />
        <Field
          name="email"
          type="text"
          component={FormInput}
          label="Email"
          duplicatedemail={duplicatedEmailError}
          initialValue=""
        />
        <Field
          name="role"
          component={FormDropdown}
          type="select"
          options={roles.roleOptions}
          label="Role"
          initialValue={null}
        ></Field>
      </FormModal>
    </div>
  );
};

export default memo(Families);
