import React from "react";
import Logo from "../Logo/Logo";

import "./Error.scss";

const Error = () => {
  return (
    <div className="iwk-error">
      <Logo />
      <div className="iwk-error__info">
        <div className="iwk-error__info-header">
          Something went wrong on our end
        </div>
        <div className="iwk-error__info-message">
          If refreshing the page doesn't help, please try again later
        </div>
      </div>
    </div>
  );
};

export default Error;
