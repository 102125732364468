import React from 'react';

import './Loading.scss';

const Loading = () => {
  return (
    <div className="iwk-loading">
      <div className="iwk-loading__loader"></div>
    </div>
  )
}

export default Loading;