import { useState, useEffect, useRef } from "react";
import { decode } from "jsonwebtoken";

import config from "config";
import hasExpired from "utils/verifyToken";
import { getHeaders } from "utils/getHeaders";
import { useStateValue } from "state";
import {
  USER_UNAUTHENTICATED,
  USER_UNAUTHORIZED,
  USER_AUTHORIZED,
  USER_AUTHENTICATED
} from "actions/user";
import {
  CONNECTION_ERROR,
  CONNECTION_AVAILABLE
} from "actions/serverConnection";
import { fetchRequest } from "utils/fetch";

const useLogin = (token, callback) => {
  const callbackRef = useRef(callback);
  const [, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLogin = async token => {
      const { accountId } = decode(token);
      const res = await fetch(
        `${config.api.baseURL}/accounts/${accountId}/profiles`,
        getHeaders(token)
      )
        .then(res => {
          // if we've received a response from the server, reset serverConnection state to true
          dispatch({
            type: CONNECTION_AVAILABLE
          });
          if (res.ok || res.status < 400) {
            return res;
          }
          if (res.status === 401) {
            // when environment is restarted the server passport token expires, which results in unauthenticated requests
            // In this case, unauthenticate and start over
            setIsLoading(false);
            callbackRef.current(false);

            dispatch({
              type: USER_UNAUTHENTICATED
            });
          } else {
            setIsLoading(false);
            callbackRef.current(false);
          }
        })
        .catch(error => {
          // set connection error when fail to fetch
          dispatch({
            type: CONNECTION_ERROR,
            payload: error
          });
        });

      if (res) {
        let profiles = await res.json();
        // primary profile
        return profiles.find(profile => profile.accountHolder);
      }
      return;
    };

    if (!token) {
      setIsLoading(false);
      callbackRef.current(false);

      dispatch({
        type: USER_UNAUTHENTICATED
      });

      return;
    }

    if (!hasExpired(token)) {
      dispatch({
        type: USER_AUTHENTICATED,
        token
      });
    }

    fetchLogin(token).then(primaryProfile => {
      if (!primaryProfile) {
        return;
      }
      fetchRequest(token, "GET", `/accounts/${primaryProfile.accountId}`)
        .then(account => {
          // an account with role of none means they are not a staff member and cannot access the admin portal
          if (account.access === "none") {
            dispatch({
              type: USER_UNAUTHORIZED
            });

            return;
          }

          if (primaryProfile) {
            dispatch({
              type: USER_AUTHORIZED,
              user: primaryProfile
            });

            localStorage.setItem("iwk-admin-token", token);
            setIsLoading(false);
            callbackRef.current(true);

            return;
          }

          // if there is no primary profile then user is unauthorized
          dispatch({
            type: USER_UNAUTHORIZED
          });

          setIsLoading(false);
          callbackRef.current(false);
        })
        .catch(error => {
          // if there was an error making the fetch request
          // such as invalid token then set as unauthorized
          dispatch({
            type: USER_UNAUTHORIZED
          });

          setIsLoading(false);
          callbackRef.current(false);
        });
    });
  }, [callbackRef, dispatch, token]);

  return { isLoading };
};

export default useLogin;
