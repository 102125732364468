import React from "react";
import "./Logo.scss";
import { ReactComponent as LoginLogo } from "assets/icons/login-logo.svg";

const Logo = (props) => {
  return (
    <div className="iwk-logo">
      <LoginLogo className="iwk-logo__icon"/>
      <p className="iwk-logo__text">Admin Portal</p>
    </div>
  );
};

export default Logo;