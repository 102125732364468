import React, { memo, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Checkbox from "components/Checkbox/Checkbox";
import useQueryParams from "../../hooks/useQueryParams";
import TermsOfService from "components/TermsOfService/TermsOfService";

// scss
import "./ChangePassword.scss";

// components
import Logo from "components/Logo/Logo";

// hooks and utils
import validate from "./ChangePasswordFormValidationRules";
import useForm from "hooks/useForm";
import { fetchRequest } from "utils/fetch";

const ChangePassword = ({ location, history }) => {
  const params = useQueryParams(location);
  const { values, errors, handleChange, handleSubmit } = useForm(
    validate,
    onChangePassword,
    {
      confirmTerms: params.resetToken
    }
  );
  const [inviteToken, setInviteToken] = useState(params.inviteToken);
  const [resetToken, setResetToken] = useState(params.resetToken);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [validation, setValidation] = useState({
    isInvalid: false,
    message: ""
  });
  const [termsOpen, setTermsOpen] = useState(false);

  useEffect(() => {
    setInviteToken(params.inviteToken);
    setResetToken(params.resetToken);
  }, [location.search, params]);

  // validate invite token
  useEffect(() => {
    if (inviteToken) {
      fetchRequest(undefined, "POST", "invite/verify", { token: inviteToken })
        .then(({ message }) => {
          // errors will have message
          if (!message) {
            setValidation({
              isInvalid: false,
              message: ""
            });
            return;
          }

          switch (message) {
            case "INVITE_EXPIRED":
              setValidation({
                isInvalid: true,
                message:
                  "Your invite has expired. Contact the administrator to receive a new one."
              });
              break;
            default:
              setValidation({
                isInvalid: true,
                message:
                  "Your invite was not found. Check that it is exactly the same as the one you received, and contact the administrator for further issues."
              });
          }
        })
        .catch(error => {
          // TODO global app error handling?
          console.log(error);
        });
    } else if (resetToken) {
      fetchRequest(undefined, "POST", "reset-verify", {
        resetPasswordToken: resetToken
      })
        .then(({ code }) => {
          switch (code) {
            case 200:
              setValidation({
                isInvalid: false,
                message: ""
              });
              return;
            case 401:
              setValidation({
                isInvalid: true,
                message:
                  "Your invite has expired. Contact the administrator to receive a new one."
              });
              break;
            default:
              setValidation({
                isInvalid: true,
                message:
                  "Your invite was not found. Check that it is exactly the same as the one you received, and contact the administrator for further issues."
              });
          }
        })
        .catch(error => {
          // TODO global app error handling?
          console.log(error);
        });
    }
  }, [inviteToken, resetToken]);

  function onChangePassword() {
    const endpoint = inviteToken ? "invite/redeem" : "reset-password";
    let inviteBody = {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      consent: true
    };

    if (inviteToken) {
      inviteBody.token = inviteToken;
      inviteBody.onboarded = true;
    } else {
      inviteBody.resetPasswordToken = resetToken;
    }

    fetchRequest(undefined, "POST", endpoint, inviteBody)
      .then(result => {
        setPasswordChanged(true);
      })
      .catch(error => {
        // TODO global app error handling?
        console.log(error);
      });
  }

  function goToLogin() {
    history.push("/login");
  }

  if (validation.isInvalid) {
    return (
      <div className="iwk-change-password">
        <Logo />
        <div className="iwk-change-password__info">
          <h1 className="iwk-change-password__info-header">Invalid Token</h1>
          <p className="iwk-change-password__info-message">
            {validation.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="iwk-change-password">
        <Logo />
        {!passwordChanged && (
          <div>
            <div className="iwk-change-password__info">
              <h1 className="iwk-change-password__info-header">
                {inviteToken ? "Set Password" : "Change Password"}
              </h1>
              <p className="iwk-change-password__info-message">
                Create a new password for your account.
              </p>
            </div>
            <Form
              className="iwk-change-password__form"
              onSubmit={handleSubmit}
              noValidate
            >
              <Form.Group
                className="iwk-change-password__form-group"
                controlId="loginForm.password"
              >
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  isInvalid={errors.password}
                  value={values.password || ""}
                  onChange={handleChange}
                />
                {errors.password && (
                  <Form.Control.Feedback
                    className="iwk-change-password__form-error"
                    type="invalid"
                  >
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group
                className="iwk-change-password__form-group"
                controlId="loginForm.passwordConfirmation"
              >
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="passwordConfirmation"
                  isInvalid={errors.passwordConfirmation}
                  value={values.passwordConfirmation || ""}
                  onChange={handleChange}
                />
                {errors.passwordConfirmation && (
                  <Form.Control.Feedback
                    className="iwk-change-password__form-error"
                    type="invalid"
                  >
                    {errors.passwordConfirmation}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <div className="iwk-change-password__form-note">
                <p>Password requirements</p>
                <ul>
                  <li>Minimum 8 characters</li>
                  <li>At least 1 uppercase and 1 special character</li>
                </ul>
              </div>

              {inviteToken && (
                <>
                  <Form.Group
                    className="iwk-change-password__form-group --checkbox"
                    controlId="loginForm.confirmTerms"
                  >
                    <Form.Label>
                      I have read and accept the{" "}
                      <button
                        className="iwk-change-password__form-inline-button"
                        type="button"
                        onClick={e => {
                          if (e) {
                            e.preventDefault();
                          }
                          setTermsOpen(!termsOpen);
                        }}
                      >
                        Terms of Service
                      </button>
                    </Form.Label>
                    <Form.Control
                      type="checkbox"
                      name="confirmTerms"
                      isInvalid={errors.confirmTerms}
                      value={values.confirmTerms || ""}
                    />
                    <Checkbox
                      checked={values.confirmTerms}
                      full={values.confirmTerms}
                      onCheckboxCheckedCallback={() => {
                        handleChange(
                          {
                            persist: () => {},
                            target: {
                              name: "confirmTerms",
                              value: !values.confirmTerms
                            }
                          },
                          "confirmTerms",
                          !values.confirmTerms
                        );
                      }}
                      error={errors.confirmTerms}
                    />
                  </Form.Group>
                  {errors.confirmTerms && (
                    <div className="iwk-change-password__form-error" isValid>
                      {errors.confirmTerms}
                    </div>
                  )}
                </>
              )}

              <div className="iwk-change-password__form-actions">
                <Button
                  className="iwk-change-password__form-actions-button"
                  variant="primary"
                  type="submit"
                >
                  Change Password
                </Button>
              </div>
            </Form>
          </div>
        )}

        {passwordChanged && (
          <div className="iwk-change-password__info">
            <h1 className="iwk-change-password__info-header">
              Password Changed!
            </h1>
            <p className="iwk-change-password__info-message">
              You have successfully changed your password!
              <br />
              You can now use it to log into your account
            </p>

            <Button
              className="iwk-change-password__changed-button"
              variant="primary"
              onClick={goToLogin}
            >
              Return to Log In
            </Button>
          </div>
        )}
      </div>
      <TermsOfService open={termsOpen} onClose={() => setTermsOpen(false)} />
    </>
  );
};

export default memo(ChangePassword);
