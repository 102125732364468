import React, { memo, forwardRef, useImperativeHandle, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import FormStyled from "react-bootstrap/Form";
import { Form } from "react-final-form";

import "./FormModal.scss";

export const createFormInput = (
  type,
  { input, meta, label, ...rest },
  givenControlId
) => {
  const FormElement = type;

  // basically a hack to get the message to leave once you start re-typing a new email in
  if(rest.duplicatedemail && rest.duplicatedemail.exists && meta.active) {
    // reset rest.duplicatedEmail options
    rest.duplicatedemail.exists = false;
    rest.duplicatedemail.message = '';
  }

  return (
    <FormStyled.Group controlId={givenControlId}>
      <FormStyled.Label>{label}</FormStyled.Label>
      <FormElement {...input} {...rest} />
      
      {input.name !== "password" &&
        input.name !== "passwordConfirmation" &&
        meta.touched &&
        meta.dirty && (
          <span className="form-error">{meta.error || meta.submitError}</span>
      )}
      
      {(input.name === "password" || input.name === "passwordConfirmation") &&
      ((meta.error || meta.submitError) && meta.touched && (
        <span className="form-error">{meta.error || meta.submitError}</span>
      ))}

      {rest.duplicatedemail && rest.duplicatedemail.exists && (
        <span className="form-error">{rest.duplicatedemail.message}</span>
      )}
    </FormStyled.Group>
  );
};

export const FormModal = forwardRef(
  (
    {
      children,
      modalCloseAction,
      show,
      submitAction,
      title,
      resetAction,
      validate,
      submitTitle = "Save",
      className
    },
    ref
  ) => {
    // when we close the form we want to also reset our form
    function closeModal() {
      resetAction();
      modalCloseAction();
    }
    // ref for form to use submit from parent
    const formRef = useRef();

    // provide submit method to parent if needed
    useImperativeHandle(ref, () => ({
      submitFromParent: () => {
        formRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    }));

    return (
      <Modal
        show={show}
        onHide={() => closeModal()}
        size="md"
        centered
        className="iwk-form-modal"
      >
        <Form
          onSubmit={submitAction}
          title={title}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} ref={formRef} className={className}>
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
              </div>

              <div>
                <Modal.Body className="form-group">{children}</Modal.Body>
              </div>

              <div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => closeModal()}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    {submitTitle}
                  </Button>
                </Modal.Footer>
              </div>
            </form>
          )}
        />
      </Modal>
    );
  }
);

export default memo(FormModal);
