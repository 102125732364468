import React, { memo, useEffect, useState } from "react";

import "./Checkbox.scss";
import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";

export const Checkbox = ({
  checked,
  full,
  onCheckboxCheckedCallback,
  error
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  function markCheckbox(isChecked) {
    setIsChecked(!isChecked);
    onCheckboxCheckedCallback(!isChecked);
  }

  useEffect(() => {
    setIsChecked(checked);
  }, [checked, full]);

  return (
    <div
      className={`iwk-checkbox__box ${isChecked ? "-checked" : ""} ${
        full ? "-full" : ""
      } ${error ? "-error" : ""}`}
      role="button"
      onClick={() => markCheckbox(isChecked)}
    >
      <CheckmarkIcon className="iwk-checkbox__box-checkmark" />
    </div>
  );
};

export default memo(Checkbox);
