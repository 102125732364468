import React, { memo, useEffect, useState } from "react";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./FamilyRow.scss";

// components
import Dropdown from "components/Dropdown/Dropdown";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import InfoModal from "components/InfoModal/InfoModal";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

import validate from "../ProfileValidationRules";
import useForm from "hooks/useForm";
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import { capitalize } from "utils/utils";
import { PROFILE_DELETED, PROFILE_UPDATED } from "actions/profiles";

export const FamilyRow = ({
  id,
  familyId,
  status,
  role,
  accountHolder,
  firstName,
  lastName,
  checked,
  onCheckboxUpdate,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { resetForm } = useForm(validate, editProfile);
  const [{ roles, user, accounts }, dispatch] = useStateValue();
  const [currentProfile, setCurrentProfile] = useState({
    firstName,
    lastName,
    role,
  });

  let currentAccount;
  if (accounts.accounts) {
    currentAccount = accounts.accounts.find(
      (account) => account.id === familyId
    );
  }

  useEffect(() => {
    const roleName = capitalize(role.name);

    setCurrentProfile({
      firstName,
      lastName,
      role: {
        ...role,
        name: roleName,
      },
    });
  }, [firstName, lastName, role]);

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `accountSettingsForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  function onShowDeleteModalClick() {
    if (accountHolder) {
      setShowInfoModal(!showInfoModal);
    } else {
      setShowDeleteModal(!showDeleteModal);
    }
  }

  function deleteProfile(profileId) {
    fetchRequest(user.token, "DELETE", `profiles/${profileId}`, undefined)
      .then((result) => {
        dispatch({
          type: PROFILE_DELETED,
          profileId,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function editProfile(values) {
    if (!values.firstName) {
      return;
    }

    const profileBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: values.role.value,
    };

    fetchRequest(
      user.token,
      "PATCH",
      `accounts/${familyId}/profiles/${id}`,
      profileBody
    )
      .then((profile) => {
        dispatch({
          type: PROFILE_UPDATED,
          profile,
        });

        setShowEditModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  return (
    <div className="iwk-family-row">
      {/* <div className="iwk-family-row__cell -small">
        <Checkbox
          checked={checked}
          onCheckboxCheckedCallback={checked => onCheckboxUpdate(id, checked)}
        />
      </div> */}
      {/* <div className="iwk-family-row__cell">
        <span>{capitalize(status)}</span>
      </div> */}
      {/* <div className="iwk-family-row__cell">
        <span>{id}</span>
      </div> */}
      <div className="iwk-family-row__cell">
        <span>{accountHolder ? "Primary" : ""}</span>
      </div>

      <div className="iwk-family-row__cell">{capitalize(role.name)}</div>

      <div className="iwk-family-row__cell -icons -small">
        {accounts.access === "admin" ||
          (accounts.access === "super-admin" && (
            <button
              onClick={onShowDeleteModalClick}
              className="iwk-family-row__cell-button"
            >
              <DeleteIcon className="iwk-family-row__cell-icon" />
            </button>
          ))}
        <button
          onClick={() => setShowEditModal(!showEditModal)}
          className="iwk-family-row__cell-button -edit"
        >
          <EditIcon className="iwk-family-row__cell-icon" />
        </button>
      </div>

      {accountHolder && (
        <InfoModal
          title="Delete Error"
          message={"Deleting a primary profile on an account is not allowed."}
          show={showInfoModal}
          modalCloseAction={() => setShowInfoModal(false)}
        />
      )}
      {!accountHolder && (
        <ConfirmationModal
          message={`Did you want to delete ID ${id}? You will not be able to access their data again.`}
          modalCloseAction={onShowDeleteModalClick}
          confirmAction={() => deleteProfile(id)}
          confirmActionLabel={"Delete"}
          cancelActionLabel={"Cancel"}
          cancelAction={onShowDeleteModalClick}
          show={showDeleteModal}
          title={"Are you sure?"}
        />
      )}

      <FormModal
        modalCloseAction={() => setShowEditModal(!showEditModal)}
        show={showEditModal}
        title={"Edit Profile"}
        validate={(values) => validate(values)}
        saveAction={() => editProfile(id)}
        submitAction={async (newValues) => {
          editProfile(newValues);
        }}
        resetAction={resetForm}
      >
        <Field
          name="firstName"
          type="text"
          initialValue={currentProfile.firstName}
          component={FormInput}
          label="First Name"
        />
        <Field
          name="lastName"
          type="text"
          initialValue={currentProfile.lastName}
          component={FormInput}
          label="Last Name"
        />
        <Field
          name="email"
          type="email"
          initialValue={currentAccount ? currentAccount.email : null}
          component={FormInput}
          label="Email"
          disabled
        />
        <Field
          name="role"
          component={FormDropdown}
          type="select"
          customOption={true}
          value={{
            label: currentProfile.role.name,
            value: currentProfile.role.id,
          }}
          initialValue={{
            label: currentProfile.role.name,
            value: currentProfile.role.id,
          }}
          selectedValue={{
            label: currentProfile.role.name,
            value: currentProfile.role.id,
          }}
          options={roles.roleOptions}
          label="Role"
          controlId="editProfileForm.role"
        ></Field>
      </FormModal>
    </div>
  );
};

export default memo(FamilyRow);
