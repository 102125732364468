import { useCallback, useEffect, useState } from 'react';
import { useStateValue } from 'state';
import { fetchRequest } from 'utils/fetch';
import { USER_UNAUTHENTICATED } from 'actions/user';

const SAVE_INTERVAL_MS = 1000; // 1 second
const STORAGE_KEY = 'IWK_AUTO_LOGOUT'; // Same as client site
const EVENTS_TO_MONITOR = [
  'click',
  'keypress',
  'load',
  'mousedown',
  'mousemove',
  'scroll'
];

const useAutoLogout = () => {
  const [{ user }, dispatch] = useStateValue();
  const [timeoutMS, setTimeoutMS] = useState(0);
  const [loading, setLoading] = useState(false);

  const getLastUpdate = () => parseInt(localStorage.getItem(STORAGE_KEY)) || 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setLastUpdate = useCallback(() => {
    // Only update local storage if at minimum seconds have passed
    // since we don't want to be saving every millisecond
    const timestamp = Date.now();
    const lastUpdate = getLastUpdate();

    if (timestamp - lastUpdate > SAVE_INTERVAL_MS) {
      localStorage.setItem(STORAGE_KEY, timestamp);
    }
  });

  // Fetch setting value from db
  useEffect(() => {
    if (!user.token || loading) {
      return;
    }

    setLoading(true);

    fetchRequest(user.token, 'GET', 'settings', undefined)
      .then((results) => {
        const value = results.find(
          (item) => item.name === 'timeout'
        ).value;

        const minutes = parseInt(value) || 0;

        setTimeoutMS(minutes * 60000);
      });
  }, [user, loading]);

  // Setup event listeners
  useEffect(() => {
    // If user is logged in, begin listening for activity
    if (user.token) {
      EVENTS_TO_MONITOR.forEach(event =>
        window.addEventListener(event, setLastUpdate)
      );
    }

    return () => {
      EVENTS_TO_MONITOR.forEach(event =>
        window.removeEventListener(event, setLastUpdate)
      );
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Check for user activity every second
  // If none in the last timeoutValue minutes, log them out
  useEffect(() => {
    if (user.token && timeoutMS) {
      const interval = setInterval(() => {
        const lastUpdate = getLastUpdate();

        if (lastUpdate !== 0 && Date.now() - lastUpdate > timeoutMS) {
          dispatch({ type: USER_UNAUTHENTICATED });
        }
      }, SAVE_INTERVAL_MS);

      return () => {
        clearInterval(interval);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, timeoutMS]);
};

export default useAutoLogout;
