import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, Link, NavLink } from "react-router-dom";

import Babies from "./Babies/Babies";
import DischargeReport from "./DischargeReport/DischargeReport";
import Family from "./Family/Family";

import { ReactComponent as ChevronLeft } from "assets/icons/chevron-left.svg";

import "./FamilyDashboard.scss";

import { FAMILIES_RECEIVED } from "actions/families";
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";

const FamilyDashboard = ({ match, history }) => {
  const [{ families, profiles, user }, dispatch] = useStateValue();
  const [familyId] = useState(match.params.familyId);

  let familyName;
  if (profiles.profiles) {
    const profile = profiles.profiles.find(
      (profile) => profile.accountId === familyId && profile.accountHolder
    );
    familyName = profile.lastName;
  }

  useEffect(() => {
    if (families.families) {
      return;
    }

    fetchRequest(user.token, "GET", "accounts", undefined)
      .then((families) => {
        families = families.filter(
          (familyMember) => familyMember.role === "regular"
        );

        dispatch({
          type: FAMILIES_RECEIVED,
          families,
        });

        const family = families.find(
          (familyMember) => familyMember.id === familyId
        );

        if (!family) {
          history.push("/families");
        }
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }, [families, user, familyId, dispatch, history]);

  return (
    <div className="iwk-family-dashboard">
      <div className="iwk-family-dashboard__header">
        <div className="iwk-family-dashboard__header-actions">
          <Link className="iwk-family-dashboard__header-link" to="/families">
            <ChevronLeft className="iwk-family-dashboard__header-link-icon" />
            Families
          </Link>
          <div className="iwk-family-dashboard__header-info">
            <span className="iwk-family-dashboard__header-id">
              Family Name: {familyName}
            </span>
          </div>
        </div>

        <div className="iwk-family-dashboard__header-tabs">
          <NavLink
            activeClassName="-active"
            className="iwk-family-dashboard__header-tab"
            exact
            to={{ pathname: `/families/${familyId}/family` }}
          >
            Family
          </NavLink>

          <NavLink
            activeClassName="-active"
            className="iwk-family-dashboard__header-tab"
            exact
            to={{ pathname: `/families/${familyId}/babies` }}
          >
            Babies
          </NavLink>

          <NavLink
            activeClassName="-active"
            className="iwk-family-dashboard__header-tab"
            exact
            to={{ pathname: `/families/${familyId}/discharge-report` }}
          >
            Discharge Report
          </NavLink>
        </div>
      </div>

      <Switch>
        <Route path="/families/:familyId/family" component={Family} />
        <Route path="/families/:familyId/babies" component={Babies} />
        <Route
          path="/families/:familyId/discharge-report"
          component={DischargeReport}
        />
        <Redirect path="/families/:familyId" to="/families/:familyId/family" />
      </Switch>
    </div>
  );
};

export default FamilyDashboard;
