import React from "react";
import PropTypes from "prop-types";

export default function Path({
  nodeHeight,
  nodeWidth,
  pathWidth,
  pathColor,
  node,
  columnCount
}) {
  const isEven = node.rowIndex % 2 === 0;
  const isOdd = !isEven;

  if (node.index === 0) return null;
  if (node.columnIndex === columnCount - 1 && isOdd) {
    return (
      <path
        key={`${node.index}-${node.columnIndex}-${node.rowIndex}`}
        fill="none"
        strokeWidth={pathWidth}
        stroke={pathColor}
        strokeLinecap="round"
        d={`M${node.columnIndex * nodeWidth + nodeWidth / 2},${node.rowIndex *
          nodeHeight +
          nodeHeight / 2} c${nodeWidth * 0.4},0 ${nodeWidth *
          0.4},-${nodeHeight} 0,-${nodeHeight} `}
      />
    );
  } else if (node.columnIndex === 0 && isEven) {
    return (
      <path
        key={`${node.index}-${node.columnIndex}-${node.rowIndex}`}
        fill="none"
        strokeWidth={pathWidth}
        stroke={pathColor}
        strokeLinecap="round"
        d={`M${node.columnIndex * nodeWidth + nodeWidth / 2},${node.rowIndex *
          nodeHeight +
          nodeHeight / 2} c-${nodeWidth * 0.4},0 -${nodeWidth *
          0.4},-${nodeHeight} 0,-${nodeHeight} `}
      />
    );
  }

  return (
    <path
      key={`${node.index}-${node.columnIndex}-${node.rowIndex}`}
      fill="none"
      strokeWidth={pathWidth}
      stroke={pathColor}
      strokeLinecap="round"
      d={`M${node.columnIndex * nodeWidth + nodeWidth / 2},${node.rowIndex *
        nodeHeight +
        nodeHeight / 2}h${isEven ? "-" : ""}${nodeWidth}`}
    />
  );
}

Path.defaultProps = {
  nodeHeight: 100,
  nodeWidth: 200,
  pathWidth: 10,
  node: {},
  columnCount: 1
};

Path.propTypes = {
  nodeHeight: PropTypes.number,
  nodeWidth: PropTypes.number,
  pathWidth: PropTypes.number,
  pathColor: PropTypes.string.isRequired,
  node: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.object]),
  columnCount: PropTypes.number
};
