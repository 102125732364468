import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { HomeIncomplete, HomeComplete } from "./DefaultIcons";
import ProgressIcon from "../../components/ProgressIcon/ProgressIcon";
import { imgToDataURL } from "./helper";

export const Nucleui = ({ x, y, node, isMobile }) => {
  const { topics, completion } = node;
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    imgToDataURL(node.acf.illustration_icon)
      .then(imgURL => {
        setImgSrc(imgURL);
      })
      .catch(e => setImgSrc(""));
  }, [node.acf.illustration_icon]);

  const content = (
    <g
      key={node.ID}
      onClick={node.onClick}
      transform={`translate(${x},${y})`}
      cursor="pointer"
    >
      <foreignObject
        width={96}
        height={96}
        x={-46}
        y={-96}
        pointerEvents="none"
      >
        {topics.length > 0 && (
          <ProgressIcon
            colorScheme="green"
            percentage={completion}
            imgSrc={imgSrc}
          />
        )}

        {topics.length === 0 && (
          <ProgressIcon
            colorScheme="green"
            percentage={completion}
            imgSrc={imgSrc}
          />
        )}
      </foreignObject>

      <text
        y={30}
        textAnchor={isMobile ? "" : "middle"}
        className="nuclei-text"
        pointerEvents="none"
      >
        {node.acf.title || node.post_title}
      </text>
    </g>
  );

  return isMobile || !node.directLink ? (
    content
  ) : (
    <Link to={node.directLink}>{content}</Link>
  );
};

export const renderHomeIcon = ({ x, y, node, fullCompletion }) => {
  const content = (
    <g
      key={`${node.index}-${node.columnIndex}-${node.rowIndex}`}
      onClick={node.onClick}
      transform={`translate(${x},${y})`}
    >
      {fullCompletion ? (
        <HomeComplete width={60} height={60} x={-33} y={-75} />
      ) : (
        <HomeIncomplete width={60} height={60} x={-33} y={-75} />
      )}
      <text y={30} textAnchor="middle">
        {node.label}
      </text>
    </g>
  );

  return content;
};

Nucleui.defaultProps = {
  x: undefined,
  y: undefined,
  node: {
    status: "inProgress"
  },
  isMobile: false
};

Nucleui.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  node: PropTypes.oneOfType([PropTypes.shape(), PropTypes.object]),
  isMobile: PropTypes.bool
};
