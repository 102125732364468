import { decode } from "jsonwebtoken";

export default function hasExpired(token) {
  if (!token) {
    return true;
  }

  const { exp } = decode(token);

  // expiry date from token is in milliseconds
  const now = Date.now() / 1000;

  return now > exp;
}