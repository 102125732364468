import React, { memo, useEffect, useMemo, useRef, useState } from "react";

// scss
import "./Requests.scss";

// components
import Checkbox from "components/Checkbox/Checkbox";
import TableHeaderButtons from "components/TableHeaderButtons/TableHeaderButtons";
import RequestsRow from "./RequestsRow/RequestsRow";
import Search from "components/Search/Search";

// hooks and utils
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import { sortRequestByDateCreated } from "utils/utils";
import { REQUESTS_DELETED } from "actions/requests";

import { generate } from "generate-password";

import { ACCOUNT_CREATED } from "actions/accounts";
import { FAMILY_CREATED } from "actions/families";
import { PROFILE_CREATED } from "actions/profiles";

const Requests = (props) => {
  const [{ requests, user, roles }, dispatch] = useStateValue();

  const [checkedIds, setCheckedIds] = useState([]);
  const [tableHeaderUsers, setTableHeaderUsers] = useState([]);
  const [requestsArray, setRequestsArray] = useState(undefined);
  const [filteredRequestsArray, setFilteredRequestsArray] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteStatus, setInviteStatus] = useState(null);
  const latestCheckedIds = useRef(checkedIds);

  useEffect(() => {
    if (!requests.requests) {
      return;
    }

    const filteredRequests = requests.requests.sort((a, b) =>
      sortRequestByDateCreated(a, b)
    );

    setRequestsArray(filteredRequests);
    setFilteredRequestsArray(filteredRequests);

    const tableHeaderUsers = filteredRequests.map((request) => {
      return {
        checked: request.checked || false,
      };
    });

    setTableHeaderUsers(tableHeaderUsers);
  }, [requests.requests]);

  useEffect(() => {
    if (inviteStatus && inviteStatus.accountId) {
      setShowInviteModal(true);
    }
  }, [inviteStatus]);

  const filterRequests = useMemo(
    () => (
      <React.Fragment>
        <span className="iwk-staff__actions-value">
          {filteredRequestsArray.length} Requests
        </span>
      </React.Fragment>
    ),
    [filteredRequestsArray]
  );

  const updateRequestStatus = (ids, action) => {
    const requested = filteredRequestsArray.filter((request) =>
      ids.includes(request.id)
    );

    if (requested) {
      requested.forEach((request) => {
        let updateBody = {
          approved: action,
          email: request.email,
        };

        fetchRequest(user.token, "PATCH", `requests/${request.id}`, updateBody)
          .then((result) => {
            if (action === 1) {
              sendInvite(
                request,
                "approved",
                result.customer ? result.customer : null
              );
            } else {
              sendInvite(
                request,
                "rejected",
                result.customer ? result.customer : null
              );
            }
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });
      });
    }
  };

  const addFamily = (values) => {
    if (!values.email) {
      return;
    }

    const password = generate({
      length: 10,
      numbers: true,
    });

    const accountBody = {
      email: values.email,
      role: "regular",
      access: "none",
      password,
      passwordConfirmation: password,
      status: "active",
      inviteSent: true,
    };

    const profileBody = {
      accountHolder: true,
      status: "active",
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: roles.roles.find((role) => role.name === "other").id,
      defaultRoom: values.room,
    };

    let account;

    fetchRequest(user.token, "POST", "accounts", accountBody)
      .then((response) => {
        account = response;
        return fetchRequest(
          user.token,
          "POST",
          `accounts/${account.id}/profiles`,
          profileBody
        );
      })
      .then((profile) => {
        dispatch({
          type: PROFILE_CREATED,
          profile,
        });

        dispatch({
          type: FAMILY_CREATED,
          family: account,
        });

        dispatch({
          type: ACCOUNT_CREATED,
          account,
        });
      })
      .then(() => {
        bulkDeleteRequests(values.id);
      })
      .then(() => {
        setInviteStatus({
          sent: true,
          message: `An approved email has been sent to: ${values.email}. 'Manage their account from the families tab.'`,
          accountId: account.id,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  };

  const handleSearchInput = (input) => {
    const filteredRequestArray = requestsArray.filter((request) => {
      const firstName = request.firstName;
      const lastName = request.lastName;
      const email = request.email;
      const room = request.room;

      return (
        (firstName && firstName.toLowerCase().includes(input)) ||
        (lastName && lastName.toLowerCase().includes(input)) ||
        (email && email.toLowerCase().includes(input)) ||
        (room && room.toLowerCase().includes(input))
      );
    });

    if (filteredRequestArray) {
      setFilteredRequestsArray(filteredRequestArray);
    }
  };

  const addCustomerAccount = (values, customer) => {
    if (!values.email) {
      return;
    }

    const password = generate({
      length: 10,
      numbers: true,
    });

    const accountBody = {
      email: values.email,
      role: "admin",
      access: "admin",
      password,
      passwordConfirmation: password,
      status: "active",
      inviteSent: true,
    };

    const profileBody = {
      accountHolder: true,
      status: "active",
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: roles.roles.find((role) => role.name === "other").id,
      defaultRoom: values.room,
    };

    let account;

    fetchRequest(
      user.token,
      "POST",
      "accounts",
      accountBody
    )
      .then((response) => {
        account = response;
        return fetchRequest(
          user.token,
          "POST",
          `accounts/${account.id}/profiles`,
          profileBody
        );
      })
      .then((profile) => {
        dispatch({
          type: PROFILE_CREATED,
          profile,
        });

        dispatch({
          type: ACCOUNT_CREATED,
          account,
        });
      })
      .then(() => {
        bulkDeleteRequests(values.id);
      })
      .then(() => {
        setInviteStatus({
          sent: true,
          message: `An approval email has been sent to: ${values.email}.`,
          accountId: values.id,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  };

  const sendInvite = (request, type, customer) => {
    const inviteBody = {
      email: request.email,
      requestId: request.id,
      type,
      customer,
    };

    if (customer) {
      fetchRequest(
        user.token,
        "POST",
        "invite",
        inviteBody
      )
        .then((result) => {
          // if there is no message invite was sent successfully
          if (!result.message) {
            if (type === "approved") {
              addCustomerAccount(request, customer);
              // setInviteStatus({
              //   sent: true,
              //   message: `An approval email has been sent to: ${request.email}.`,
              //   accountId: request.id
              // })
            } else {
              setInviteStatus({
                sent: true,
                message: `An rejection email has been sent to: ${request.email}.`,
                accountId: request.id,
              });
            }
          } else {
            setInviteStatus({
              sent: false,
              message:
                "There was an error sending out the email, try resending.",
              accountId: request.id,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      fetchRequest(user.token, "POST", "invite", inviteBody)
        .then((result) => {
          // if there is no message invite was sent successfully
          if (!result.message) {
            if (type === "approved") {
              addFamily(request);
              setInviteStatus({
                sent: true,
                message: `An approval email has been sent to: ${request.email}.`,
                accountId: request.id,
              });
            } else {
              setInviteStatus({
                sent: true,
                message: `An rejection email has been sent to: ${request.email}.`,
                accountId: request.id,
              });

              bulkDeleteRequests(request.id);
            }
          } else {
            setInviteStatus({
              sent: false,
              message:
                "There was an error sending out the email, try resending.",
              accountId: request.id,
            });
          }
        })
        .catch((error) => {
          // TODO app error handling
          console.log(error);
        });
    }
  };

  const updateCheckedIds = (id, checked) => {
    if (checked) {
      latestCheckedIds.current.push(id);
    } else {
      const checkedIndex = checkedIds.findIndex(
        (checkedId) => checkedId === id
      );
      latestCheckedIds.current.splice(checkedIndex, 1);
    }

    const tableHeaderUsers = filteredRequestsArray.map((request) => ({
      id: request.id,
      checked: request.id === id ? checked : request.checked,
    }));

    const newRequestsArray = filteredRequestsArray.map((request) => {
      if (request.id === id) {
        request.checked = checked;
      }
      return {
        ...request,
      };
    });

    setRequestsArray(newRequestsArray);
    setFilteredRequestsArray(newRequestsArray);
    setTableHeaderUsers(tableHeaderUsers);
    setCheckedIds(latestCheckedIds.current);
  };

  const onBulkCheckboxClick = (checked) => {
    let ids = [];
    const tableHeaderUsers = requestsArray.map((request) => {
      request.checked = checked;

      if (request.checked) {
        ids.push(request);
      } else {
        latestCheckedIds.current = [];
      }

      return {
        id: request.id,
        checked,
      };
    });

    setTableHeaderUsers(tableHeaderUsers);

    requestsArray.forEach((request) => {
      updateCheckedIds(request.id, checked);
    });
  };

  const bulkDeleteRequests = (requests) => {
    if (Array.isArray(requests)) {
      requests.forEach((id) => {
        fetchRequest(user.token, "DELETE", `requests/${id}`, undefined)
          .then((result) => {
            dispatch({
              type: REQUESTS_DELETED,
              signUpRequestId: id,
            });
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });
      });
    } else {
      fetchRequest(user.token, "DELETE", `requests/${requests}`, undefined)
        .then((result) => {
          dispatch({
            type: REQUESTS_DELETED,
            signUpRequestId: requests,
          });
        })
        .catch((error) => {
          // TODO app error handling
          console.log(error);
        });
    }
  };

  return (
    <div className="iwk-families">
      <div className="iwk-families__search">
        <Search placeholder="Search table" handleSearch={handleSearchInput} />
      </div>

      <div className="iwk-families__actions">
        <TableHeaderButtons
          checkedIds={latestCheckedIds.current}
          users={tableHeaderUsers}
          onBulkDeleteClick={bulkDeleteRequests}
          onUpdateStatusClick={updateRequestStatus}
          showDischargeButtons={false}
          showApproveButtons={true}
          requestsEmail={filteredRequestsArray.map(
            (request) => request.checked && request.email
          )}
        />

        {filteredRequestsArray && filterRequests}
      </div>

      <div className="iwk-families__table">
        <div className="iwk-families__table-header">
          <div className="iwk-families__table-header-cell -small">
            <Checkbox
              checked={
                checkedIds.length === filteredRequestsArray.length &&
                checkedIds.length
              }
              full={
                checkedIds.length > 0 &&
                checkedIds.length !== filteredRequestsArray.length
              }
              onCheckboxCheckedCallback={(checked) =>
                onBulkCheckboxClick(checked)
              }
            />
          </div>
          <div className="iwk-families__table-header-cell -large">Email</div>
          <div className="iwk-families__table-header-cell">First Name</div>
          <div className="iwk-families__table-header-cell">Last Name</div>
          <div className="iwk-families__table-header-cell">Room</div>
          <div className="iwk-families__table-header-cell">Date</div>
          <div className="iwk-families__table-header-cell"></div>
        </div>

        {filteredRequestsArray.length > 0 &&
          filteredRequestsArray.map((request, index) => {
            return (
              <RequestsRow
                id={request.id}
                email={request.email}
                firstName={request.firstName}
                familyName={request.lastName}
                roomNumber={request.room}
                date={request.createdAt}
                onCheckboxUpdate={updateCheckedIds}
                updateRequestStatus={updateRequestStatus}
                checked={request.checked}
                key={`requests-${index}`}
                showInviteModal={showInviteModal}
                setShowInviteModal={setShowInviteModal}
                inviteMessage={
                  inviteStatus && inviteStatus.message
                    ? inviteStatus.message
                    : null
                }
                inviteStatus={inviteStatus}
                bulkDeleteRequests={bulkDeleteRequests}
              />
            );
          })}
      </div>
    </div>
  );
};

export default memo(Requests);
