import { isEmpty, invalidEmail, errorMessages } from "utils/validation";

export default function validate({ email, password }) {
  let errors = {};

  if (isEmpty(email)) {
    errors.email = errorMessages.emptyValue;
  } else if (invalidEmail(email)) {
    errors.email = errorMessages.invalidEmail;
  }

  if (isEmpty(password)) {
    errors.password = errorMessages.emptyValue;
  }

  return errors;
};