import React from 'react';
import PropTypes from 'prop-types';

export default function MobilePath({
  nodeHeight,
  nodeWidth,
  pathWidth,
  pathColor,
  node,
  previousNode
}) {
  if (node.index === 0) return null;

  const calculateStartPoint = () =>
    `${nodeWidth / 2}, ${node.rowIndex * nodeHeight * 1.1}`;

  const calculateEndpoint = () => `${nodeWidth / 2}, ${nodeHeight}`;

  return (
    <path
      key={`${node.index}-${node.columnIndex}-${node.rowIndex}`}
      fill="none"
      strokeWidth={pathWidth}
      stroke={pathColor}
      strokeLinecap="round"
      d={`M${calculateStartPoint()} ${calculateEndpoint()}`}
    />
  );
}

MobilePath.defaultProps = {
  nodeHeight: 100,
  nodeWidth: 200,
  pathWidth: 15,
  pathColor: '#ccc',
  node: {},
  previousNode: {}
};

MobilePath.propTypes = {
  nodeHeight: PropTypes.number,
  nodeWidth: PropTypes.number,
  pathWidth: PropTypes.number,
  pathColor: PropTypes.number,
  node: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.object]),
  previousNode: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.object])
};
