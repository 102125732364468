import { fetch } from "whatwg-fetch";

export function calculatePathColor(nodes, index, fullCompletion) {
  const green = "#14a0a2";
  const white = "#fff";

  // fully completed is always green
  if (fullCompletion) {
    return green;
  }

  // unless full completion, home is white
  if (index === 0) {
    return white;
  }

  // final path is not rendered
  if (index === nodes.length - 1) {
    return white;
  }

  // if both this step, and the next are complete, then green
  if (nodes[index].completion === 100 && nodes[index + 1].completion === 100) {
    return green;
  }

  // else white
  return white;
}

export default { calculatePathColor };

export const imgToDataURL = url => {
  return fetch(url)
    .then(res => {
      if (!res.ok) throw new Error("err");
      return res;
    })
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          if (blob.size !== 0) {
            reader.readAsDataURL(blob);
          } else {
            reject();
          }
        })
    )
    .catch(e => {});
};
