import React, { memo, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// scss
import "./Dashboard.scss";

// components
import FamilyDashboard from "./FamilyDashboard/FamilyDashboard";
import StaffFamiliesDashboard from "./StaffFamiliesDashboard/StaffFamiliesDashboard";

// hooks and utils
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";

// actions
import { ACCOUNTS_RECEIVED, ACCOUNT_ACCESS_RECEIVED } from "actions/accounts";
import { BABIES_RECEIVED } from "actions/babies";
import { FAMILIES_RECEIVED } from "actions/families";
import { STAFF_RECEIVED } from "actions/staff";
import { PROFILES_RECIEVED } from "actions/profiles";
import { ROLES_RECEIVED } from "actions/roles";
import { REQUESTS_RECEIVED } from "actions/requests";

const Dashboard = () => {
  const [{ user, accounts, profiles }, dispatch] = useStateValue();

  // on dashboard load get accounts and profiles
  useEffect(() => {
    if (accounts.accounts || profiles.profiles) {
      return;
    }

    fetchRequest(user.token, "GET", "roles", undefined)
      .then(roles => {
        dispatch({
          type: ROLES_RECEIVED,
          roles
        });
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });

    fetchRequest(user.token, "GET", "accounts", undefined)
      .then(accounts => {
        dispatch({
          type: ACCOUNTS_RECEIVED,
          accounts
        });

        const staff = accounts.filter(account => account.role === "admin");
        const families = accounts.filter(account => account.role === "regular");

        dispatch({
          type: FAMILIES_RECEIVED,
          families
        });

        dispatch({
          type: STAFF_RECEIVED,
          staff
        });
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });

    fetchRequest(user.token, "GET", "profiles", undefined)
      .then(profiles => {
        dispatch({
          type: PROFILES_RECIEVED,
          profiles
        });
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });

    fetchRequest(user.token, "GET", "babies", undefined)
      .then(babies => {
        dispatch({
          type: BABIES_RECEIVED,
          babies
        });
      })
      .catch(error => {
        // TODO app error handling
        console.log(error);
      });

      fetchRequest(user.token, "GET", "requests", undefined)
        .then(requests => {
          dispatch({
            type: REQUESTS_RECEIVED,
            requests
          });
        })
        .catch(error => {
          // TODO app error handling
          console.log(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.user || !accounts.accounts) {
      return;
    }

    // when we receive both user and accounts, find the users access and set it in store
    const account = accounts.accounts.find(
      account => account.id === user.user.accountId
    );
    dispatch({
      type: ACCOUNT_ACCESS_RECEIVED,
      access: account.access
    });
  }, [accounts.accounts, dispatch, user.user]);

  return (
    <div className="iwk-dashboard">
      <Switch>
        <Route path="/families/:familyId" component={FamilyDashboard} />
        <Route path="/families" component={StaffFamiliesDashboard} />
        <Route path="/staff" component={StaffFamiliesDashboard} />
        <Route path="/requests" component={StaffFamiliesDashboard} />
        <Redirect path="/" to="/staff" />
      </Switch>
    </div>
  );
};

export default memo(Dashboard);
