import React, { memo, useCallback } from "react";
import Form from 'react-bootstrap/Form';

import "./Search.scss";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

const Search = ({ placeholder, handleSearch }) => {
  return (
    <div className="iwk-search">
      <Form.Control
        className="iwk-search__input"
        placeholder={placeholder}
        type="text"
        onChange={useCallback((e) => handleSearch(e.target.value.toLowerCase()), [handleSearch])}
      />
      <SearchIcon className="iwk-search__icon"/>
    </div>
  );
};

export default memo(Search);