import React from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';
import classNames from 'classnames';
import 'react-circular-progressbar/dist/styles.css';
import { Checkmark } from '../../components/icons';
import './ProgressIcon.css';

function ProgressIcon(props) {
  const { percentage, colorScheme } = props;

  const className = classNames('progress-icon', {
    'progress-icon--red': colorScheme === 'red',
    'progress-icon--green': colorScheme === 'green',
    'progress-icon--blue': colorScheme === 'blue',
    'progress-icon--empty': percentage === 0
  });

  return (
    <div className={className}>
      <div
        className="progress-icon__image"
        style={{
          backgroundImage: `url(${props.imgSrc})`,
          backgroundSize: 'cover'
        }}
      />
      <CircularProgressbar
        strokeWidth={5}
        percentage={props.percentage}
        textForPercentage={null}
      />
      {props.percentage === 100 && (
        <div className="progress-icon__checkmark">
          <Checkmark />
        </div>
      )}
    </div>
  );
}

ProgressIcon.propTypes = {
  percentage: PropTypes.number,
  colorScheme: PropTypes.oneOf(['red', 'green', 'blue'])
};

ProgressIcon.defaultProps = {
  percentage: 0,
  colorScheme: 'red'
};

export default ProgressIcon;
