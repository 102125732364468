import React, { memo } from "react";
import { Button, Modal } from "react-bootstrap";

export const ConfirmationModal = ({
  message,
  modalCloseAction,
  confirmAction,
  confirmActionLabel,
  cancelAction,
  cancelActionLabel,
  show,
  title
}) => {
  return (
    <Modal show={ show } onHide={ modalCloseAction } size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          { title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{ message }</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ cancelAction }>{ cancelActionLabel }</Button>
        <Button
          variant="primary"
          onClick={() => {
            confirmAction();
            modalCloseAction();
          }}>
          { confirmActionLabel }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(ConfirmationModal);