import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.string.isRequired,
  numRows: PropTypes.number.isRequired,
  topMargin: PropTypes.bool
};

const defaultProps = {
  topMargin: false
};

export default function FooterSectionRow(props) {
  const { title, numRows, topMargin } = props;
  const [lines, setLines] = useState([]);

  useEffect(() => {
    let tempLines = [];
    for (let i = 0; i < numRows; i++) {
      tempLines.push(
        <div className="iwk-discharge-report-pdf__footer-signatures-placeholder" />
      );
    }
    setLines(tempLines);
  }, [numRows]);

  return (
    <>
      <div
        className={`iwk-discharge-report-pdf__footer-section-title 
        ${topMargin && "--top-margin"}`}
      >
        {title}
      </div>
      <div className="iwk-discharge-report-pdf__footer-signatures">
        <div>
          <div className="iwk-discharge-report-pdf__footer-signatures-title">
            Name
          </div>
          {lines.map(line => line)}
        </div>
        <div>
          <div className="iwk-discharge-report-pdf__footer-signatures-title">
            Signature
          </div>
          {lines.map(line => line)}
        </div>
        <div>
          <div className="iwk-discharge-report-pdf__footer-signatures-title">
            Initial
          </div>
          {lines.map(line => line)}
        </div>
      </div>
    </>
  );
}

FooterSectionRow.propTypes = propTypes;
FooterSectionRow.defaultProps = defaultProps;
