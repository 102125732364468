import React, { memo } from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import "./Dropdown.scss";

export const Dropdown = ({ name, options, selectedValue, onChange, isInvalid, customOption = false }) => {
  const customStyles = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: '#FFF',
        width: "32rem",
        borderRadius: "2px",
        height: "4.2rem",
        fontSize: "1.4rem",
        letterSpacing: "0.082rem",
        boxShadow: isFocused ? 0 : 0,
        border: isInvalid ? "1px solid #dc3545" : "1px solid #DEE2E6",

        ":hover": {
          border: isInvalid ? "1px solid #dc3545" : "1px solid #DEE2E6"
        }
      }
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#5768A8" : "#FFFFFF",
        color: isSelected ? "#FFFFFF" : "#212529",
        width: "32rem",
        fontSize: "1.4rem",
        letterSpacing: "0.082rem",
        ':active': {
          ...styles[':active'],
          backgroundColor: "#5768A8",
          textColor: "#FFFFFF"
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: "#F7F4FD",
          color: "#212529"
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles,) => ({ ...styles }),
  };

  return (
    <div>
      { customOption &&
        <CreatableSelect
          isClearable
          defaultValue={selectedValue || ""}
          className="iwk-dropdown"
          classNamePrefix="select"
          options={options}
          styles={customStyles}
          name={name}
          onChange={onChange}
        />
      }
      { !customOption &&
        <Select
          defaultValue={selectedValue || ""}
          className="iwk-dropdown"
          classNamePrefix="select"
          options={options}
          styles={customStyles}
          name={name}
          onChange={onChange}
        />
      }
      {
        !!isInvalid &&
        <div className="iwk-dropdown__error">
          { isInvalid }
        </div>
      }
    </div>
  )
}

export default memo(Dropdown);