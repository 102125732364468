import React, { memo } from "react";
import { Button, Modal } from "react-bootstrap";

import "./InfoModal.scss";

export const InfoModal = ({
  message,
  modalCloseAction,
  show,
  title,
  className,
  size
}) => {
  return (
    <Modal
      className={`iwk-info-modal ${className ? className : ""}`}
      show={show}
      onHide={modalCloseAction}
      size={size ? size : "sm"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            modalCloseAction();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(InfoModal);
