import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Icon.css';

const NAME_TO_PATH_MAP = {
  ArrowLeft: (
    <path d="M29.33,8a.71.71,0,0,1,.48.19l4.67,4.66a.69.69,0,0,1,0,1L24.3,24,34.48,34.18a.69.69,0,0,1,0,1l-4.67,4.67a.71.71,0,0,1-1,0L13.52,24.48a.7.7,0,0,1,0-1L28.85,8.19A.75.75,0,0,1,29.33,8Z" />
  ),
  ArrowRight: (
    <path d="M18.67,8a.71.71,0,0,0-.48.19l-4.67,4.66a.69.69,0,0,0,0,1L23.7,24,13.52,34.18a.69.69,0,0,0,0,1l4.67,4.67a.71.71,0,0,0,1,0L34.48,24.48a.7.7,0,0,0,0-1L19.15,8.19A.75.75,0,0,0,18.67,8Z" />
  ),
  ArrowUp: (
    <path d="M8,29.33a.71.71,0,0,0,.19.48l4.66,4.67a.69.69,0,0,0,1,0L24,24.3,34.18,34.48a.69.69,0,0,0,1,0l4.67-4.67a.71.71,0,0,0,0-1L24.48,13.52a.7.7,0,0,0-1,0L8.19,28.85A.75.75,0,0,0,8,29.33Z" />
  ),
  ArrowDown: (
    <path d="M8,18.67a.71.71,0,0,1,.19-.48l4.66-4.67a.69.69,0,0,1,1,0L24,23.7,34.18,13.52a.69.69,0,0,1,1,0l4.67,4.67a.71.71,0,0,1,0,1L24.48,34.48a.7.7,0,0,1-1,0L8.19,19.15A.75.75,0,0,1,8,18.67Z" />
  ),
  Bullet: <circle cx="24" cy="24" r="16" />,
  BulletEmpty: (
    <path d="M24,40A16,16,0,1,1,40,24,16,16,0,0,1,24,40Zm0-4A12,12,0,1,0,12,24,12,12,0,0,0,24,36Z" />
  ),
  Calendar: (
    <path d="M35.33,26V20.67H30V26Zm0,9.33V30H30v5.33ZM26.67,26V20.67H21.33V26Zm0,9.33V30H21.33v5.33ZM18,26V20.67H12.67V26Zm0,9.33V30H12.67v5.33ZM38,8a2,2,0,0,1,2,2V38a2,2,0,0,1-2,2H10a2,2,0,0,1-2-2V10a2,2,0,0,1,2-2h2.67v4a2,2,0,0,0,4,0V8H31.33v4a2,2,0,0,0,4,0V8Z" />
  ),
  Checkmark: (
    <path d="M39.81,17.16a.72.72,0,0,0,0-1l-4.67-4.66a.69.69,0,0,0-1,0L20,25.74l-6.18-6.21a.69.69,0,0,0-1,0L8.19,24.19a.71.71,0,0,0,0,1L19.52,36.48a.79.79,0,0,0,.48.18.75.75,0,0,0,.48-.18Z" />
  ),
  Clock: (
    <path d="M30.13,30.27a1.77,1.77,0,0,1-2.36,0l-5.27-4.2a1.91,1.91,0,0,1-.63-1.5l.36-8.47a1.77,1.77,0,0,1,3.54,0l.33,7.57,4.13,4.06A1.79,1.79,0,0,1,30.13,30.27ZM24,35.93A12.07,12.07,0,0,0,35.93,24,12.07,12.07,0,0,0,24,12.07,12.09,12.09,0,0,0,12.07,24,12.09,12.09,0,0,0,24,35.93ZM24,8A16,16,0,1,1,8,24,16,16,0,0,1,24,8Z" />
  ),
  Dashboard: (
    <g>
      <path
        className="svg-outline"
        d="M15.5,17.5V27.94H4V17.5H15.5m0-2H4a2,2,0,0,0-2,2V27.94a2,2,0,0,0,2,2H15.5a2,2,0,0,0,2-2V17.5A2,2,0,0,0,15.5,15.5ZM27.94,6v5.5H16.5V6H27.94m0-2H16.5a2,2,0,0,0-2,2v5.5a2,2,0,0,0,2,2H27.94a2,2,0,0,0,2-2V6A2,2,0,0,0,27.94,4Zm0,13.5v7.44H21.5V17.5h6.44m0-2H21.5a2,2,0,0,0-2,2v7.44a2,2,0,0,0,2,2h6.44a2,2,0,0,0,2-2V17.5A2,2,0,0,0,27.94,15.5ZM10.5,4V11.5H4V4h6.5m0-2H4A2,2,0,0,0,2,4V11.5a2,2,0,0,0,2,2h6.5a2,2,0,0,0,2-2V4A2,2,0,0,0,10.5,2Z"
      />
      <path
        className="svg-fill"
        d="M15.5,15.5H4a2,2,0,0,0-2,2V27.94a2,2,0,0,0,2,2H15.5a2,2,0,0,0,2-2V17.5A2,2,0,0,0,15.5,15.5ZM27.94,4H16.5a2,2,0,0,0-2,2v5.5a2,2,0,0,0,2,2H27.94a2,2,0,0,0,2-2V6A2,2,0,0,0,27.94,4Zm0,11.5H21.5a2,2,0,0,0-2,2v7.44a2,2,0,0,0,2,2h6.44a2,2,0,0,0,2-2V17.5A2,2,0,0,0,27.94,15.5ZM10.5,2H4A2,2,0,0,0,2,4V11.5a2,2,0,0,0,2,2h6.5a2,2,0,0,0,2-2V4A2,2,0,0,0,10.5,2Z"
      />
    </g>
  ),
  Delete: (
    <path
      className="svg-delete"
      d="M16,14.59l4.24-4.25,1.42,1.42L17.41,16l4.25,4.24-1.42,1.42L16,17.41l-4.24,4.25-1.42-1.42L14.59,16l-4.25-4.24,1.42-1.42Z"
    />
  ),
  Diary: (
    <g>
      <path
        className="svg-stroke"
        d="M28.82,13.39,22.09,24h-4.3V20.7a2,2,0,0,0-2-2H11.22a2,2,0,0,0-2,2V24H4.89V15.86H20.61a2,2,0,0,0,1.67-.9l6.5-9.86A2,2,0,0,0,27.11,2H13.17a2,2,0,0,0-1.67.89L3.22,15.36a2.05,2.05,0,0,0-.33,1.1V24a2,2,0,0,0,2,2H9.22v4l4.28-2.13L17.79,30V26H22a2,2,0,0,0,1.7-.95l5.16-8.34ZM13.09,4H26.87L20.7,13.86H6.92Zm2.7,22.8L13.5,25.62,11.22,26.8V20.7h4.57Z"
      />
      <path
        className="svg-fill"
        d="M28.82,13.39,22.09,24h-4.3V20.7a2,2,0,0,0-2-2H11.22a2,2,0,0,0-2,2V24H4.89V15.86H20.61a2,2,0,0,0,1.67-.9l6.5-9.86A2,2,0,0,0,27.11,2H13.17a2,2,0,0,0-1.67.89L3.22,15.36a2.05,2.05,0,0,0-.33,1.1V24a2,2,0,0,0,2,2H9.22v4l4.28-2.13L17.79,30V26H22a2,2,0,0,0,1.7-.95l5.16-8.34Z"
      />
    </g>
  ),
  Discharge: (
    <g>
      <path
        className="svg-stroke"
        d="M24.88,2H5.76a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h7.31v3.1h5.86V11h6a1,1,0,0,0,.65-.25l4.05-3.49a1,1,0,0,0,0-1.51L25.54,2.26A1,1,0,0,0,24.88,2Zm-.37,7H16.93v3.1H15.07V9H5.76V4H24.51l2.9,2.5ZM6.38,16.46,2.33,20a1,1,0,0,0,0,1.52L6.39,25a1,1,0,0,0,.65.24h6V30h5.86V25.22h7.32a2,2,0,0,0,2-2v-5a2,2,0,0,0-2-2H7A1,1,0,0,0,6.38,16.46Zm19.86,6.76H16.93V28H15.07V23.22H7.41l-2.9-2.5,2.91-2.5H26.24Z"
      />
      <path
        className="svg-fill"
        d="M24.88,2H5.76a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h7.31v3.1h5.86V11h6a1,1,0,0,0,.65-.25l4.05-3.49a1,1,0,0,0,0-1.51L25.54,2.26A1,1,0,0,0,24.88,2ZM6.38,16.46,2.33,20a1,1,0,0,0,0,1.52L6.39,25a1,1,0,0,0,.65.24h6V30h5.86V25.22h7.32a2,2,0,0,0,2-2v-5a2,2,0,0,0-2-2H7A1,1,0,0,0,6.38,16.46Z"
      />
    </g>
  ),
  ChevronDown: (
    <path
      className="chevron-down"
      d="M4.69,8.93,16,20.24,27.31,8.93l1.42,1.41L17.41,21.66,16,23.07l-1.41-1.41L3.27,10.34Z"
    />
  ),
  Download: (
    <path d="M34.8 24.5H40c0 8.5-1.2 12.8-1.2 13-.3 1.2-1.4 2-2.6 2H11.8c-1.2 0-2.3-.8-2.6-2 0-.2-1.2-4.4-1.2-13h5.2c0 3.2.2 6.4.6 9.6h20.3c.5-3.2.7-6.4.7-9.6zm-11.6 3c-3.3-2.8-6.2-6-8.7-9.5-.2-.4-.3-.9-.1-1.3.2-.4.6-.7 1.1-.7h4.3c.3-2.2.8-4.3 1.6-6.4.7-.8 1.6-1.2 2.6-1.1 1-.1 1.9.3 2.5 1.1.7 2.1 1.3 4.2 1.6 6.4h4.3c.5 0 .9.3 1.1.7.2.4.2.9-.1 1.3-2.5 3.5-5.4 6.7-8.7 9.5-.4.3-1 .3-1.5 0z" />
  ),
  Edit: (
    <g>
      <path d="M29.37,9a2,2,0,0,0,0-2.83h0L25.79,2.63a2,2,0,0,0-2.83,0h0L2,23.55V30H8.45Zm-5-5L28,7.63l-3,3L21.37,7ZM4,28V24.37L20,8.45,23.55,12,7.63,28Z" />
      <path d="M29.37,9a2,2,0,0,0,0-2.83h0L25.79,2.63a2,2,0,0,0-2.83,0h0L2,23.55V30H8.45ZM4,28V24.37L20,8.45,23.55,12,7.63,28Z" />
    </g>
  ),
  Gear: (
    <g>
      <path
        className="gear-outline"
        d="M27,16.76V15.23l1.92-1.68A2,2,0,0,0,29.29,11L26.93,7A2,2,0,0,0,25.2,6a2,2,0,0,0-.64.1l-2.43.82a9.89,9.89,0,0,0-1.31-.75l-.51-2.52a2,2,0,0,0-2-1.61H13.63a2,2,0,0,0-2,1.61l-.51,2.52a12.39,12.39,0,0,0-1.32.75L7.42,6.06A2.25,2.25,0,0,0,6.78,6,2,2,0,0,0,5.05,7L2.69,11a2,2,0,0,0,.41,2.51L5,15.24v1.53L3.1,18.45A2,2,0,0,0,2.69,21l2.36,4a2,2,0,0,0,1.73,1,2,2,0,0,0,.64-.1l2.43-.82a11.35,11.35,0,0,0,1.31.75l.51,2.52a2,2,0,0,0,2,1.61h4.72a2,2,0,0,0,2-1.61l.51-2.52a10.69,10.69,0,0,0,1.32-.75l2.42.82a2,2,0,0,0,.64.1A2,2,0,0,0,27,25l2.28-4a2,2,0,0,0-.41-2.51ZM25.2,24l-3.43-1.16a8.86,8.86,0,0,1-2.71,1.57L18.35,28H13.63l-.71-3.55a9.5,9.5,0,0,1-2.7-1.57L6.78,24,4.42,20l2.72-2.4a9.14,9.14,0,0,1,0-3.13L4.42,12,6.78,8l3.43,1.16a9.1,9.1,0,0,1,2.71-1.57L13.63,4h4.72l.71,3.55a9.23,9.23,0,0,1,2.7,1.57L25.2,8l2.36,4-2.72,2.4a8.67,8.67,0,0,1,0,3.13L27.56,20ZM16,22a6,6,0,1,1,6-6,5.93,5.93,0,0,1-5.87,6Zm0-10a3.92,3.92,0,0,0-4,3.82V16a3.91,3.91,0,0,0,3.82,4H16a3.91,3.91,0,0,0,4-3.82A.54.54,0,0,0,20,16a3.9,3.9,0,0,0-3.81-4Z"
      />
      <path
        className="gear-fill"
        d="M27,16.76V15.23l1.92-1.68A2,2,0,0,0,29.29,11L26.93,7A2,2,0,0,0,25.2,6a2,2,0,0,0-.64.1l-2.43.82a9.89,9.89,0,0,0-1.31-.75l-.51-2.52a2,2,0,0,0-2-1.61H13.63a2,2,0,0,0-2,1.61l-.51,2.52a12.39,12.39,0,0,0-1.32.75L7.42,6.06A2.25,2.25,0,0,0,6.78,6,2,2,0,0,0,5.05,7L2.69,11a2,2,0,0,0,.41,2.51L5,15.24v1.53L3.1,18.45A2,2,0,0,0,2.69,21l2.36,4a2,2,0,0,0,1.73,1,2,2,0,0,0,.64-.1l2.43-.82a11.35,11.35,0,0,0,1.31.75l.51,2.52a2,2,0,0,0,2,1.61h4.72a2,2,0,0,0,2-1.61l.51-2.52a10.69,10.69,0,0,0,1.32-.75l2.42.82a2,2,0,0,0,.64.1A2,2,0,0,0,27,25l2.28-4a2,2,0,0,0-.41-2.51Zm-7-.58A3.91,3.91,0,0,1,16,20h-.18A3.91,3.91,0,0,1,12,16v-.18A3.92,3.92,0,0,1,16,12h.19A3.9,3.9,0,0,1,20,16,.54.54,0,0,1,20,16.18Z"
      />
    </g>
  ),
  Guide: (
    <path d="M20.8 40v-5.3h-6.5c-.4 0-.8-.2-1.1-.5L8.8 31c-.5-.4-.8-1-.8-1.7s.3-1.3.9-1.7l4.3-3.2c.3-.2.7-.4 1.1-.4h21.4c1.2.1 2.2 1.1 2.2 2.3v6.2c0 1.2-1 2.2-2.2 2.2h-8.5V40h-6.4zm18.3-28.4c.6.4.9 1 .9 1.7a2 2 0 0 1-.9 1.7l-4.3 3.2c-.3.3-.7.5-1.1.5h-6.5v3.2h-6.4v-3.2h-8.5c-1.3-.1-2.3-1.1-2.2-2.4v-6.2A2 2 0 0 1 12.2 8h21.5c.4 0 .8.2 1.1.4l4.3 3.2z" />
  ),
  Heart: (
    <path d="M38.28,24c4.39-9.28-.64-14.21-5.75-14.25A7.59,7.59,0,0,0,28.17,11,10.6,10.6,0,0,0,24,16.57a10.58,10.58,0,0,0-4.22-5.5,7.2,7.2,0,0,0-4.21-1.32C10.39,9.68,5.21,14.61,9.64,24,13.85,33,21.78,38.29,24,38.29S34.07,32.93,38.28,24Z" />
  ),
  HeartEmpty: (
    <path d="M35.09,23.2c2.9-6.14.7-10.06-3.47-10.09a4.94,4.94,0,0,0-2.85.86,8.57,8.57,0,0,0-1.36,1.16,7.74,7.74,0,0,0-1.35,2,3.87,3.87,0,0,0-.3.75,1.82,1.82,0,0,1-3.52,0,4.48,4.48,0,0,0-.31-.76,7.54,7.54,0,0,0-1.37-1.95A7.9,7.9,0,0,0,19.19,14a4.59,4.59,0,0,0-2.71-.84C12.26,13.08,10,17,12.9,23.23S22.06,34.89,24,34.89,32.2,29.34,35.09,23.2ZM24,38.52c-3.62,0-11-6.47-14.38-13.75-4-8.53-.09-15.36,6.88-15.26A8.16,8.16,0,0,1,21.27,11a11.79,11.79,0,0,1,1.92,1.63c.29.31.56.62.8.92.23-.3.49-.6.77-.9A11.61,11.61,0,0,1,26.71,11a8.6,8.6,0,0,1,4.92-1.5c6.93,0,10.74,6.82,6.75,15.26C35,32,27.61,38.52,24,38.52Z" />
  ),
  Help: (
    <g>
      <path
        className="svg-outline"
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Zm0-6a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,22Zm5.5-9.5A4.49,4.49,0,0,1,17,17v2.5H15V15h2a2.5,2.5,0,0,0,0-5H15.5A2.5,2.5,0,0,0,13,12.5V13H11v-.52A4.49,4.49,0,0,1,15.5,8H17A4.49,4.49,0,0,1,21.5,12.5Z"
      />
      <path
        className="svg-fill"
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,20a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,22Zm5.5-9.5A4.49,4.49,0,0,1,17,17v2.5H15V15h2a2.5,2.5,0,0,0,0-5H15.5A2.5,2.5,0,0,0,13,12.5V13H11v-.52A4.49,4.49,0,0,1,15.5,8H17A4.49,4.49,0,0,1,21.5,12.5Z"
      />
    </g>
  ),
  Info: (
    <path d="M21.67,33.6h4.66a1.06,1.06,0,0,0,1.07-1.07V31.47a1.06,1.06,0,0,0-1.07-1.07l0-8.53A1.08,1.08,0,0,0,25.3,20.8H21.67a1.06,1.06,0,0,0-1.07,1.07v1.06A1.06,1.06,0,0,0,21.67,24v6.4a1.06,1.06,0,0,0-1.07,1.07v1.06A1.06,1.06,0,0,0,21.67,33.6ZM24,17.9a2.46,2.46,0,0,0,2.43-2.4,2.43,2.43,0,1,0-4.86,0A2.46,2.46,0,0,0,24,17.9ZM24,8A16,16,0,1,1,8,24,16,16,0,0,1,24,8Z" />
  ),
  Learn: (
    <g>
      <path
        className="svg-stroke"
        d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,16,2Zm7.44,16A13,13,0,0,0,22,18,37.27,37.27,0,0,0,16,19.33,37.27,37.27,0,0,0,10.05,18,13,13,0,0,0,8.56,18a12.06,12.06,0,0,0-4.07.65V29a11.54,11.54,0,0,1,3.91-.56q.67,0,1.47.06A23.83,23.83,0,0,1,15,29.63L16,30l1-.36a23.83,23.83,0,0,1,5.13-1.15q.8-.06,1.47-.06a11.54,11.54,0,0,1,3.91.56V18.61A12.06,12.06,0,0,0,23.44,18ZM10,26.49c-.55,0-1.1-.07-1.63-.07a18,18,0,0,0-1.91.1V20.15A10.74,10.74,0,0,1,8.56,20,10.81,10.81,0,0,1,9.83,20,34.17,34.17,0,0,1,15,21.12v6.41A24.63,24.63,0,0,0,10,26.49Zm15.48,0a18,18,0,0,0-1.91-.1c-.53,0-1.08,0-1.63.07a24.63,24.63,0,0,0-5,1V21.12A34.44,34.44,0,0,1,22.17,20a11.44,11.44,0,0,1,3.34.12Z"
      />
      <path
        className="svg-fill"
        d="M16,2a7,7,0,1,0,7,7A7,7,0,0,0,16,2Zm7.44,16A13,13,0,0,0,22,18,36.2,36.2,0,0,0,16,19.32,36.2,36.2,0,0,0,10.05,18,11,11,0,0,0,8.56,18a12.06,12.06,0,0,0-4.07.65V29a11.54,11.54,0,0,1,3.91-.56q.67,0,1.47.06A23.25,23.25,0,0,1,15,29.62L16,30l1-.36a23.25,23.25,0,0,1,5.13-1.15q.8-.06,1.47-.06a11.54,11.54,0,0,1,3.91.56V18.6A12.06,12.06,0,0,0,23.44,18Z"
      />
    </g>
  ),
  Logout: (
    <path
      className="svg-fill"
      d="M8.37,22.36,9.79,21,5.84,17H18V15H5.84l3.95-3.95L8.37,9.64,2,16ZM29.93,4V28a2,2,0,0,1-2,2h-16a2,2,0,0,1-2-2V24h2v4h16V4h-16V8h-2V4a2,2,0,0,1,2-2h16A2,2,0,0,1,29,2.34a2.17,2.17,0,0,1,.55.54A2.05,2.05,0,0,1,29.93,4Z"
    />
  ),
  Minus: <rect x="8" y="20" width="32" height="8" rx="0.67" ry="0.67" />,
  Menu: (
    <path className="svg-outline" d="M2,15H30v2H2Zm0,8H30v2H2ZM2,7H30V9H2Z" />
  ),
  Plus: (
    <path d="M27.33,40a.67.67,0,0,0,.67-.67V28H39.33a.67.67,0,0,0,.67-.67V20.67a.67.67,0,0,0-.67-.67H28V8.67A.67.67,0,0,0,27.33,8H20.67a.67.67,0,0,0-.67.67V20H8.67a.67.67,0,0,0-.67.67v6.66a.67.67,0,0,0,.67.67H20V39.33a.67.67,0,0,0,.67.67Z" />
  ),
  Search: (
    <path
      id="search"
      d="M29.51,28.1,22,20.51A11,11,0,1,0,20.51,22l7.59,7.55Zm-25-14.59a9,9,0,1,1,9,9A9,9,0,0,1,4.51,13.51Z"
    />
  ),
  ToolTip: (
    <g>
      <path
        className="edu-icon__tooltip-background"
        d="M24 8c8.8 0 16 7.2 16 16s-7.2 16-16 16S8 32.8 8 24 15.2 8 24 8z"
        fill="#666"
      />
      <path
        className="edu-icon__tooltip-foreground"
        d="M21.7 33.6h4.7c.6 0 1.1-.5 1.1-1v-1.1c0-.6-.5-1.1-1-1.1v-8.5c0-.6-.5-1-1-1.1h-3.6c-.6 0-1.1.5-1.1 1v1.1c0 .6.5 1.1 1 1.1v6.4c-.6 0-1.1.5-1.1 1v1.1c-.1.6.4 1.1 1 1.1zM24 17.9c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4c0 1.3 1.1 2.4 2.4 2.4z"
      />
    </g>
  ),
  Track: (
    <g>
      <path
        id="trackCircleOutline"
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
      />
      <path id="trackPlusOutline" d="M17,15h6v2H17v6H15V17H9V15h6V9h2Z" />
      {/* <path
        id="trackCircleFill"
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z"
      />
      <polygon
        id="trackPlusFill"
        points="17 15 23 15 23 17 17 17 17 23 15 23 15 17 9 17 9 15 15 15 15 9 17 9 17 15"
      /> */}
    </g>
  ),
  Undo: (
    <path d="M40,24.69A15.34,15.34,0,0,0,24.7,9.39a15.16,15.16,0,0,0-10.32,4L9.2,8.19A.79.79,0,0,0,8.43,8,.69.69,0,0,0,8,8.69V21.91a.7.7,0,0,0,.7.7H21.91a.73.73,0,0,0,.66-.43.78.78,0,0,0-.15-.77l-4.1-4.06A9.56,9.56,0,0,1,24.7,15a9.74,9.74,0,0,1,0,19.47A9.87,9.87,0,0,1,18.28,32a.67.67,0,0,0-.93,0l-3,3a.61.61,0,0,0-.16.47.63.63,0,0,0,.2.46v0h0A15.29,15.29,0,0,0,40,24.69Z" />
  ),
  User: (
    <path d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,16,2ZM26,30H24V25a5,5,0,0,0-5-5H13a5,5,0,0,0-5,5v5H6V25a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z" />
  ),
  Users: (
    <path d="M19.1 37.7c-3.6.1-7.3 0-10.9-.4-.2-.1-.5-.2-.6-.4a.8.8 0 0 1-.2-.8c.5-2.2 2-4.2 4-5.3A12 12 0 0 1 13 30c1.1-.5 2.4-1 2.7-1.7s-.3-1.6-.8-2.2c-2.2-3.4-2.6-6.3-1.1-8.7a6.2 6.2 0 0 1 10.5 0c1.4 2.4 1 5.3-1.1 8.7-.5.6-1 1.5-.8 2.2s1.6 1.2 2.7 1.7l1.8.8c2 1.2 3.4 3.1 3.9 5.3.1.3 0 .6-.2.8-.2.2-.4.4-.7.4-3.6.4-7.3.5-10.9.4h.1zm20.3-5.9c.1.5-.2 1.1-.8 1.2h-.1c-1.9.3-3.8.4-5.7.4-1-2.1-2.5-3.8-4.5-5l-2.1-1-.6-.2C29 22 28.4 18.1 27 16c-.8-1.5-2-2.7-3.5-3.4 1-1.4 2.2-2.3 4.1-2.3A6 6 0 0 1 33 13c1.4 2.4 1 5.3-1.1 8.6-.5.7-1 1.6-.8 2.2s1.5 1.3 2.7 1.8l1.7.8a8 8 0 0 1 4 5.3l-.1.1z" />
  ),
  Warning: (
    <path d="M24,36.67A2.67,2.67,0,1,0,21.34,34,2.66,2.66,0,0,0,24,36.67Zm-2-20V28h4V16.67ZM39.63,35.19a3.32,3.32,0,0,1-.11,3.22A3.36,3.36,0,0,1,36.67,40H11.34a3.33,3.33,0,0,1-3-4.81L21,9.85A3.37,3.37,0,0,1,24,8a3.28,3.28,0,0,1,3,1.85Z" />
  )
};

const Icon = ({
  name,
  width,
  height,
  fill,
  className,
  label,
  altText,
  viewBox
}) => {
  const path = NAME_TO_PATH_MAP[name];
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className={classNames(
        'edu-icon',
        label ? 'icon-with-label__icon' : className
      )}
      viewBox={viewBox}
    >
      {altText && <title>{altText}</title>}
      {path}
    </svg>
  );

  if (label) {
    return (
      <div className={classNames('icon-with-label', className)}>
        {icon}
        <div className="icon-with-label__label">{label}</div>
      </div>
    );
  }

  return icon;
};

Icon.defaultProps = {
  fill: '#444',
  width: 48,
  height: 48,
  className: '',
  label: '',
  altText: null,
  viewBox: '0 0 48 48'
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  altText: PropTypes.string,
  viewBox: PropTypes.string
};

const curryIconWithName = name => props => <Icon name={name} {...props} />;

export { Icon };

export const ArrowLeft = curryIconWithName('ArrowLeft');
export const ArrowRight = curryIconWithName('ArrowRight');
export const ArrowUp = curryIconWithName('ArrowUp');
export const ArrowDown = curryIconWithName('ArrowDown');
export const Bullet = curryIconWithName('Bullet');
export const BulletEmpty = curryIconWithName('BulletEmpty');
export const Calendar = curryIconWithName('Calendar');
export const Checkmark = curryIconWithName('Checkmark');
export const Clock = curryIconWithName('Clock');
export const Dashboard = curryIconWithName('Dashboard');
export const Delete = curryIconWithName('Delete');
export const Diary = curryIconWithName('Diary');
export const Discharge = curryIconWithName('Discharge');
export const ChevronDown = curryIconWithName('ChevronDown');
export const Download = curryIconWithName('Download');
export const Edit = curryIconWithName('Edit');
export const Gear = curryIconWithName('Gear');
export const Guide = curryIconWithName('Guide');
export const Heart = curryIconWithName('Heart');
export const HeartEmpty = curryIconWithName('HeartEmpty');
export const Help = curryIconWithName('Help');
export const Info = curryIconWithName('Info');
export const Learn = curryIconWithName('Learn');
export const Logout = curryIconWithName('Logout');
export const Minus = curryIconWithName('Minus');
export const Menu = curryIconWithName('Menu');
export const Plus = curryIconWithName('Plus');
export const Search = curryIconWithName('Search');
export const ToolTip = curryIconWithName('ToolTip');
export const Track = curryIconWithName('Track');
export const Undo = curryIconWithName('Undo');
export const User = curryIconWithName('User');
export const Users = curryIconWithName('Users');
export const Warning = curryIconWithName('Warning');
