import React from "react";

const DEFAULT_ICONS = {
  HomeIncomplete: (
    <g id="Home_Incomplete">
      <circle
        className="discharge-home-icon-background"
        cx="33"
        cy="33"
        r="30"
      />
      <path
        className="discharge-home-icon"
        fillRule="evenodd"
        d="M16,3.46,2,16.54H5v12h8v-10h6v10h8v-12h3Z"
      />
    </g>
  ),
  HomeComplete: (
    <g>
      <circle
        className="discharge-home-icon-background-complete"
        cx="33"
        cy="33"
        r="30"
      />
      <path
        className="discharge-home-icon-complete"
        fillRule="evenodd"
        d="M16,3.46,2,16.54H5v12h8v-10h6v10h8v-12h3Z"
      />
    </g>
  ),
  PinActive: (
    <g>
      <path
        fill="#e16362"
        fillRule="evenodd"
        d="M29.93,53a6.47,6.47,0,0,1-4.29-2C25,50.45,12,38,12,25.33A18.19,18.19,0,0,1,30,7,18.19,18.19,0,0,1,48,25.33C48,38,34.83,50.46,34.27,51A6.6,6.6,0,0,1,29.93,53ZM30,22a3,3,0,0,0-3,3,3,3,0,0,0,3,3,3,3,0,1,0,0-6Z"
      />
      <path
        fill="#fff"
        d="M30,10A15.22,15.22,0,0,1,45,25.34C45,36.84,32.22,48.8,32.22,48.8A3.64,3.64,0,0,1,29.93,50a3.62,3.62,0,0,1-2.27-1.2S15,36.84,15,25.34A15.22,15.22,0,0,1,30,10m0,21a6,6,0,1,0-6-6,6,6,0,0,0,6,6M30,4A21.19,21.19,0,0,0,9,25.34C9,39,22.05,51.75,23.54,53.16l0,0,.05,0A9.35,9.35,0,0,0,29.93,56a9.5,9.5,0,0,0,6.32-2.77l0,0,0,0C37.82,51.77,51,39,51,25.34A21.19,21.19,0,0,0,30,4Zm0,21h0Z"
      />
    </g>
  ),
  PinComplete: (
    <g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M30,10A15.22,15.22,0,0,1,45,25.34C45,36.84,32.22,48.8,32.22,48.8c-1.84,1.66-2.86,1.54-4.56,0,0,0-12.66-12-12.66-23.46A15.22,15.22,0,0,1,30,10Z"
      />
      <path
        fill="#14a0a2"
        fillRule="evenodd"
        d="M37.9,22.91a.34.34,0,0,0,0-.48L35.57,20.1a.34.34,0,0,0-.48,0L28,27.2l-3.09-3.11a.34.34,0,0,0-.48,0L22.1,26.42a.36.36,0,0,0,0,.49l5.66,5.66a.37.37,0,0,0,.48,0Z"
      />
    </g>
  ),
  PinContinue: (
    <g>
      <path
        fill="#14a0a2"
        fillRule="evenodd"
        d="M29.93,53a6.47,6.47,0,0,1-4.29-2C25,50.45,12,38,12,25.33A18.19,18.19,0,0,1,30,7,18.2,18.2,0,0,1,48,25.33C48,38,34.83,50.46,34.27,51A6.6,6.6,0,0,1,29.93,53Z"
      />
      <path
        fill="#fff"
        d="M30,10A15.22,15.22,0,0,1,45,25.34C45,36.84,32.22,48.8,32.22,48.8A3.64,3.64,0,0,1,29.93,50a3.62,3.62,0,0,1-2.27-1.2S15,36.84,15,25.34A15.22,15.22,0,0,1,30,10M22.33,28H28v5.67a.33.33,0,0,0,.33.33h3.34a.33.33,0,0,0,.33-.33V28h5.67a.33.33,0,0,0,.33-.33V24.33a.33.33,0,0,0-.33-.33H32V18.33a.33.33,0,0,0-.33-.33H28.33a.33.33,0,0,0-.33.33V24H22.33a.33.33,0,0,0-.33.33v3.34a.33.33,0,0,0,.33.33M30,4A21.19,21.19,0,0,0,9,25.34C9,39,22.05,51.75,23.54,53.16l0,0,.05,0A9.35,9.35,0,0,0,29.93,56a9.5,9.5,0,0,0,6.32-2.77l0,0,0,0C37.82,51.77,51,39,51,25.34A21.19,21.19,0,0,0,30,4Z"
      />
    </g>
  ),
  PinPlaceholder: (
    <g>
      <clipPath id="clip-path">
        <path d="M30,10A15.22,15.22,0,0,1,45,25.34C45,36.84,32.22,48.8,32.22,48.8c-1.84,1.66-2.86,1.54-4.56,0,0,0-12.66-12-12.66-23.46A15.22,15.22,0,0,1,30,10Z" />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect x="10" y="5" width="40" height="50" />
      </clipPath>
      <clipPath id="clip-path-3">
        <rect x="15" y="10" width="30" height="40" />
      </clipPath>
      <g>
        <g clipPath="url(#clip-path)">
          <g isolation="isolate">
            <g clipPath="url(#clip-path-2)">
              <g clipPath="url(#clip-path-3)">
                <rect fill="#61c7ca" x="10" y="5" width="40" height="50" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  )
};

const exportByName = name => props => {
  const icon = DEFAULT_ICONS[name];
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
      {icon}
    </svg>
  );
};

export const HomeIncomplete = exportByName("HomeIncomplete");
export const HomeComplete = exportByName("HomeComplete");
export const PinActive = exportByName("PinActive");
export const PinComplete = exportByName("PinComplete");
export const PinContinue = exportByName("PinContinue");
export const PinPlaceholder = exportByName("PinPlaceholder");
