import {
  PROFILES_RECIEVED,
  PROFILE_CREATED,
  PROFILE_UPDATED,
  PROFILE_DELETED,
  PROFILES_DELETED
} from "actions/profiles"

export const profilesInitialState = {
  profiles: undefined
};

export function profilesReducer(state, action) {
  switch (action.type) {
    case PROFILES_RECIEVED:
      return { ...state, profiles: action.profiles }

    case PROFILE_CREATED:
      return { ...state, profiles: [...state.profiles, action.profile] }

    case PROFILE_UPDATED:
      const profiles = state.profiles.map((profile)=> {
        return profile.id === action.profile.id ? action.profile : profile;
      });

      return { ...state, profiles };

    case PROFILE_DELETED: {
      const profiles = state.profiles;
      const profileIndex = profiles.findIndex((profile) => profile.id === action.profileId);
      profiles.splice(profileIndex, 1);

      return { ...state, profiles }
    }

    case PROFILES_DELETED: {
      const profiles = state.profiles.filter((profile) => profile.accountId !== action.accountId);

      return { ...state, profiles }
    }

    default:
      return { ...state }
  }
}