import React, { useEffect, useState } from "react";

import MainDashboard from "./containers/MainDashboard/MainDashboard";
import AuthForms from "./containers/AuthForms/AuthForms";

import "./App.scss";
import "./assets/scss/_settings.fonts.scss";
import useLogin from "./hooks/useLogin";
import useAutoLogout from "./hooks/useAutoLogout";
import Error from "./components/Error/Error";
import Loading from "./components/Loading/Loading";
import { useStateValue } from "./state";

const App = ({ history }) => {
  const [{ user, serverConnection }] = useStateValue();
  const [token] = useState(localStorage.getItem("iwk-admin-token"));
  const [isLoggedIn, setLoggedIn] = useState(false);
  const { isLoading } = useLogin(token, onLogin);

  useAutoLogout();

  useEffect(() => {
    const loggedIn = user.authenticated && user.authorized;
    setLoggedIn(loggedIn);
  }, [user, isLoading]);

  function onLogin(loggedIn) {
    setLoggedIn(loggedIn);
  }

  if (serverConnection.error) {
    return <Error />;
  } else if (isLoading) {
    return <Loading />;
  } else if (isLoggedIn) {
    return <MainDashboard />;
  } else {
    return <AuthForms history={history} />;
  }
};

export default App;
