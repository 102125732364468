import React, { memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

// scss
import "./GeneralSettings.scss";

// components
import { IncrementalStepper } from "components/IncrementalStepper/IncrementalStepper";

// hooks and utils
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";

// actions
import { SETTING_UPDATED } from "actions/settings";

const GeneralSettings = (props) => {
  const [{ settings, user }, dispatch] = useStateValue();
  const [timeoutValue, setTimeoutValue] = useState(0);
  const [initialTimeoutValue, setInitialTimeoutValue] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (!settings.settings) {
      return;
    }

    const timeout = settings.settings.find(
      (setting) => setting.name === "timeout"
    );
    setInitialTimeoutValue(parseInt(timeout.value));
  }, [settings]);

  useEffect(() => {
    if (!timeoutValue) {
      return;
    }

    if (timeoutValue !== initialTimeoutValue) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [timeoutValue, initialTimeoutValue, setUnsavedChanges]);

  function saveTimeout() {
    const timeoutId = settings.settings.find(
      (setting) => setting.name === "timeout"
    ).id;
    const settingsBody = {
      value: timeoutValue.toString(),
    };

    fetchRequest(user.token, "PATCH", `settings/${timeoutId}`, settingsBody)
      .then((result) => {
        dispatch({
          type: SETTING_UPDATED,
          settingId: result.id,
          settingValue: settingsBody.value,
        });
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function updateTimeoutValue(value) {
    setTimeoutValue(value);
  }

  function cancelTimeout() {
    setTimeoutValue(initialTimeoutValue);
    setUnsavedChanges(false);
    setReset(true);
  }

  return (
    <div className="iwk-general-settings">
      <div className="iwk-general-settings__actions">
        {unsavedChanges && (
          <div>
            <Button
              variant="secondary"
              className="iwk-general-settings__actions-cancel"
              onClick={() => cancelTimeout()}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="iwk-general-settings__actions-save"
              onClick={() => saveTimeout()}
            >
              Save
            </Button>
          </div>
        )}
      </div>
      <div className="iwk-general-settings__settings">
        <span className="iwk-general-settings__settings-title">Time Out</span>

        <div className="iwk-general-settings__timeout">
          <div className="iwk-general-settings__timeout-message">
            Set the time that will log both parents and staff out automatically.
          </div>

          <div className="iwk-general-settings__timeout-control">
            <IncrementalStepper
              initialValue={initialTimeoutValue}
              resetStepper={reset}
              onValueChange={(value) => updateTimeoutValue(value)}
            ></IncrementalStepper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GeneralSettings);
