import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./Family.scss";

// components
import TableHeaderButtons from "components/TableHeaderButtons/TableHeaderButtons";
import Dropdown from "components/Dropdown/Dropdown";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import FamilyRow from "./FamilyRow/FamilyRow";

// hooks and utils
import { useStateValue } from "state";
import useForm from "hooks/useForm";
import validate from "./ProfileValidationRules";
import { fetchRequest } from "utils/fetch";
import { sortAccountByDateCreated } from "utils/utils";

// actions
import {
  PROFILE_CREATED,
  PROFILE_DELETED,
  PROFILE_UPDATED,
} from "actions/profiles";

const Family = ({ match }) => {
  const [showAddProfileModal, setShowAddProfileModal] = useState(false);
  const [familyArray, setFamilyArray] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [tableHeaderUsers, setTableHeaderUsers] = useState([]);
  const [forceCreate, setForceCreate] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const latestCheckedIds = useRef(checkedIds);
  const addProfileRef = useRef();

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `accountSettingsForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  const onFormSubmit = async (newValues) => {
    addProfile(newValues);
  };

  const { resetForm } = useForm(validate, () => {});
  const [{ families, profiles, roles, user }, dispatch] = useStateValue();

  const familyId = match.params.familyId;

  // keep checkedIds, latestCheckedIds and table header users in sync with family array
  useEffect(() => {
    if (familyArray.length > 0) {
      latestCheckedIds.current = Array.from(
        familyArray.filter(({ profile }) => profile.checked),
        ({ profile }) => profile.id
      );
      const newTableHeaderUsers = familyArray.map(({ profile }) => ({
        status: profile.status,
        checked: profile.checked,
      }));
      setCheckedIds(latestCheckedIds.current);
      setTableHeaderUsers(newTableHeaderUsers);
    }
  }, [familyArray]);

  const addProfile = useCallback(
    function (values) {
      if (!values.firstName) {
        return;
      }

      const profileBody = {
        status: "active",
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        roleId: roles.roles.find((role) => role.id === values.role.value).id,
      };

      const create = () => {
        return fetchRequest(
          user.token,
          "POST",
          `accounts/${familyId}/profiles`,
          profileBody
        )
          .then((profile) => {
            dispatch({
              type: PROFILE_CREATED,
              profile,
            });

            resetForm();
            setShowAddProfileModal(false);
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });
      };

      if (!forceCreate) {
        fetchRequest(
          user.token,
          "POST",
          `accounts/${familyId}/profiles/check-name`,
          profileBody
        ).then(({ unique }) => {
          if (unique) {
            create();
          } else {
            setShowConflictModal(true);
          }
        });
      } else {
        create();
        setForceCreate(false);
      }
    },
    [dispatch, familyId, forceCreate, resetForm, roles.roles, user.token]
  );

  // when forcing a profile creation, call the submit form via forwardRef
  useEffect(() => {
    if (forceCreate) {
      addProfileRef.current.submitFromParent();
    }
  }, [addProfile, forceCreate]);

  function getRoleName(roleId) {
    if (roleId && roles.roles) {
      return roles.roles.filter((role) => role.id === roleId)[0].name;
    } else return null;
  }

  function updateCheckedIds(id, checked) {
    if (checked) {
      latestCheckedIds.current.push(id);
    } else {
      const checkedIndex = checkedIds.findIndex(
        (checkedId) => checkedId === id
      );
      latestCheckedIds.current.splice(checkedIndex, 1);
    }

    const tableHeaderUsers = familyArray.map(({ account, profile }) => {
      return {
        status: profile.status,
        checked: profile.id === id ? checked : profile.checked,
      };
    });

    const family = familyArray.map(({ account, profile }) => {
      if (profile.id === id) {
        profile.checked = checked;
      }

      return {
        account,
        profile,
      };
    });

    setFamilyArray(family);
    setTableHeaderUsers(tableHeaderUsers);
    setCheckedIds(latestCheckedIds.current);
  }

  useEffect(() => {
    if (!families.families || !profiles.profiles) {
      return;
    }

    const familyAccount = families.families.filter(
      (family) => family.id === familyId
    )[0];
    const familyProfiles = profiles.profiles
      .filter((profile) => profile.accountId === familyId)
      .sort((a, b) => sortAccountByDateCreated(a, b))
      .map((profile) => {
        profile.checked = profile.checked || false;

        return {
          profile,
          account: familyAccount,
        };
      });

    setFamilyArray(familyProfiles);

    const tableHeaderUsers = familyProfiles.map(({ account, profile }) => {
      return {
        status: profile.status,
        checked: profile.checked || false,
      };
    });

    setTableHeaderUsers(tableHeaderUsers);
  }, [families.families, familyId, profiles]);

  useEffect(() => {
    if (profiles.profiles) {
      // latestCheckedIds is a ref, so it isn't updated when profiles have changed, so this effect hook keeps the list of
      // selected ids in sync with the state
      const profileIds = profiles.profiles.map((profile) => profile.id);
      latestCheckedIds.current = latestCheckedIds.current.filter((id) =>
        profileIds.includes(id)
      );

      setCheckedIds(latestCheckedIds.current);
    }
  }, [profiles]);

  function bulkDeleteFamily(ids) {
    for (let id of ids) {
      const { profile } = familyArray.find(({ profile }) => profile.id === id);

      if (!profile.accountHolder) {
        fetchRequest(user.token, "DELETE", `profiles/${id}`, undefined)
          .then((result) => {
            dispatch({
              type: PROFILE_DELETED,
              profileId: id,
            });
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });
      }
    }
  }

  function updateFamilyStatus(ids, action) {
    for (let profileId of ids) {
      let { profile } = familyArray.find(
        ({ profile }) => profile.id === profileId
      );
      const tableUsers = tableHeaderUsers.map(({ id, status, checked }) => {
        if (id === profileId) {
          checked = true;
          status = action;
        }

        return {
          status,
          checked,
          id,
        };
      });

      if (profile.status !== action) {
        profile.status = action;
        profile.checked = true;

        fetchRequest(user.token, "PATCH", `profiles/${profileId}`, profile)
          .then((profile) => {
            profile.checked = true;

            dispatch({
              type: PROFILE_UPDATED,
              profile,
            });
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });

        setTableHeaderUsers(tableUsers);
      }
    }
  }

  return (
    <div className="iwk-family">
      <div className="iwk-family__actions">
        <TableHeaderButtons
          checkedIds={latestCheckedIds.current}
          users={tableHeaderUsers}
          onBulkDeleteClick={bulkDeleteFamily}
          onUpdateStatusClick={updateFamilyStatus}
          showDischargeButtons={true}
        />
        <div>
          <span className="iwk-family__actions-count">
            {familyArray && familyArray.length} Profile
            {familyArray && familyArray.length > 1 ? "s" : ""}
          </span>
          <Button
            variant="primary"
            className="iwk-family__actions-add"
            onClick={() => setShowAddProfileModal(!showAddProfileModal)}
          >
            Add Profile
          </Button>
        </div>
      </div>

      <div className="iwk-family__table">
        <div className="iwk-family__table-header">
          <div className="iwk-family__table-header-cell">Account Holder</div>
          <div className="iwk-family__table-header-cell">Role</div>
          <div className="iwk-family__table-header-cell -small">Actions</div>
        </div>
        {familyArray &&
          familyArray.map(({ profile, account }, index) => {
            return (
              <FamilyRow
                // status={profile.status}
                familyId={familyId}
                id={profile.id}
                checked={profile.checked}
                role={{
                  name: getRoleName(profile.roleId),
                  id: profile.roleId,
                }}
                accountHolder={profile.accountHolder}
                firstName={profile.firstName}
                lastName={profile.lastName}
                onCheckboxUpdate={(id, checked) =>
                  updateCheckedIds(id, checked)
                }
                key={`profile-${index}`}
              />
            );
          })}
      </div>

      <FormModal
        ref={addProfileRef}
        modalCloseAction={() => setShowAddProfileModal(!showAddProfileModal)}
        show={showAddProfileModal}
        title={"Add Profile"}
        validate={(values) => validate(values)}
        submitAction={onFormSubmit}
        resetAction={resetForm}
      >
        <Field
          name="firstName"
          type="text"
          component={FormInput}
          label="First Name"
        />
        <Field
          name="lastName"
          type="text"
          component={FormInput}
          label="Last Name"
        />
        {/* We will likely be including emails for profiles in the future */}
        {/* <Field name="email" type="text" component={FormInput} label="Email"/> */}

        <Field
          options={roles.roleOptions}
          name="role"
          component={FormDropdown}
          label="Role"
        ></Field>
      </FormModal>

      <FormModal
        modalCloseAction={() => {
          setShowAddProfileModal(false);
          setShowConflictModal(false);
        }}
        show={showConflictModal}
        title={"Name Already Exists"}
        validate={() => {}}
        submitAction={() => {
          setForceCreate(true);
          setShowConflictModal(false);
        }}
        resetAction={() => {}}
        submitTitle={"Yes"}
      >
        <div>
          A profile with that name already exists for your account. Are you sure
          you would like to save this profile anyway?
        </div>
      </FormModal>
    </div>
  );
};

export default memo(Family);
