import React, { memo, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormStyled from "react-bootstrap/Form";
import { Field } from "react-final-form";

// scss
import "./Babies.scss";

// components
import TableHeaderButtons from "components/TableHeaderButtons/TableHeaderButtons";
import Dropdown from "components/Dropdown/Dropdown";
import FormModal, { createFormInput } from "components/FormModal/FormModal";
import BabiesRow from "./BabiesRow/BabiesRow";

// hooks and utils
import useForm from "hooks/useForm";
import validate from "./BabyValidationRules";
import { useStateValue } from "state";
import { fetchRequest } from "utils/fetch";
import { sexOptions } from "utils/options";
import { sortAccountByDateCreated } from "utils/utils";

// actions
import { BABY_CREATED, BABY_DELETED, BABY_UPDATED } from "actions/babies";

const Babies = ({ match }) => {
  const { resetForm } = useForm();
  const [showAddBabyModal, setShowAddBabyModal] = useState(false);
  const [babiesArray, setBabiesArray] = useState([]);
  const [{ families, user, babies }, dispatch] = useStateValue();
  const [checkedIds, setCheckedIds] = useState([]);
  const [tableHeaderUsers, setTableHeaderUsers] = useState([]);
  const latestCheckedIds = useRef(checkedIds);
  const familyId = match.params.familyId;
  // local variable to hold a default color scheme for babies added in the admin panel
  let createdColorScheme = "";

  // Types of inputs found in this section
  const FormInput = (options) =>
    createFormInput(
      FormStyled.Control,
      options,
      `addBabyForm.${options.input.name}`
    );
  const FormDropdown = (options) => createFormInput(Dropdown, options);

  // keep checkedIds, latestCheckedIds and table header users in sync with baby array
  useEffect(() => {
    if (babiesArray.length > 0) {
      latestCheckedIds.current = Array.from(
        babiesArray.filter(({ baby }) => baby.checked),
        ({ baby }) => baby.id
      );
      const newTableHeaderUsers = babiesArray.map(({ baby }) => ({
        status: baby.status,
        checked: baby.checked,
      }));
      setCheckedIds(latestCheckedIds.current);
      setTableHeaderUsers(newTableHeaderUsers);
    }
  }, [babiesArray]);

  function addBaby(values) {
    if (!values.name) {
      return;
    }

    const babyBody = {
      accountId: familyId,
      name: values.name,
      status: "active",
      sex: values.sex.value,
      dueDate: values.dueDate,
      birthDate: values.birthDate,
      admissionDate: values.admissionDate,
      deliveryType: values.deliveryType,
      diagnosis: values.diagnosis,
      room: values.room,
      colorScheme: setColorScheme(values.sex),
      icon:
        createdColorScheme + parseInt(Math.floor(Math.random() * 4) + 1, 10),
    };

    fetchRequest(user.token, "POST", "babies", babyBody)
      .then((baby) => {
        dispatch({
          type: BABY_CREATED,
          baby,
        });

        resetForm();
        setShowAddBabyModal(false);
      })
      .catch((error) => {
        // TODO app error handling
        console.log(error);
      });
  }

  function setColorScheme(currentSex) {
    switch (currentSex.value) {
      case "F":
        createdColorScheme = "red";
        break;
      case "M":
        createdColorScheme = "blue";
        break;
      default:
        createdColorScheme = "green";
    }

    return createdColorScheme;
  }

  function updateCheckedIds(id, checked) {
    if (checked) {
      latestCheckedIds.current.push(id);
    } else {
      const checkedIndex = checkedIds.findIndex(
        (checkedId) => checkedId === id
      );
      latestCheckedIds.current.splice(checkedIndex, 1);
    }

    const newTableHeaderUsers = babiesArray.map(({ account, baby }) => {
      return {
        status: baby.status,
        checked: baby.id === id ? checked : baby.checked,
      };
    });

    const newBabies = babiesArray.map(({ account, baby }) => {
      if (baby.id === id) {
        baby.checked = checked;
      }

      return {
        account,
        baby,
      };
    });

    setBabiesArray(newBabies);
    setTableHeaderUsers(newTableHeaderUsers);
    setCheckedIds(latestCheckedIds.current);
  }

  useEffect(() => {
    if (!families.families || !babies.babies) {
      return;
    }

    const familyAccount = families.families.filter(
      (family) => family.id === familyId
    )[0];
    const newBabiesArray = babies.babies
      .filter((baby) => baby.accountId === familyId)
      .sort((a, b) => sortAccountByDateCreated(a, b))
      .map((baby) => {
        baby.checked = baby.checked || false;

        return {
          baby,
          account: familyAccount,
        };
      });

    setBabiesArray(newBabiesArray);

    const newTableHeaderUsers = newBabiesArray.map(({ account, baby }) => {
      return {
        status: baby.status,
        checked: baby.checked || false,
      };
    });

    setTableHeaderUsers(newTableHeaderUsers);
  }, [babies, families, familyId]);

  function bulkDeleteBabies(ids) {
    for (let id of ids) {
      fetchRequest(user.token, "DELETE", `babies/${id}`, undefined)
        .then((result) => {
          dispatch({
            type: BABY_DELETED,
            babyId: id,
          });
        })
        .catch((error) => {
          // TODO app error handling
          console.log(error);
        });
    }
  }

  function updateBabiesStatus(ids, action) {
    for (let babyId of ids) {
      let { baby } = babiesArray.find(({ baby }) => baby.id === babyId);
      const tableUsers = tableHeaderUsers.map(({ id, status, checked }) => {
        if (id === babyId) {
          checked = true;
          status = action;
        }

        return {
          status,
          checked,
          id,
        };
      });

      if (baby.status !== action) {
        baby.status = action;
        baby.checked = true;

        fetchRequest(user.token, "PATCH", `babies/${babyId}`, baby)
          .then((baby) => {
            baby.checked = true;

            dispatch({
              type: BABY_UPDATED,
              baby,
            });
          })
          .catch((error) => {
            // TODO app error handling
            console.log(error);
          });

        setTableHeaderUsers(tableUsers);
      }
    }
  }

  return (
    <div className="iwk-babies">
      <div className="iwk-babies__actions">
        <TableHeaderButtons
          checkedIds={latestCheckedIds.current}
          users={tableHeaderUsers}
          onBulkDeleteClick={() => bulkDeleteBabies(latestCheckedIds.current)}
          onUpdateStatusClick={updateBabiesStatus}
          showDischargeButtons={false}
        />
        <div>
          <span className="iwk-family__actions-count">
            {!babiesArray || babiesArray.length === 0
              ? "This family doesn't have a baby yet. Please add one."
              : `${babiesArray.length} ${
                  babiesArray.length > 1 ? "Babies" : "Baby"
                }`}
          </span>
          <Button
            variant="primary"
            className="iwk-family__actions-add"
            onClick={() => setShowAddBabyModal(!showAddBabyModal)}
          >
            Add Baby
          </Button>
        </div>
      </div>

      {babiesArray && babiesArray.length > 0 && (
        <div className="iwk-babies__table">
          <div className="iwk-babies__table-header">
            <div className="iwk-babies__table-header-cell">Name</div>
            <div className="iwk-babies__table-header-cell">Sex</div>
            <div className="iwk-babies__table-header-cell">Room</div>
            <div className="iwk-babies__table-header-cell -small">Actions</div>
          </div>

          {babiesArray.map(({ baby, account }, index) => {
            return (
              <BabiesRow
                status={baby.status}
                id={baby.id}
                checked={baby.checked}
                familyId={familyId}
                name={baby.name}
                sex={baby.sex}
                room={baby.room}
                birthDate={baby.birthDate}
                dueDate={baby.dueDate}
                admissionDate={baby.admissionDate}
                deliveryType={baby.deliveryType}
                diagnosis={baby.diagnosis}
                onCheckboxUpdate={(id, checked) =>
                  updateCheckedIds(id, checked)
                }
                key={`baby-${index}`}
              />
            );
          })}
        </div>
      )}

      <FormModal
        modalCloseAction={() => setShowAddBabyModal(!showAddBabyModal)}
        show={showAddBabyModal}
        title={"Add Baby"}
        validate={(values) => validate(values)}
        submitAction={async (newValues) => {
          addBaby(newValues);
        }}
        resetAction={resetForm}
        className={"--overflow-scroll"}
      >
        <Field name="name" type="text" component={FormInput} label="Name" />
        <Field
          name="sex"
          component={FormDropdown}
          type="select"
          options={sexOptions}
          label="Sex"
        ></Field>
        <Field
          name="birthDate"
          type="date"
          component={FormInput}
          label="Birth Date"
        />
        <Field
          name="dueDate"
          type="date"
          component={FormInput}
          label="Due Date"
        />
        <Field
          name="admissionDate"
          type="date"
          component={FormInput}
          label="Admission Date"
        />
        <Field
          name="deliveryType"
          type="text"
          component={FormInput}
          label="Delivery Type"
        />
        <Field
          name="diagnosis"
          type="text"
          component={FormInput}
          label="Primary Diagnosis"
        />
        <Field name="room" type="text" component={FormInput} label="Room" />
      </FormModal>
    </div>
  );
};

export default memo(Babies);
