export function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function sortAccountByDateCreated(a, b) {
  const aDate = new Date(a.createdAt);
  const bDate = new Date(b.createdAt);

  return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
}

export function sortRequestByDateCreated(a, b) {
  const aDate = new Date(a.createdAt);
  const bDate = new Date(b.createdAt);

  return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
}