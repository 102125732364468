import { errorMessages, isEmpty } from "utils/validation";

export default function validate({
  name,
  sex,
  birthDate,
  dueDate,
  admissionDate,
  deliveryType,
  diagnosis,
  room,
}) {
  let errors = {};

  if (isEmpty(name)) {
    errors.name = errorMessages.emptyValue;
  }

  if (isEmpty(sex)) {
    errors.sex = errorMessages.emptyValue;
  }

  if (isEmpty(birthDate)) {
    errors.birthDate = errorMessages.emptyValue;
  }

  if (isEmpty(dueDate)) {
    errors.dueDate = errorMessages.emptyValue;
  }

  return errors;
};