import { errorMessages, isEmpty } from "utils/validation";

export default function validate({ firstName, lastName, role }) {
  let errors = {};

  if (isEmpty(firstName)) {
    errors.firstName = errorMessages.emptyValue;
  }

  if (isEmpty(lastName)) {
    errors.lastName = errorMessages.emptyValue;
  }

  if (isEmpty(role)) {
    errors.role = errorMessages.emptyValue;
  }

  return errors;
};