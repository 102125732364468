import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// scss
import "./AuthForms.scss";

// containers
import ChangePassword from "containers/ChangePassword/ChangePassword";
import Login from "containers/Login/Login";
import ResetPassword from "containers/ResetPassword/ResetPassword";
import Unauthorized from "containers/Unauthorized/Unauthorized";

// hooks and utils
import { useStateValue } from "state";

const AuthForms = ({ history }) => {
  const [{ user }] = useStateValue();

  useEffect(() => {
    // if the user.authorized is set to false they are unauthorized
    if (typeof user.authorized !== "undefined" && !user.authorized) {
      history.push("/unauthorized");
    }
  }, [user, history]);
  return (
    <div>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/change-password" exact component={ChangePassword} />
        <Route path="/unauthorized" component={Unauthorized} />
        <Redirect to="/login" />
      </Switch>
    </div>
  );
};

export default AuthForms;
