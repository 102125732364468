import { useState, useEffect } from "react";

// https://upmostly.com/tutorials/form-validation-using-custom-react-hooks/
// tutorial on how to setup this custom hook for our forms

const useForm = (validate, callback, initialValues = {}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // TODO: Determine how best to solve this. Callback will change and cause the form to be submitted twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting]);

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event, name = undefined, value = undefined) => {
    if (event) {
      event.persist();
    }

    setValues(values => ({
      ...values,
      [name || event.target.name]: value || event.target.value
    }));
  };

  const resetForm = () => {
    setValues({});
    setErrors({});
    setIsSubmitting(false);
  };

  return {
    handleChange,
    handleSubmit,
    resetForm,
    values,
    errors,
    setErrors
  };
};

export default useForm;
