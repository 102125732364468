import React from "react";
import Button from "react-bootstrap/Button";
import config from "../../config";

// scss
import "./Unauthorized.scss";

// components
import Logo from "components/Logo/Logo";

export const Unauthorized = () => {
  function goToChezHome() {
    window.location = config.clientURL;
  }

  return (
    <div className="iwk-unauthorized">
      <Logo />

      <div className="iwk-unauthorized__info">
        <h1 className="iwk-unauthorized__info-header">Whoa there!</h1>
        <p className="iwk-unauthorized__info-message">
          Sorry, but you don't have permission to access the Admin Portal.
        </p>
        <Button
          className="iwk-unauthorized__info-button"
          variant="primary"
          onClick={goToChezHome}
        >
          OK, take me to Chez NICU Home
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
