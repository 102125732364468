export function getHeaders(token) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // Add token to header request
  if (token) {
    return {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin':'*'
      }
    };
  }

  return { headers: defaultHeaders };
}