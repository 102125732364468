import { isEmpty, invalidEmail, errorMessages } from "utils/validation";

export default function validate({ firstName, lastName, email, role }) {
  let errors = {};

  if (isEmpty(firstName)) {
    errors.firstName = errorMessages.emptyValue
  }

  if (isEmpty(lastName)) {
    errors.lastName = errorMessages.emptyValue;
  }

  if (isEmpty(role)) {
    errors.role = errorMessages.emptyValue;
  }

  if (isEmpty(email)) {
    errors.email = errorMessages.emptyValue;
  } else if (invalidEmail(email)) {
    errors.email = errorMessages.invalidEmail;
  }

  return errors;
};