import {
  ROLES_RECEIVED
} from "actions/roles";
import { capitalize } from "utils/utils";

export const rolesInitialState = {
  roles: undefined,
  roleOptions: undefined
};

export function rolesReducer(state, action) {
  switch (action.type) {
    case ROLES_RECEIVED:
      const roleOptions = action.roles
        .sort((a, b) => a.name > b.name ? 1 : -1)
        .map((role) => {
          return {
            value: role.id,
            label: capitalize(role.name)
          }
        });

      return {
        ...state,
        roles: action.roles,
        roleOptions
      }

    default:
      return { ...state }
  }
}