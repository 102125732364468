import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { find, orderBy } from "lodash";
import { Nucleui, renderHomeIcon } from "./Nucleus";
import Path from "./Path";
import MobilePath from "./MobilePath";
import { calculatePathColor } from "./helper";
import "./DischargePath.scss";

const DischargePath = props => {
  const [state, setState] = useState({ width: 0, height: 0, rowCount: 1 });
  const svgRef = useRef(null);
  const outerContainer = useRef(null);

  const getWidth = () => {
    return (
      (outerContainer &&
        outerContainer.current &&
        outerContainer.current.offsetWidth) ||
      100
    );
  };

  const nodeHeight = () => {
    const { height, rowCount } = state;
    return height / rowCount;
  };

  const buildRows = () => {
    const { nodes, columnCount } = props;
    const orderedNodes = orderBy(nodes, parseInt("acf.step_number", 10), "asc");

    const calculateColumnIndex = ({ index, rowIndex }) => {
      const isEven = rowIndex % 2 === 0;
      const position = index % columnCount;
      return isEven ? position : columnCount - 1 - position;
    };

    const buildNode = (node, index) => {
      const rowIndex = Math.floor(index / columnCount);

      return {
        ...node,
        index,
        rowIndex,
        columnIndex: calculateColumnIndex({ index, rowIndex })
      };
    };

    return orderedNodes.map(buildNode);
  };

  const renderNodes = () => {
    const nodes = buildRows();
    return [renderPaths({ nodes }), renderNuclei({ nodes })];
  };

  /**
   * Calculates the position of the Nucleus based on mobile detection
   *
   * @param { Object } node the Nuclei node
   * @param { Number } height the height of the Nuclei
   *
   * @returns { Object } coordinates An object containing both the x and y coordinates for the Nuclei
   * @memberof DischargePath
   */
  const calculateNodePathCoordinates = (node, height) => {
    const { width } = state;
    const { columnCount } = props;
    const nodeWidth = width / columnCount;

    let coordinates = {
      x: 0,
      y: 0
    };

    coordinates.x = node.columnIndex * nodeWidth + nodeWidth / 2;
    coordinates.y = height * node.rowIndex + height / 1.25;

    return coordinates;
  };

  const renderNuclei = ({ nodes }) => {
    const { nucleusRender, fullCompletion, columnCount } = props;

    const mainNodes = nodes.map((node, i) => {
      const coordinates = calculateNodePathCoordinates(node, nodeHeight());
      const { x, y } = coordinates;

      if (nodes.length === i + 1) {
        return renderHomeIcon({
          x,
          y,
          node,
          columnCount: columnCount,
          isMobile: false,
          fullCompletion
        });
      }

      return nucleusRender({
        x,
        y,
        node,
        columnCount: columnCount,
        isMobile: false
      });
    });

    return mainNodes;
  };

  const renderPaths = ({ nodes }) => {
    const { width } = state;
    const {
      pathWidth,
      pathColor,
      renderPath,
      columnCount,
      fullCompletion
    } = props;
    const nodeWidth = width / columnCount;
    const clone = nodes.slice(0).reverse();

    return clone.map((node, index, array) => {
      const calculatedColor = pathColor(clone, index, fullCompletion);
      return renderPath({
        node,
        pathWidth,
        nodeWidth,
        nodeHeight: nodeHeight(),
        pathColor: calculatedColor,
        columnCount: columnCount,
        previousNode: find(array, el => el.index === node.index - 1)
      });
    });
  };

  useEffect(() => {
    const { nodes, columnCount, rowHeight } = props;

    setState({
      width: getWidth(),
      height: Math.ceil(nodes.length / columnCount) * rowHeight,
      rowCount: Math.ceil(nodes.length / columnCount)
    });
  }, [props]);

  const { width, height } = state;

  return (
    <div className="discharge-path__container">
      <div className="discharge-path__container-inner" ref={outerContainer}>
        <div className="discharge-path__intro" />
        <svg
          ref={svgRef}
          className="discharge-path__svg"
          viewBox={`0 0 ${width} ${height + 10}`}
          preserveAspectRatio="xMidYMid meet"
        >
          {renderNodes()}
        </svg>
      </div>
    </div>
  );
};

DischargePath.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  rowHeight: PropTypes.number,
  columnCount: PropTypes.number,
  mobileColumnCount: PropTypes.number,
  pathWidth: PropTypes.number,
  pathColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  nucleusRender: PropTypes.func,
  renderHome: PropTypes.func,
  renderPath: PropTypes.func,
  renderMobilePath: PropTypes.func
};

DischargePath.defaultProps = {
  nodes: [],
  rowHeight: 50,
  columnCount: 3,
  pathWidth: 10,
  mobileColumnCount: 3,
  pathColor: calculatePathColor,
  nucleusRender: Nucleui,
  renderHome: renderHomeIcon,
  renderPath: Path,
  renderMobilePath: MobilePath
};

export default DischargePath;
